@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/abstracts/extends';
@import 'src/styles/base/typography';
@import '../BaseLbp';

%view-field-brand {
  .logo-brand {
    width: 22px;
    max-width: 22px;
    border-radius: 22px;
    object-fit: contain;
  }

  .symbol {
    display: inline-block !important;
    color: $color-text-1;
    text-transform: uppercase;
    margin: 0 5px;
    @include body-3-medium;
  }
}

.adornment-end {
  @include flex-items-center;

  .slot {
    margin-left: 6px;
  }
}

.brand {
  height: 32px;
  padding: 5px;
  border-radius: 32px;
  background: $bg-6;
  @include mix-flex-centered;
  @extend %view-field-brand;
}

.paper-collateral {
  box-shadow: none;
  overflow: visible;
}

.list-collateral {
  margin-top: 4px;
  min-width: 150px;
  background: $bg-5;
  border-radius: 12px;
  border: 2px solid $color-text-4;
  padding: 8px;
  overflow: hidden;
}

.root-item {
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 5px;

  &:hover {
    background-color: rgb(0 120 255 / 30%) !important;
  }
  @extend %view-field-brand;
}

.selected-item {
  background-color: rgb(0 120 255 / 30%) !important;
}

.icon-collateral {
  color: $color-text-1;
}

.error {
  color: $color-accent-6;
  @include body-4-regular;
}

.field-slider {
  + .field-slider {
    margin-top: 16px;
  }
  @include laptop {
    max-width: 90%;
  }

  .label {
    color: $color-text-1;
    margin-bottom: 0;
    @include body-3-bold;

    .require {
      color: $color-accent-6;
    }
  }

  .control {
    @include flex-justify-between;

    align-items: center;
    margin-bottom: 28.5px;

    @include mobile {
      margin-bottom: 8px;
    }
  }

  .icon-change {
    transform: translateY(-50%);
    display: block;
    overflow: visible;
    padding: 1px 8px;
    width: 16px;
    background: $accent-color-100;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      position: absolute;
      padding: 1px;
      background: inherit;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }

  .minus {
    margin-right: 20px;
    margin-top: 6px;
    padding: 10px 2px 10px 0;
    cursor: pointer;
  }

  .plus {
    margin-left: 20px;
    margin-right: 0;
    padding-top: 4px;
    cursor: pointer;
  }

  .value {
    justify-content: space-between;
    @include flex-items-center;

    .item {
      @include flex-items-center;

      .name {
        color: $color-text-1;
        text-transform: uppercase;
        margin-right: 8px;
        @include body-3-regular;
      }

      .percent {
        color: $color-accent-1;
        @include body-3-bold;
      }
    }
  }
}

.lbp-auction-chart {
  height: 260px;
  @include mobile {
    height: 250px;
  }
}