@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.input-picker {
  border: 2px solid $color-text-4;
  border-radius: 12px;
  padding: 0 12px;
  @include flex-items-center;

  .input-control {
    flex-grow: 1;
    margin-right: 8px;
    width: 100%;

    input {
      width: 100%;
      border: none;
      outline: none;
      background: transparent;
      color: $color-text-1;
      @include body-2-regular;
    }
  }

  &.large {
    height: 50px;
  }

  &.medium {
    height: 40px;
  }

  .adornment-end {
    @include mix-adornment-end($width: 26px, $height: 26px);

    img,
    svg {
      width: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }
}
