@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';
@import 'src/styles/abstracts/extends';

.detail-back {
  background: $bg-400;
  margin-bottom: 10px;
  padding: 3px 18px 3px 0;

  @include custom-flex;
  @include tablet {
    padding: 9px 26px 9px 0;
    margin-bottom: 4px;
  }

  .content-left {
    a {
      @include flex-items-center;
    }
  }

  .settings {
    color: $color-brand-2;

    svg {
      cursor: pointer;
    }
  }

  .icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    @include mix-flex-centered;

    @include tablet {
      margin: 10px 12px;
    }

    svg path {
      fill: $color-neutrals-8;
    }
  }

  .name {
    color: $text-neutrals-8;
    @include h-6-bold;
  }
}

.content-item {
  + .content-item {
    margin-top: 10px;
    @include tablet {
      margin-top: 4px;
    }
  }

  &.group-tab-desktop {
    @include tablet {
      background: $bg-3;
      border-radius: 16px;
      overflow: hidden;
      margin-top: 16px;
    }
  }

  &.after-rounded {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow: hidden;
  }
}

.lbp-duration {
  @include mix-card-rounded;
  @extend %view-block-rounded;

  .card-duration {
    @include tablet {
      margin-bottom: 0;
      @include flex-items-center;
    }

    .title {
      color: $color-text-1;
      @include h5-bold;
      @include flex-items-center;

      margin-bottom: 16px;
      @include tablet {
        margin-bottom: 0;
        padding-right: 32px;
      }

      svg {
        margin-left: 10px;
      }
    }

    .content {
      @include tablet {
        @include flex-items-center;
        @include mix-border-divider;
      }

      .detail {
        @include tablet {
          padding-left: 32px;
        }

        + .detail {
          margin-top: 16px;
          @include tablet {
            margin-top: 0;
          }
        }

        @include flex-items-center;

        .item {
          margin-right: 8px;
          color: $color-text-2;
        }

        .icon {
          max-width: 24px;
        }

        .label {
          white-space: nowrap;
          color: $color-text-2;
          @include body-3-medium;
        }

        .value {
          white-space: nowrap;
          color: $color-text-1;
          @include body-3-bold;
        }
      }
    }
  }
}

.lbp-overview {
  display: flex;
  align-items: stretch;
  width: 100%;
  overflow: auto;

  .overview-item {
    min-width: 248px;
    @include mix-card-rounded;
    @extend %view-block-rounded;

    + .overview-item {
      margin-left: 10px;
      @include tablet {
        margin-left: 4px;
      }
    }
    @include desktop {
      width: 100%;
      flex-grow: 1;
    }

    .info {
      padding: 0 16px;
      width: 100%;

      .label {
        @include flex-items-center;

        margin-bottom: 12px;

        .icon {
          @extend %view-icon-square-blue;
        }

        .text {
          color: $color-text-3;
          @include body-4-regular;
        }
      }

      .value {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;

        .text {
          color: $color-neutrals-8;
          @include body-2-bold;

          margin-right: 5px;
        }

        .note {
          color: $color-neutrals-8;
          @include body-3-medium;

          opacity: 0.5;
          margin-left: 5px;
        }

        .price {
          @include body-3-semibold;

          line-height: 26px;
        }
      }
    }
  }
}
