@import 'src/styles/abstracts/variables';

.card {
  background: rgb(47 50 65 / 50%);
  padding: 16px;

  .card-body {
    .timeline {
      padding-left: 36px;
      position: relative;

      > div {
        .row {
          border-top: 1px solid $bg-100;

          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            bottom: calc(100% - 29px);
            left: -27px;
            width: 2px;
            height: 100%;
            background: $text-color-300;
          }
        }
      }

      .row {
        position: relative;
        padding: 16px 0;

        +.row {
          border-top: 1px solid $bg-100;

          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            bottom: calc(100% - 29px);
            left: -27px;
            width: 2px;
            height: 100%;
            background: $text-color-300;
          }
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 22px;
          left: -33px;
          width: 14px;
          height: 14px;
          border-radius: 7px;
          background: $text-color-300;
          z-index: 999;
        }

        .header-claim {
          display: flex;
          justify-content: space-between;
        }

        .percent {
          color: $text-color-50;
          font-size: 14px;
          line-height: 23px;
          font-weight: 700;
          margin-bottom: 16px;
        }

        &.done, &.active {
          .percent {
            color: $accent-color-500;
          }

          &::before, &::after {
            background: $color-green-100;
          }
        }

        .date {
          margin-bottom: 8px;
        }

        .date, .amount {
          color: $text-color-50;
          font-size: 14px;
          font-weight: 500;
          line-height: 23px;
        }

        .claim-status-desktop {
          width: 20%;
        }

        .claim-status {
          div {
            border: 1px solid $text-color-300;
            border-radius: 20px;
            font-weight: 400;
            font-size: 14px;
            line-height: 23px;
            color: $text-color-50;
            padding: 2px 10px 2px 24px;
            position: relative;
            width: max-content;

            &::before {
              content: '';
              background: $text-color-200;
              border-radius: 10px;
              width: 10px;
              height: 10px;
              position: absolute;
              left: 8px;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          .claimed {
            &::before {
              background: $accent-color-500;
            }
          }

          .claimable {
            &::before {
              background: $brand-color-300;
            }
          }
        }
      }

      .item-mobile {
        .row {
          border-top: 1px solid $bg-100;
        }

        &:first-child {
          .row {
            border-top: 0;

            &::before {
              content: none;
            }
          }
        }
      }
    }
  }

  .card-header {
    display: block;
    width: 100%;

    .card-title {
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
      border-bottom: 1px solid $bg-100;

      .left {
        flex-grow: 1;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
        color: $color-white;
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .btn-collapse {
          position: relative;
          border: none;
          outline: none;
          background: transparent;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 16px;

          .icon {
            position: relative;
            display: block;
            overflow: visible;
            padding: 1px 8px;
            background: $accent-color-100;

            &::after {
              content: '';
              display: block;
              position: absolute;
              padding: 1px;
              background: $accent-color-100;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              transition: all .25s cubic-bezier(0.19, 1, 0.22, 1);
            }
          }

          &.collapsed {
            .icon::after {
              padding: 8px 1px;
            }
          }
        }
      }
    }

    .sub-header {
      padding-top: 16px;
      max-height: 100vh;
      transition: all .3s cubic-bezier(0.4, 0, 0.2, 1);
      overflow: hidden;

      &.hidden {
        max-height: 0;
        padding-top: 0;
      }

      .col {
        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-grow: 1;

          +.row {
            margin-top: 16px;
          }

          .left {
            display: flex;
            align-items: center;

            .icon {
              width: 12px;
              height: 12px;
              border-radius: 4px;
              background: $accent-color-100;
              display: block;
              margin-right: 8px;
            }

            .label {
              color: $text-color-100;
              font-size: 12px;
              line-height: 20px;
              font-weight: 400;
            }
          }

          .right {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .value {
              color: $text-color-50;
              font-size: 12px;
              line-height: 20px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .card {
    padding: 32px;

    .btn-collapse {
      position: absolute;
      top: calc(50% - 12px);
      right: 16px;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      padding: 0;

      &:hover {
        border: 2px solid $color-white;

        svg path {
          fill: $color-white;
        }
      }
    }

    .card-header {
      .card-title {
        border-bottom: none;
        padding-bottom: 32px;

        .left {
          font-size: 18px;
          line-height: 26px;
          font-weight: 700;
        }

        .right {
          .btn-claim {
            height: 40px;
            border-radius: 20px;
            padding: 0 24px;
            font-size: 14px;
            font-weight: 600;
            line-height: 23px;
          }
        }
      }
    }

    .card-body {
      .timeline {
        padding-left: 36px;
        position: relative;

        .header-collapse  {
          position: relative;
          margin-left: -36px;

          .row {
            background: $bg-500;

            &::before {
              content: none;
            }

            &::after {
              content: none;
            }
          }
        }

        .row {
          display: flex;
          background: transparent;
          border-radius: 16px;
          padding: 16px 0;
          padding-right: 24px;

          >div {
            flex-grow: 1;
            padding: 0 16px;
          }

          +.row {
            border-top: none;

            &::before {
              bottom: calc(100% - 34px);
            }
          }

          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            bottom: calc(100% - 34px);
            left: -27px;
            width: 2px;
            height: 100%;
            background: $text-color-300;
          }

          &::after {
            top: calc(50% - 7px);
          }

          &:first-child {
            &::before {
              content: none;
            }
          }

          .percent {
            margin-bottom: 0;
            width: 30%;
          }

          &.done, &.active {
            .percent {
              color: $accent-color-500;
            }

            &::before, &::after {
              background: $color-green-100;
            }
          }

          .date {
            margin-bottom: 0;
            width: 35%;
          }

          .amount, .claim-status-desktop {
            width: 25%;
          }

          .btn-collapse {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        .row:nth-of-type(odd) {
          background: $bg-500;
        }
      }
    }
  }
}