@import 'src/styles/abstracts/variables';

.wrapper {
  .pagination {
    display: flex;
    justify-content: flex-end;
    margin: 32px 32px 0 42px;

    nav {
      ul {
        li {
          button {
            background: $bg-300;
            border: 1px solid $bg-100;
            border-radius: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 23px;
          }
        }
      }
    }

    button {
      color: #FCFCFD !important;
    }
  }
  
  .load-more {
    display: flex;
    justify-content: center;
    padding-bottom: 60px;

    .btn-load-more {
      border-radius: 4px;
    }
  }
}