@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.input-currency {
  width: 100%;
  padding: 0 16px;
  border-radius: 12px;
  color: $color-brand-4;
  box-sizing: border-box;
  border: 2px solid $color-text-4;
  @include flex-items-center;

  &-small {
    height: 40px;
  }

  &-medium {
    height: 50px;
  }

  .core-input {
    flex-grow: 1;
    position: relative;
    height: 100%;

    .base-input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include mix-flex-centered;

      .input {
        border: none;
        outline: none;
        width: 100%;
        height: 100%;
        background: transparent !important;
        color: $color-brand-4;
        @include body-2-regular;
      }
    }
  }

  &-disabled {
    background: $bg-6;
    border-color: $bg-6;
    color: $color-text-2;
  }

  &-error {
    border-color: $color-accent-6;
  }
}
