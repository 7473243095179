@import 'src/styles/abstracts/variables';

.root {
  padding: 0;

  &-medium {
    height: 28px;
    width: 52px;
  }

  .checked {
    color: white;

    + .track {
      background: $bg-brand-0;
      opacity: 1;
    }

    &-medium {
      transform: translateX(24px);
    }
  }

  .disabled.checked {
    + .track {
      opacity: 0.5 !important;
      cursor: not-allowed;
    }
  }

  .disabled {
    + .track {
      cursor: not-allowed;
    }
  }

  .track {
    background: $bg-8;

    &-medium {
      border-radius: 28px;
    }
  }
}

.thumb-medium {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}

.switch-base-medium {
  padding: 4px;
}
