@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

$height-size-small: 40px;
$height-size-medium: 50px;

.select {
  color: $color-text-1;
  justify-content: flex-start;
  padding: 0 5px;
  box-sizing: border-box;
  @include body-2-medium;
  @include flex-items-center;

  &.select {
    padding-right: 32px;
  }

  .menu-item {
    color: $color-white;
    @include body-2-medium;

    &:only-child {
      margin: 0 7px;
    }
  }

  &-filled {
    background: $bg-6;

    &:focus {
      background: $bg-6;
    }

    &:hover {
      background: rgb(0 120 255 / 30%);
    }

    &.small {
      border-radius: $height-size-small;
    }

    &.medium {
      border-radius: $height-size-medium;
    }
  }

  &-outlined {
    background: transparent;
    color: $color-text-1;
    border: 2px solid $color-text-4;
    @include body-2-regular;

    &:hover {
      border-color: $color-brand-3;
    }

    &.small {
      border-radius: 12px;
    }

    &.medium {
      border-radius: 12px;
    }
  }

  &.small {
    height: $height-size-small;
  }

  &.medium {
    height: $height-size-medium;
  }
}

.disabled {
  opacity: 0.5;
}

.icon {
  max-width: 100%;
  width: auto;
}

.icon-down {
  width: auto;
  max-width: 26px;
  object-fit: contain;
  position: absolute;
  top: calc(50% - 3px);
  right: 12px;
}

.paper {
  box-shadow: none;
  overflow: visible;
  color: $color-text-1;
}

.list {
  margin: 4px 0;
  min-width: 150px;
  background: $bg-5;
  border-radius: 12px;
  border: 2px solid $color-text-4;
  padding: 8px;
  overflow: hidden;
  box-shadow: 0 16px 64px rgb(0 0 0 / 40%);

  &-small {
    .root-menu-item {
      height: $height-size-small;
    }
  }

  &-medium {
    .root-menu-item {
      height: $height-size-medium;
    }
  }
}

.root-menu-item {
  background: transparent;
  border-radius: 8px;
  color: $color-neutrals-7;
  padding: 0 8px;
  padding-left: 8px;
  padding-right: 8px;
  @include flex-items-center;
  @include body-2-medium;

  &:hover,
  &.selected-menu-item {
    background: rgb(0 120 255 / 30%) !important;
  }

  + .root-menu-item {
    margin-top: 5px;
    @include laptop {
      margin-top: 10px;
    }
  }
}
