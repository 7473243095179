@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.root-tabs {
  position: relative;
}

.staking-page {
  @include mix-max-width;

  margin-bottom: 130px;
  min-height: calc(100vh - 630px);

  @media only screen and (max-width: 768px) {
    margin-bottom: 50px;
  }

  .content-left {
    padding-right: 32px;
    border-right: 1px solid $bg-100;
  }

  .content-right {
    padding-right: 0;
  }

  .loading {
    display: flex;
    justify-content: center;
    margin: 70px 0;
  }

  .message {
    color: white;
    font-size: 14px;
    top: 0;
    padding: 12px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #591425;
    margin-bottom: 15px;
    border-radius: 8px;

    .btn-switch {
      padding: 6px 18px;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      margin-left: 16px;
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;

      > div {
        text-align: center;
      }

      .btn-switch {
        margin-top: 5px;
      }
    }
  }
}

.collapse-tier {
  padding: 32px 0;
  background: $bg-400;
  border-radius: 16px;
  margin-top: 16px;

  .header {
    padding: 0 32px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .title {
      font-family: R-Flex-Bold, sans-serif;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      color: $text-color-50;
    }

    .icon-collapse {
      transform: translateY(-50%);
      display: block;
      overflow: visible;
      padding: 1px 8px;
      background: $accent-color-100;

      &::after {
        content: '';
        display: block;
        position: absolute;
        padding: 1px;
        background: inherit;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
      }

      &.collapsed::after {
        padding: 8px 1px;
      }
    }
  }
}

.collapse-tier-show {
  padding-bottom: 0;
}

.diamond-tier {
  margin-top: 32px;

  .note {
    background: $bg-300;
    border-radius: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: $text-color-100;
    padding: 20px 32px;
    margin: 0 32px 50px;
    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
      width: 48px;
    }

    .text {
      width: calc(100% - 48px);
    }

    .read-more {
      color: #0065d0;
      cursor: pointer;
    }
  }

  .warning {
    display: flex;
    justify-content: center;
    color: $accent-color-200;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
  }

  .snapshot-detail {
    display: flex;
    align-items: center;
  }

  .snapshot {
    display: flex;
    justify-content: space-between;
    margin: 32px 32px 64px;
    align-items: center;

    svg {
      margin-right: 10px;
    }

    .label {
      font-weight: 700;
      font-size: 14px;
      line-height: 23px;
      color: $text-color-100;
      padding: 0 10px;
    }

    .snap-time {
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      color: $text-color-50;
    }

    .token-rate {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: $text-color-100;
    }
  }
}

.table {
  margin-bottom: 16px;
  margin-top: 32px;

  .item {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    text-align: right;
    color: $text-color-50;
    margin: 0;
    padding: 16px 32px;

    &:nth-child(even) {
      background: $bg-300;
    }

    > div {
      margin: 0;

      > div {
        padding: 0;

        &:first-child,
        &:nth-child(2) {
          text-align: left;
        }
      }
    }
  }

  .thead {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: $text-color-100;
    margin: 0 32px 16px;

    > div {
      padding: 0;

      &:first-child,
      &:nth-child(2) {
        text-align: left;
      }
    }
  }
}

.text-noti {
  text-align: center;
  margin: 16px 0;
  font-size: 14px;
}

.account-err {
  color: $accent-color-200;
}

.warning {
  @include mobile {
    color: $accent-color-200;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    text-align: center;
    margin-top: 16px;
  }
}

.text-success {
  color: $accent-color-500;
}

.competition {
  padding-bottom: 32px;

  .competition-header {
    padding: 20px 32px;
    background: $bg-300;
    border-radius: 16px;
    margin: 32px 32px 16px;

    .competition-notification {
      display: flex;
      border-bottom: 1px solid $bg-100;
      padding-bottom: 22px;

      svg {
        margin-right: 16px;
      }

      .text {
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        color: $text-color-50;

        .text-warning {
          color: $accent-color-200;

          svg {
            margin-right: 8px;
          }
        }
      }
    }

    .competition-note {
      display: flex;
      justify-content: space-between;
      margin-top: 27px;
      margin-bottom: 7px;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      color: $text-color-50;
      flex-wrap: wrap;
      align-items: center;

      svg {
        margin-right: 8px;
      }

      .account-err {
        color: $accent-color-200;
      }

      .text-success {
        color: $accent-color-500;
      }

      .btn {
        margin: 5px 0;
      }
    }
  }

  .snapshot {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    align-items: center;

    svg {
      margin-right: 10px;
    }

    .label {
      font-weight: 700;
      font-size: 14px;
      line-height: 23px;
      color: $text-color-100;
      padding: 0 10px 0 0;
    }

    .snap-time {
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      color: $text-color-50;
    }

    .token-rate {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: $text-color-100;
    }

    .snapshot-info {
      display: flex;
    }

    .box-snapshot {
      display: flex;
      justify-content: space-between;
    }

    .description {
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      color: $text-color-200;
      margin-top: 16px;
    }
  }

  .competition-info {
    margin: 0 32px;

    > div {
      &:first-child {
        .competition-info-item {
          border-radius: 16px 16px 4px 4px;
        }

        padding: 0;
      }

      &:nth-child(2),
      &:nth-child(3) {
        .competition-info-item {
          border-radius: 4px;
        }
      }

      &:nth-child(2) {
        padding-right: 2px;
        padding-left: 0;
      }

      &:nth-child(3) {
        padding-left: 2px;
        padding-right: 0;
      }
    }

    .competition-info-item {
      background: $bg-300;
      margin-bottom: 4px;
      display: flex;
      justify-content: space-between;
      padding: 16px 32px;

      .end-time {
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        color: $text-color-100;
        display: flex;
        align-items: center;

        .text {
          display: flex;
          align-items: flex-end;
        }

        .count-down {
          span {
            margin-right: 0;
          }
        }

        span {
          margin-right: 10px;
        }

        svg {
          margin-right: 10px;

          path {
            fill: $text-color-100;
          }
        }
      }

      .label {
        display: flex;
        align-items: center;

        .dot {
          width: 12px;
          height: 12px;
          background: linear-gradient(
            265.56deg,
            #246cf9 -0.27%,
            #1e68f6 -0.26%,
            #0030ff 98.59%
          );
          border-radius: 4px;
          margin-right: 8px;
        }

        .text {
          font-size: 16px;
          line-height: 26px;
          color: $text-color-100;
        }
      }

      .value {
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        color: $text-color-50;
      }
    }
  }
}

.count-down {
  span {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: $text-color-50;
    margin-right: 0 !important;
  }
}

.description-mobile {
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  color: $text-color-200;
  margin-top: 16px;
}

// new styles update 28/04/2022
.tab-staking-mobile {
  position: relative;
  width: 100%;
  max-height: 48px;
  overflow: hidden;
  border-bottom: 1px solid $text-color-300;
  margin-bottom: 16px;

  .btn-navigation {
    background: linear-gradient(270deg, #2c3561 0%, rgb(44 53 97 / 10%) 100%);
    width: 41px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 999;

    svg path {
      fill: $color-white;
    }
  }

  .btn-left {
    left: 0;
    transform: rotate(180deg);
  }

  .btn-right {
    position: absolute;
    right: 0;
  }

  .list-tab-staking {
    list-style: none;
    margin: 0;
    display: flex;
    padding-left: 0;

    .tab-staking {
      display: block;
      padding: 10px 20px 12px;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 500;
      line-height: 23px;
      color: $text-color-100;
      border-bottom: 3px solid transparent;
      transition: all 0.25s linear;

      &.tab-active {
        color: $brand-color-200;
        border-color: currentcolor;
      }
    }
  }
}

.pool-detail-mobile {
  position: fixed;
  right: 100%;
  width: 100%;
  top: 100px;
  height: calc(100vh - 100px);
  padding-bottom: 70px;
  z-index: 98;
  background: $bg-600;
  transition: all 0.25s linear;

  &.show {
    right: 0;
  }
}

.staking-table-mobile {
  .card-staking {
    background: $bg-400;
    box-shadow: 0 4px 4px rgb(0 0 0 / 16%);
    border-radius: 12px;
    padding: 16px;
    margin-top: 16px;

    .text-note {
      font-size: 14px;
      font-weight: 400;
      color: $text-color-100;
      line-height: 23px;
      margin-bottom: 0;

      .read-more {
        color: #0065d0;
      }
    }

    .competition-notification {
      padding-bottom: 16px;
      border-bottom: 1px solid $bg-100;

      .text {
        color: $text-color-50;
        font-size: 14px;
        font-weight: 500;
        line-height: 23px;
      }
    }

    .competition-note {
      padding-top: 16px;
      font-size: 14px;
      font-weight: 500;
      line-height: 23px;

      .text {
        color: $text-color-50;

        svg {
          margin-right: 8px;
        }
      }

      .btn {
        color: $accent-color-500;
        margin-top: 16px;
      }
    }

    .text {
      font-size: 14px;
      font-weight: 400;
      color: $text-color-100;
      line-height: 23px;
      margin-bottom: 0;

      .read-more {
        color: #0065d0;
      }
    }

    .warning {
      font-size: 14px;
      font-weight: 400;
      color: $accent-color-200;
      line-height: 23px;
      margin-top: 8px;
    }
  }

  .competition-info {
    .card-staking-header {
      padding-bottom: 16px;

      .flex-header {
        position: relative;
        display: flex;
        align-items: center;

        .icon {
          margin-right: 8px;
        }

        .title {
          color: $text-color-100;
          font-size: 14px;
          font-weight: 500;
          line-height: 23px;
          margin: 0;
        }

        .icon-collapse {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          display: block;
          overflow: visible;
          padding: 1px 8px;
          background: $accent-color-100;

          &::after {
            content: '';
            display: block;
            position: absolute;
            padding: 1px;
            background: inherit;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
          }

          &.collapsed::after {
            padding: 8px 1px;
          }
        }
      }
    }

    .card-staking-subheader {
      > div {
        color: $text-color-100;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;

        > span {
          color: $text-color-100;
          font-size: 16px;
          font-weight: 700;
          line-height: 26px;
        }
      }
    }

    .card-staking-body {
      margin-top: 16px;
      border-radius: 12px;
      overflow: hidden;

      .card-staking-item {
        display: flex;
        align-items: center;
        padding: 15px 16px;
        background: $bg-300;
        margin-top: 0 !important;

        &:not(:last-child) {
          margin-bottom: 4px;
        }

        .label {
          color: $text-color-100;
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          display: flex;
          align-items: center;
          margin: 0;
          flex-grow: 1;

          .icon {
            margin-right: 8px;
            width: 12px;
            height: 12px;
            border-radius: 4px;
            background: linear-gradient(
              265.56deg,
              #246cf9 -0.27%,
              #1e68f6 -0.26%,
              #0030ff 98.59%
            );
          }
        }

        .value {
          margin: 0;
          color: $text-color-50;
          font-size: 12px;
          font-weight: 700;
          line-height: 20px;
          text-align: right;
        }
      }
    }
  }

  .snapshot {
    .label {
      display: flex;
      align-items: center;
      color: $text-color-100;
      font-size: 14px;
      font-weight: 500;
      line-height: 23px;
      margin-bottom: 16px;

      .icon {
        margin-right: 8px;
      }
    }

    .snap-time {
      color: $text-color-50;
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
      margin-bottom: 16px;
    }

    .token-rate {
      color: $text-color-100;
      font-size: 12px;
      font-weight: 500;
      line-height: 26px;
    }

    .account-err {
      color: $accent-color-200;
      margin-bottom: 16px;

      svg {
        margin-right: 8px;
      }
    }

    .text-success {
      margin-bottom: 16px;
      color: $accent-color-500;
    }
  }
}

.card-staking {
  background: $bg-400;
  box-shadow: 0 4px 4px rgb(0 0 0 / 16%);
  border-radius: 12px;
  padding: 16px;

  &:not(:first-child) {
    margin-top: 16px;
  }

  .text-note {
    font-size: 14px;
    font-weight: 400;
    color: $text-color-100;
    line-height: 23px;
    margin-bottom: 0;

    .read-more {
      color: #0065d0;
    }
  }

  .card-staking-header {
    padding-bottom: 16px;

    .flex-header {
      position: relative;
      @include flex-items-center;

      .icon-position {
        @include custom-flex(row, center, center);

        margin-right: 8px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: $bg-50;
        color: $brand-color-200;
        @include body-2-bold;
      }

      .title {
        color: white;
        @include body-2-bold;

        margin: 0;
      }

      .icon-collapse {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        display: block;
        overflow: visible;
        padding: 1px 8px;
        background: $accent-color-100;

        &::after {
          content: '';
          display: block;
          position: absolute;
          padding: 1px;
          background: inherit;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
        }

        &.collapsed::after {
          padding: 8px 1px;
        }
      }
    }
  }

  .card-staking-subheader,
  .card-staking-body .card-staking-item {
    @include custom-flex;

    .label {
      @include caption;

      color: $text-color-100;
    }

    .text {
      color: $text-color-50;
      @include label-bold;

      margin: 0;
    }
  }

  .card-staking-body {
    .card-staking-item {
      margin-top: 16px;
    }
  }
}

.indicator {
  background-color: $color-brand-2;
  height: 3px;
}

.wrapped-tab {
  color: $color-text-2;
  padding: 0 20px 12px;
  text-transform: capitalize;

  + .wrapped-tab {
    margin-left: 28px;
  }
  @include body-3-medium;
}

.selected-tab {
  color: $color-brand-2;
}
