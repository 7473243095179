@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';
@import '../BaseLbp';

.card-body {
  .title-body {
    margin-bottom: 16px;
    @include laptop {
      margin-bottom: 32px;
    }

    color: $color-text-1;
    @include body-1-bold;
  }
}

.section-content {
  padding-top: 16px;
  @include mix-border-divider($position: top, $color: $bg-6);
  @include laptop {
    @include mix-border-divider($position: top, $width: 0);
  }
}

.foot {
  @include mix-flex-column;
  @include mix-flex-centered;

  .caption-img {
    margin-top: 29px;
    color: $color-text-2;
    @include body-3-regular;

    span {
      text-transform: uppercase;
    }
  }
  @include laptop {
    margin-left: 32px;
    @include mix-border-divider($color: $bg-6);
  }
}

.group-btn .row{
  display: flex;
  margin-top: 30px;

  .group-button-left {
    margin-right: 16px;
  }

  .group-button-left, .group-button-right {
    button:disabled {
      border: 2px solid $bg-4;

      &:hover {
        background: $bg-4;
      }
    }
  }
}

.group-button-left {
  button:disabled {
    border: 2px solid $bg-4 !important;

    &:hover {
      background: $bg-4;
    }
  }
}