@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.main {
  margin-top: 30px;
  align-items: center;
  @include mix-flex-column;

  .box-message {
    background: $bg-4;
    border-radius: 12px;
    padding: 10px;
    margin-bottom: 32px;
    text-align: center;
    color: $color-accent-2;
    @include body-2-medium;
  }

  .label {
    margin-bottom: 10px;
    color: $color-text-1;
    @include body-3-bold;
  }

  .row {
    margin-left: -8px;
    margin-right: -8px;
    margin-top: 32px;
    width: 100%;
    @include flex-items-center;
    @media (min-width: 992px) {
      justify-content: space-between;
    }

    .group-button-left,
    .group-button-right {
      padding-left: 8px;
      padding-right: 8px;
      flex-grow: 1;
      display: flex;

      .btn {
        min-width: 100px;
        width: 100%;
        max-width: 576px;
      }
    }

    .group-button-right {
      justify-content: flex-end;

      .btn {
        &:disabled {
          background: $bg-brand-0;
          color: $color-white;
          opacity: 0.5;
        }
      }
    }

    .group-button-left {
      justify-content: flex-start;
    }
  }
}

.helper-text {
  margin-top: 4px;
  color: $color-accent-6;
  @include body-3-regular;

  width: 100%;
  text-align: left;
}

.text-waring {
  margin-top: 32px;
  color: $color-accent-2;
  @include body-2-medium;
}
