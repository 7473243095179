@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.page {
  border-color: $bg-6;
  background: $bg-4;
  color: $color-gray-2;
  width: 32px;
  height: 32px;
  border-radius: 4px;

  &.disabled {
    opacity: 1;
    color: $bg-8;
  }

  &.selected {
    background: $bg-gray-8;
    border-color: $color-accent-1;
  }
}

.ellipsis {
  color: $color-gray-6;
  border: 1px solid $bg-6;
  background: $bg-4;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  @include label-bold;
}
