@import 'src/styles/abstracts/variables';

.card {
  background: rgb(47 50 65 / 50%);
  position: relative;
  padding: 20px 16px;

  .body-center {
    display: block;

    .left, .right {
      display: flex;
      align-items: center;

      .name {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.1em;
        text-transform: capitalize;
        color: $color-white;
        margin-right: 16px;
        white-space: nowrap;
      }

      .status {
        padding: 5px 8px;
      }

      .-margin {
        margin-left: -4px;
        margin-right: -4px;
        display: flex;
        align-items: center;
      }

      .btn-stake {
        margin-left: 8px;
      }
    }

    .right {
      margin-top: 16px;
      flex-grow: 1;

      &.error-network {
        display: block;

        .warning {
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          .icon {
            margin-right: 8px;
            display: block;
            width: 16px;

            svg, img  {
              max-width: 100%;
            }
          }

          .text {
            color: $accent-color-200;
            font-size: 14px;
            line-height: 23px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .card {
    .body-center {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left, .right {
        margin: 0;
        padding: 0 16px;
      }

      .right {
        justify-content: flex-end;
        align-items: center;

        &.error-network {
          display: flex;
          border-left: 1px solid $text-color-300;
          justify-content: space-between;
          align-items: center;

          .warning {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}