@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.min-h-page {
  min-height: 100vh;
  padding-bottom: 70px;

  @media (max-width: 768px) {
    background: inherit;
    padding-bottom: 10px;
  }
}

.pools {
  @include mix-max-width;

  @media (max-width: 768px) {
    padding-top: 0;
  }

  .pool-header {
    padding-bottom: 16px;

    .title {
      display: flex;
      align-items: center;

      .left {
        flex-grow: 1;
        color: $text-color-50;
        font-size: 30px;
        line-height: 38px;
        font-weight: 700;
      }

      .right {
        display: flex;
        align-items: center;
        margin-left: -8px;
        margin-right: -8px;

        .select-visibility,
        .select-filter {
          padding: 0 8px;
        }

        .btn-filter {
          padding: 12px 16px;

          > svg,
          > img {
            margin-right: 12px;
          }

          &.active {
            background: #2c3561;
          }

          .badge-filter {
            width: 24px;
            height: 24px;
            border-radius: 12px;
            background: $accent-color-600;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-white;
            font-size: 16px;
            line-height: 26px;
            font-weight: 700;
            margin-right: 12px;
          }
        }
      }
    }

    .collapse-filter {
      margin-top: 16px;
      border-top: 1px solid $text-color-300;
      padding-top: 16px;

      .filter-box {
        .type-search {
          flex-grow: 1;
          padding-top: 16px;

          .label {
            display: flex;
            width: 100%;
          }
        }

        .type-select {
          padding-top: 16px;

          .label {
            color: $text-color-100;
            font-size: 16px;
            line-height: 26px;
            font-weight: 600;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .clear {
              font-size: 14px;
              line-height: 23px;
              font-weight: 600;
              color: $brand-color-300;
              display: block;
              cursor: pointer;
            }
          }

          .select-filter {
            width: 100%;
          }
        }
      }
    }
  }

  .pool-body {
    padding: 16px 0;
  }
}

.grid-col {
  flex-wrap: wrap;
  @include flex-justify-center;

  .pool-card-column {
    max-width: none;
  }
}

@media (min-width: 992px) {
  .pools {
    .pool-header {
      .title {
        .left {
          font-size: 40px;
          line-height: 48px;
        }
      }

      .collapse-filter {
        .filter-box {
          display: flex;
          align-items: flex-end;

          .type-search {
            .label {
              max-width: 286px;
            }
          }

          .type-select {
            padding-left: 16px;
            padding-right: 16px;

            .select-filter {
              min-width: 209px;
            }
          }
        }
      }
    }
  }
}

.filter-contain {
  width: 100%;
  min-width: 209px;
  align-items: stretch;
  @include mix-flex-column;

  .filter-header {
    margin-bottom: 10px;
    justify-content: space-between;
    @include flex-items-center;

    .label-filter {
      color: $color-text-2;
      @include body-2-semibold;
    }

    .end-label-filter {
      color: $color-brand-3;
      @include body-3-semibold;
    }
  }
}

.dropdown-visibility {
  min-width: 110px;
  padding-left: 12px !important;
}

.adornment-start {
  @include mix-adornment-start(24px, 24px);
  @include mix-flex-centered;
}

.value-type-visibility {
  color: $color-neutrals-7;
  @include body-2-medium;
}
