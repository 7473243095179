@import 'variables';
@import 'src/styles/base/typography';

@mixin custom-flex($dir: row, $align: center, $justify-content: space-between) {
  display: flex;
  flex-direction: $dir;
  align-items: $align;
  justify-content: $justify-content;
}

@mixin flex-items-center {
  display: flex;
  align-items: center;
}

@mixin flex-justify-center {
  display: flex;
  justify-content: center;
}

@mixin flex-justify-between {
  display: flex;
  justify-content: space-between;
}

@mixin flex-justify-start {
  display: flex;
  justify-content: flex-start;
}

@mixin flex-justify-end {
  display: flex;
  justify-content: flex-end;
}

@mixin mix-flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin mix-flex-column {
  display: flex;
  flex-direction: column;
}

@mixin mix-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet-width) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: $laptop-width) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $tablet-width) {
    @content;
  }
}

@mixin mobile-down {
  @media (max-width: $mobile-width) {
    @content;
  }
}

@mixin mix-icon-square($bg: $brand-color-50) {
  background: $bg;
  width: 12px;
  min-width: 12px;
  height: 12px;
  min-height: 12px;
  display: block;
  border-radius: 4px;
}

@mixin mix-card-rounded($bg: $bg-400) {
  background: $bg;
  border-radius: 4px;
}

@mixin mix-border-divider(
  $position: left,
  $width: 1px,
  $color: $text-color-300
) {
  border-#{$position}: $width solid $color;
}

@mixin mix-adornment-start($width: auto, $height: auto) {
  width: $width;
  height: $height;
  margin-right: 8px;
  display: inline-block;
  object-fit: cover;
}

@mixin mix-adornment-end($width: auto, $height: auto) {
  width: $width;
  height: $height;
  margin-left: 8px;
  display: inline-block;
}

@mixin mix-margin-section($position: bottom) {
  margin-#{$position}: 10px;
  @include laptop {
    margin-#{$position}: 16px;
  }
}

@mixin mix-padding-section($position: bottom) {
  padding-#{$position}: 10px;
  @include laptop {
    padding-#{$position}: 16px;
  }
}

@mixin mix-grid($space: 8px) {
  margin-left: calc($space / -2);
  margin-right: calc($space / -2);
  @include mix-flex-wrap;

  &-nowrap {
    flex-wrap: nowrap;
  }

  .grid-item {
    padding-left: calc($space / 2);
    padding-right: calc($space / 2);
    width: 100%;
    margin-top: $space;

    &-auto {
      width: auto;
    }

    &-grow-full {
      flex-grow: 1;
    }

    &-grow {
      width: auto;
      flex-grow: 1;
    }

    &-1 {
      max-width: calc(100% / 12);
    }

    &-2 {
      max-width: calc(100% / 6);
    }

    &-3 {
      max-width: calc(100% / 4);
    }

    &-4 {
      max-width: calc(100% / 3);
    }

    &-6 {
      max-width: calc(100% / 2);
    }

    &-8 {
      max-width: calc(100% / 12 * 8);
    }

    &-9 {
      max-width: calc(100% / 12 * 9);
    }

    &-12 {
      max-width: calc(100% / 1);
    }

    @include tablet {
      &-md-auto {
        width: auto;
      }

      &-md-grow-full {
        flex-grow: 1;
      }

      &-md-grow {
        width: auto;
        flex-grow: 1;
      }

      &-md-1 {
        max-width: calc(100% / 12);
      }

      &-md-2 {
        max-width: calc(100% / 6);
      }

      &-md-3 {
        max-width: calc(100% / 4);
      }

      &-md-4 {
        max-width: calc(100% / 3);
      }

      &-md-6 {
        max-width: calc(100% / 2);
      }

      &-md-8 {
        max-width: calc(100% / 12 * 8);
      }

      &-md-9 {
        max-width: calc(100% / 12 * 9);
      }

      &-md-12 {
        max-width: calc(100% / 1);
      }
    }

    @include laptop {
      &-lg-auto {
        width: auto;
      }

      &-lg-grow-full {
        flex-grow: 1;
      }

      &-lg-grow {
        width: auto;
        flex-grow: 1;
      }

      &-lg-1 {
        max-width: calc(100% / 12);
      }

      &-lg-2 {
        max-width: calc(100% / 6);
      }

      &-lg-3 {
        max-width: calc(100% / 4);
      }

      &-lg-4 {
        max-width: calc(100% / 3);
      }

      &-lg-6 {
        max-width: calc(100% / 2);
      }

      &-lg-8 {
        max-width: calc(100% / 12 * 8);
      }

      &-lg-9 {
        max-width: calc(100% / 12 * 9);
      }

      &-lg-12 {
        max-width: calc(100% / 1);
      }
    }
  }
}

@mixin mix-view-row($bg: $bg-4, $radius: 0, $px: 32px, $py: 21.5px) {
  width: 100%;
  background: $bg;
  padding: $py 0;
  border-radius: $radius;
  @include flex-items-center;

  .aside {
    padding: 0 $px;
    @include flex-items-center;

    .adornment {
      margin-right: 8px;
      @include mix-icon-square;
    }

    .label {
      color: $color-text-2;
      @include body-4-medium;
    }

    .value {
      color: $color-text-1;
      @include body-3-bold;
    }

    &.grow {
      flex-grow: 1;
      align-items: flex-end;
      overflow: hidden;
      @include mix-flex-column;

      .value-ellipsis {
        width: 100%;
        line-height: 0;
        text-align: right;

        .value {
          display: inline-block;
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

@mixin mix-grow-ellipsis($align: right) {
  flex-grow: 1;
  overflow: hidden;
  align-items: flex-end;
  @include mix-flex-column;

  .ellipsis {
    width: 100%;
    line-height: 0;
    text-align: $align;

    .value {
      display: inline-block;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

// base page

@mixin mix-max-width {
  padding: 0 15px;
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  @include tablet {
    padding: 0 25px;
  }
}
