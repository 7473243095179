@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';

.social-menu-paper {
  background-color: $bg-5;
  overflow: unset;
  top: 10px;
  margin-top: 12px;
  border-radius: 16px;
}

.social-menu-list::before {
  // using clip-path
  content: '';
  display: block;
  height: 20px;
  width: 20px;
  border: inherit;
  position: absolute;
  top: -10px;
  left: calc(50% - 10px);
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(135deg);
  border-radius: 0 0 0 10px;
  background-color: $bg-5;
}

.social-menu-list {
  flex-direction: column;
  @include flex-items-center;

  a {
    margin-top: 15px;

    &:first-child {
      margin-top: 0;
    }

    svg path {
      fill: $color-text-1;
    }
  }
}

.link-icon {
  color: $color-text-3;

  &:hover {
    color: $color-white;
  }
}
