@import 'src/styles/abstracts/mixins';
@import 'src/styles/abstracts/variables';
@import 'src/styles/base/typography';

.thumb-detail {
  margin-bottom: 32px;
  @include flex-justify-center;

  .img-apply-whitelist {
    width: auto;
    max-width: 100%;
    max-height: 160px;
    object-fit: contain;
  }
}

.reference-list {
  padding-left: 0;
  list-style: none;
  display: block;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 0;

  .modal-list-item {
    display: block;

    + .modal-list-item {
      margin-top: 4px;
    }

    .item-link {
      color: $color-text-2;
      background: $bg-4;
      padding: 13px 16px;
      @include body-2-medium;
      @include flex-items-center;

      &:hover {
        color: $color-brand-4;
      }

      .item-icon {
        @include flex-items-center;
        @include mix-adornment-start(24px, 24px);
      }

      .item-name {
        display: flex;
        flex-grow: 1;
      }

      .item-ref {
        @include mix-adornment-end;
      }
    }
  }
}
