@import '../abstracts/variables';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');

@font-face {
  font-family: Inter;
  src: url('../fonts/Inter.ttf') format('woff');
}

@font-face {
  font-family: R-Flex-Bold;
  src: url('../fonts/R-FLEX-BOLD.otf') format('woff');
}

@font-face {
  font-family: R-Flex-Regular;
  src: url('../fonts/R-FLEX-REGULAR.otf') format('woff');
}

@font-face {
  font-family: icons;
  src: url('../fonts/icons/icons.eot?hoymjw');
  src: url('../fonts/icons/icons.eot?hoymjw#iefix') format('embedded-opentype'),
    url('../fonts/icons/icons.ttf?hoymjw') format('truetype'),
    url('../fonts/icons/icons.woff?hoymjw') format('woff'),
    url('../fonts/icons/icons.svg?hoymjw#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='custom-icon-'],
[class*=' custom-icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: icons, sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img[src='/images/warning-red.svg'],
img[src='/images/icons/close.svg'],
img[src='/images/red-warning.svg'] {
  height: 24px;
  width: 24px;
}

img[src='/images/icons/icon_progress.svg'] {
  top: -18px;
  right: -36px;
  width: 40px;
  height: 40px;
}

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: $body-font-family;
  color: $text-color-100;
  font-size: $text-base-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  margin: 0;
}

h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
}

h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}

h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
}

h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
}

h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}

@mixin label-bold {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
}

@mixin caption {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

/** font label-bold */

@mixin body-1-regular {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
}
@mixin body-2-regular {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}
@mixin body-3-regular {
  font-size: 14px;
  line-height: 23px;
  font-weight: 400;
}
@mixin body-4-regular {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

/** font body-regular */

@mixin body-1-medium {
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
}
@mixin body-2-medium {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
}
@mixin body-3-medium {
  font-size: 14px;
  line-height: 23px;
  font-weight: 500;
}
@mixin body-4-medium {
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
}

/** font body-medium */

@mixin body-1-semibold {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
}
@mixin body-2-semibold {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
}
@mixin body-3-semibold {
  font-size: 14px;
  line-height: 23px;
  font-weight: 600;
}
@mixin body-4-semibold {
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
}

/** font body-semibold */

@mixin body-1-bold {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}
@mixin body-2-bold {
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
}

@mixin body-3-bold {
  font-size: 14px;
  line-height: 23px;
  font-weight: 700;
}
@mixin body-4-bold {
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
}

/** font body-bold */

@mixin h5-bold {
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
}
@mixin h-6-bold {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
}
@mixin hairline-2 {
  font-size: 12px;
  line-height: 12px;
  font-weight: 700;
}

@mixin h2-bold {
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
}

@mixin h3-bold {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
}
@mixin h4-bold {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
}

@mixin display-2-bold {
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
}
@mixin display-3-bold {
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
}
