@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/extends';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.adornment-end {
  @include flex-items-center;

  .slot {
    margin-left: 6px;
  }
}

.brand {
  height: 32px;
  padding: 5px;
  border-radius: 32px;
  background: $bg-6;
  @include mix-flex-centered;

  .logo-brand {
    @include mix-adornment-start(22px, 22px);

    margin-right: 5px;
    @include mix-flex-centered;

    img,
    svg {
      width: 100%;
      max-width: 100%;
      border-radius: 22px;
      object-fit: contain;
    }
  }

  .symbol {
    display: inline-block !important;
    color: $color-text-1;
    text-transform: uppercase;
    margin: 0 5px;
    @include body-3-medium;
  }
}
