@import 'src/styles/abstracts/variables';

.toast-notify {
  display: flex;
  margin: 20px 20px 24px;
  align-items: center;

  svg {
    width: 33px;
    height: 33px;
  }

  @media (max-width: 768px) {
    margin: 20px 10px 20px 16px;
  }

  .content {
    margin-left: 13px;

    @media (max-width: 768px) {
      margin-left: 10px;
    }
  }

  .title {
    font-family: R-Flex-Bold, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 8px;
  }

  .message {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $text-color-50;
  }

  &.success {
    .title {
      color: $accent-color-500;
    }
  }

  &.warning {
    .title {
      color: $accent-color-200;
    }
  }

  &.error {
    .title {
      color: $accent-color-600;
    }
  }

  &.info {
    .title {
      color: $brand-color-300;
    }
  }
}

.Toastify {
  .Toastify__toast-container {
    width: 438px;
    right: 10px;
    left: inherit;

    @media (max-width: 768px) {
      width: 350px;
    }
  }

  .Toastify__toast {
    word-break: break-word;
    background: $bg-200;
    box-shadow: 0 64px 64px -48px rgb(15 15 15 / 10%);
    border-radius: 16px;
    padding: 0;

    @media (max-width: 768px) {
      margin-top: 10px;
    }
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__close-button {
    margin: 20px 20px 0 0;
    color: $color-white;
    opacity: 1;

    > svg {
      width: 22px;
      height: 22px;
    }
  }
}