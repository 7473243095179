@import 'src/styles/abstracts/variables';

.sub-card {
  background: rgb(47 50 65 / 50%);

  // backdrop-filter: blur(64px);
  border-radius: 4px;
  padding: 16px;

  + .sub-card {
    margin-top: 4px;
  }

  &.group-inner {
    background: transparent;
    padding-top: 0;
  }

  .card-inner {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px;

    .sub-card-progress {
      background: rgb(47 50 65 / 50%);
      order: -1;
      width: 100%;
      margin-bottom: 4px;
      padding: 16px;
      border-radius: 4px;

      .title {
        margin-bottom: 12px;

        .text {
          color: $text-color-100;
          font-size: 12px;
          line-height: 20px;
          font-weight: 700;
        }
      }
    }

    .sub-card {
      width: 100%;
    }

    .title {
      .text {
        color: $text-color-100;
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;

        &--warning {
          color: rgb(240 116 22 / 100%);
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }

  .title {
    display: flex;
    align-items: center;

    .icon {
      width: 12px;
      height: 12px;
      display: block;
      border-radius: 4px;
      background: $accent-color-100;
      margin-right: 8px;
    }

    .text {
      color: $text-color-100;
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
    }
  }

  .content-text {
    .from,
    .to {
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        color: $text-color-100;
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;

        .value {
          font-size: 12px;
          line-height: 20px;
          font-weight: 700;
        }
      }

      .value {
        flex-grow: 1;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 700;
      }

      .highlight {
        color: $color-white;
      }
    }
  }

  .top {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;

    .label {
      color: $text-color-50;
      font-size: 14px;
      line-height: 23px;
      font-weight: 500;
    }

    .value {
      flex-grow: 1;
      color: #fcfcfd;
      font-size: 14px;
      line-height: 23px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .middle {
    margin-bottom: 16px;

    .form-control {
      margin-bottom: 16px;

      .input-control {
        display: flex;
        align-items: center;
        padding: 0 16px;
        width: 100%;
        border: 2px solid $text-color-300;
        border-radius: 12px;
        height: 50px;

        input,
        .text-input {
          display: block;
          flex-grow: 1;
          border: none;
          outline: none;
          background: transparent;
          font-size: 16px;
          line-height: 26px;
          font-weight: 400;
          color: $color-white;
        }

        .symbol {
          height: 32px;
          border-radius: 16px;
          padding: 5px;
          padding-right: 9px;
          display: flex;
          align-items: center;
          background: $bg-100;
          color: $text-color-50;
          font-size: 14px;
          line-height: 23px;
          font-weight: 500;
          min-width: max-content;

          svg,
          img {
            margin-right: 5px;
            object-fit: contain;
            max-width: 22px;
            height: auto;
            display: block;
          }
        }

        .label {
          color: $color-white;
          font-size: 16px;
          line-height: 26px;
          font-weight: 400;
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
  }

  &.card-in-modal {
    background: transparent;
    padding: 32px 0;
    border-radius: 0;

    .modal-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: auto;
      padding: 10px;
      background: $bg-300;
      box-shadow: 0 64px 64px -48px rgb(15 15 15 / 10%);

      .row {
        margin-left: -8px;
        margin-right: -8px;
        display: flex;
        align-items: center;

        .col-btn {
          width: 100%;
          padding: 0 8px;

          button {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .card {
    display: flex;
    background: rgb(47 50 65 / 50%);
    backdrop-filter: blur(64px);
    border-radius: 4px;
    padding: 16px 0;

    .card-inner {
      display: flex;
      flex-wrap: wrap;
      margin-left: -32px;
      margin-right: -32px;
      padding-top: 16px;

      .sub-card {
        width: 50%;
      }

      .sub-card-progress {
        background: transparent;
        padding: 16px 32px;
        margin-top: 16px;
        order: 0;
        margin-bottom: 0;

        .title {
          .text {
            font-size: 14px;
            line-height: 23px;
          }
        }
      }
    }
  }

  .sub-card {
    width: 50%;
    flex-grow: 1;
    background: transparent;
    backdrop-filter: unset;
    padding: 0 32px;
    border-radius: 0;

    + .sub-card {
      margin-top: 0;
      border-left: 1px solid $text-color-300;
    }

    .title {
      .text {
        font-size: 14px;
        line-height: 23px;
      }
    }

    .content {
      .from,
      .to {
        .label {
          font-size: 14px;
          line-height: 23px;
        }

        .value {
          font-size: 14px;
          line-height: 230px;
        }
      }
    }
  }
}

.root-progress {
  background: $color-text-4;
  height: 6px;
  border-radius: 3px;
}

.bar-progress {
  background: $bg-accent-5;
  height: 6px;
  border-radius: 3px;
}
