@import 'src/styles/abstracts/variables';

.container {
  width: 100%;
  height: 375px;
  display: flex;

  &__chart {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;

    &__inner-circle {
      position: absolute;
      z-index: 0;
      border-radius: 50%;
      background-color: rgb(31 38 70 / 100%);
      width: 330px;
      height: 330px;
      top: 50%;
      transform: translateY(-50%);
    }

    &__wrapper {
      position: relative;
      height: 110%;
    }

    &__element {
      z-index: 99;
      object-fit: contain;
    }

    &__legend {
      margin-top: 40px;
      width: 40%;

      &__list {
        display: flex;
        margin: 0;
        padding: 0;
        flex-flow: row wrap;
        width: 100%;

        &__item {
          align-items: center;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          margin-left: 10px;
          width: calc(50% - 10px);
          margin-bottom: 47px;
        }

        &__color-box {
          height: 20px;
          min-width: 46px;
          border-radius: 28px;
        }

        &__text {
          margin: 0;
          margin-left: 8px;
          padding: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 23px;
          color: $text-color-100;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .container {
    height: 100%;
    display: block;

    &__chart {
      width: 100% !important;

      &__inner-circle {
        width: 270px !important;
        height: 270px !important;
      }

      &__wrapper {
        width: 40% !important;
      }

      &__legend {
        display: block;
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    height: auto;

    &__chart {
      &__inner-circle {
        width: 166px !important;
        height: 166px !important;
      }

      &__wrapper {
        width: 150px !important;
        height: 200px ;
      }

      &__legend {
        margin-bottom: 0;

        &__list {
          &__item {
            margin-bottom: 30px;
          }

          &__color-box {
            height: 12px;
            min-width: 28px;
            border-radius: 8px;
          }

          &__text {
            margin: 0;
            margin-left: 8px;
            padding: 0;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: $text-color-100;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

    }
  }
}