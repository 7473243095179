@import 'src/styles/abstracts/mixins';
@import 'src/styles/abstracts/variables';

.profile {
  @include mix-max-width;

  margin-bottom: 130px;

  @media (max-width: 768px) {
    margin-bottom: 80px;
  }

  .box-info {
    width: 100%;
    margin: 0;

    .info-account {
      background: $bg-400;
      box-shadow: 0 64px 64px -48px rgb(15 15 15 / 10%);
      border-radius: 16px;
      padding: 0 32px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 16px;

      .info {
        margin-bottom: 40px;
        @include flex-items-center;

        .info-rank {
          flex-grow: 1;
          @include flex-items-center;

          .icon {
            width: auto;
            height: auto;
            max-width: 112px;
            object-fit: contain;
            margin-right: 16px;
          }
        }

        .rank {
          font-family: R-Flex-Bold, sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 48px;
          color: $text-color-50;
          margin-bottom: 16px;
          @media only screen and (max-width: 992px) {
            text-align: center;
          }
        }

        .account-unverified {
          display: flex;

          .button-kyc {
            margin-left: 16px;
          }
        }

        .badge {
          padding: 8px 16px;
          border-radius: 90px;
        }
      }

      .address-wallet-container {
        flex-grow: 1;
        padding: 12px 0;
        border-left: 1px solid $bg-100;
        @include flex-justify-end;
        @include mobile {
          border-top: 1px solid $bg-100;
          border-left: 0;
          padding: 15px 0;
          margin: 16px 0;
        }
      }

      .address-wallet {
        display: flex;
        padding: 15px 0 15px 60px;
        border-left: 1px solid $bg-100;

        .address {
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          color: $text-color-50;
          padding-left: 8px;
          border-left: 1px solid $text-color-300;
          margin-left: 8px;

          svg {
            margin-left: 12px;
          }
        }
      }

      .connect-wallet {
        width: 100%;
        margin: 0;

        .connect-wallet-box {
          padding-right: 14px;
          padding-left: 0;

          .connect-wallet-content {
            border: 2px solid rgb(202 204 209 / 20%);
            border-radius: 16px;
            padding: 26px 32px;
            display: flex;
            justify-content: space-between;
            height: 100%;

            .info-wallet {
              display: flex;

              svg {
                margin-right: 8px;
              }

              .connect-status {
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                color: #808191;
                margin-bottom: 4px;
              }

              .network {
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                color: $color-white;
                margin-bottom: 5px;
              }

              .address {
                font-size: 14px;
                line-height: 23px;
                color: $text-color-50;

                svg {
                  margin-left: 8px;
                }
              }
            }

            .icon-status-connect {
              border: 8px solid $text-color-200;
              width: 24px;
              height: 24px;
              border-radius: 100px;
            }
          }
        }

        .box-left {
          padding-right: 14px;
          padding-left: 0;
        }

        .box-right {
          padding-left: 14px;
          padding-right: 0;
        }

        .box-active {
          .connect-wallet-content {
            border: 2px solid $accent-color-500;

            .connect-status {
              color: #40c36d !important;
            }

            .icon-status-connect {
              border: 8px solid $accent-color-500;
            }
          }
        }
      }
    }

    .info-staking {
      padding-right: 0;
      margin-bottom: 16px;
      padding-left: 16px;

      .group-staking-item {
        border-radius: 12px;
        overflow: hidden;

        .info-staking-item {
          + .info-staking-item {
            margin-top: 4px;
          }
          @include mix-view-row($bg: $bg-3);

          .view-more {
            padding: 0 32px;
            width: 100%;
            @include flex-justify-center;

            button {
              border: none;
              outline: none;
              color: $color-brand-3;
              background: transparent;
              @include body-3-semibold;
              @include mix-flex-centered;

              svg {
                margin-left: 4px;
              }
            }
          }
        }
      }
    }
  }

  .staking {
    background: $bg-400;
    border-radius: 16px;
    padding-bottom: 5px;
    margin-bottom: 16px;

    .label {
      font-family: R-Flex-Bold, sans-serif;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      color: #fcfcfd;
      padding: 32px 0 0 32px;
    }
  }
}

.tabs-account {
  padding: 0 3px;

  .tab-nav {
    border-bottom: 1px solid $text-color-300;
    margin-bottom: 16px;

    .tab-list {
      display: flex;
      margin-bottom: 0;
      padding-left: 0;

      .tab-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 0;
        width: 100%;
        color: $text-color-100;
        font-size: 14px;
        font-weight: 500;
        border-bottom: 3px solid transparent;
        transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);

        @media (max-width: 768px) {
          padding-top: 0;
        }

        &.tab-active {
          color: $brand-color-200;
          border-color: $brand-color-200;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .info-account,
  .info-staking {
    width: 100%;
  }

  .info-account {
    padding-bottom: 32px !important;
  }

  .info-staking {
    padding-left: 0 !important;
  }
}

@media (min-width: 992px) {
  .tabs-account {
    margin-bottom: 16px;
  }
}

@media (max-width: 992px) {
  .profile {
    margin-bottom: 34px;

    .box-info {
      margin-top: 16px;
      padding: 0 3px;

      .info-account {
        padding-left: 16px;
        padding-right: 16px;

        .info {
          margin: 16px 0;
          flex-direction: column;

          .info-rank {
            flex-direction: column;

            .account-unverified {
              justify-content: center;
              align-items: center;
            }
          }
        }

        .address-wallet {
          border-left: none;
          padding: 16px 0;
          border-top: 1px solid $bg-100;
          margin-top: 16px;
        }

        .connect-wallet {
          .box-left,
          .box-right {
            padding: 0;
            margin-bottom: 16px;
          }

          .info-wallet {
            .address {
              display: none;
            }
          }
        }
      }

      .info-staking {
        padding-left: 0;
        border-radius: 12px;
        overflow: hidden;

        .info-staking-item {
          border-radius: 0;
          padding: 24px 16px;

          &:first-child,
          &:last-child {
            border-radius: 0;
          }

          .label {
            .text {
              font-size: 12px;
              font-weight: 500;
              line-height: 20px;
            }
          }

          .value {
            font-weight: 700;
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.network-links {
  display: flex;
  width: 100%;

  @include mobile {
    flex-direction: column;
  }

  > div:not(:last-child) {
    margin-right: 28px;
  }
}

.wallet-address {
  margin-left: 8px;
  position: relative;
  padding-left: 8px;

  &::before {
    content: '';
    position: absolute;
    height: 20px;
    width: 1px;
    background-color: $text-color-300;
    left: 0;
  }
}

.button-container {
  min-height: 134px;
  border: 2px solid rgb(202 204 209 / 20%);
  padding: 16px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: unset;
  @include desktop {
    padding: 26px 32px;
  }

  @include mobile {
    min-height: auto;
    margin-bottom: 16px;
  }
}

.pointer {
  cursor: pointer;
}

.button-connected {
  border-color: #40c36d;
  background-color: rgb(64 195 109 / 10%);
}

.left {
  display: flex;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.description {
  text-align: left;
  margin-left: 8px;
}

.status-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 4px;
}

.not-connected-text {
  color: $text-color-300;
  color: #808191;
}

.connected-text {
  color: $bg-accent-5;
}

.wallet-name {
  color: $text-color-50;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-transform: capitalize;
  margin-bottom: 4px;
}

.status-point {
  border: 8px solid;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.not-connected-point {
  border-color: $text-color-300;
}

.connected-point {
  border-color: $bg-accent-5;
}
