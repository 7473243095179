@import 'src/styles/abstracts/mixins';

%view-rounded-hidden {
  border-radius: 12px;
  overflow: hidden;
  background: transparent;
}

%view-block-rounded {
  padding: 16px;
  border-radius: 4px;
  @include tablet {
    padding: 25px 24px;
  }
}

%view-icon-square-blue {
  @include mix-icon-square;

  margin-right: 8px;
}

%view-icon-square-violet {
  @include mix-icon-square($bg: $accent-color-100);

  margin-right: 8px;
}

%view-single-page {
  padding-top: 84px;
  @include tablet {
    padding-top: 100px;
  }
}

%view-grid-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -4px;
  margin-top: -4px;

  .grid-item {
    padding-left: 4px;
    padding-top: 4px;
    flex-grow: 1;
    width: 100%;

    .row {
      display: flex;
      align-items: center;

      + .row {
        margin-top: 8px;
      }

      .col {
        &.grow {
          flex-grow: 1;
        }
      }
    }

    &.col-lg-1 {
      @include tablet {
        max-width: calc(100% / 12);
      }
    }

    &.col-lg-2 {
      @include tablet {
        max-width: calc(100% / 6);
      }
    }

    &.col-lg-3 {
      @include tablet {
        max-width: calc(100% / 4);
      }
    }

    &.col-lg-4 {
      @include tablet {
        max-width: calc(100% / 3);
      }
    }

    &.col-lg-6 {
      @include tablet {
        max-width: calc(100% / 2);
      }
    }

    &.col-lg-12 {
      @include tablet {
        max-width: calc(100% / 1);
      }
    }
  }
}

%view-row-wrapper {
  display: flex;
  align-items: center;
  width: 100%;

  .field {
    display: flex;
    align-items: center;
  }

  .grow {
    flex-grow: 1;
  }
}

%view-btn-collap {
  @include mix-flex-centered;

  border: none;
  outline: none;
  background: transparent;
  position: relative;

  .icon-collap,
  .icon-collapse {
    position: relative;
    display: block;
    width: 16px;
    height: 2px;
    overflow: visible;
    background: $color-accent-1;

    &::before {
      content: '';
      display: block;
      background: $color-accent-1;
      width: 2px;
      height: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.05s linear;
    }
  }

  &.active,
  &.show,
  &.open {
    .icon-collap::before,
    .icon-collapse::before {
      height: 16px;
    }
  }
}

%view-icon-checkmark {
  content: '\2713';
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
