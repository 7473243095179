@import 'src/styles/base/typography';
@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';

.modal-signature {
  flex-direction: column;
  @include mix-flex-centered;

  .thumb-signature {
    margin-bottom: 8px;

    svg,
    img {
      width: 100%;
      max-width: 100%;
      max-height: 160px;
      object-fit: contain;
    }
  }

  .modal-text {
    margin-bottom: 32px;
    color: $color-text-2;
    text-align: center;
    @include body-2-medium;
  }
}
