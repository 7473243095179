@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/abstracts/extends';
@import 'src/styles/base/typography';
@import '../BaseLbp';

.helper-text {
  margin-top: 4px;
  color: $color-accent-6;
  @include body-4-regular;
}

@include laptop {
  .wrap-infomation {
    margin: 0 -40px;

    .card {
      padding: 0 40px !important;
    }

    .card-shrink {
      @include mix-border-divider($color: $bg-6);

      max-width: 33%;
    }
  }
}

.group-info {
  flex-direction: column;
  @include mix-flex-centered;

  .head {
    margin-bottom: 24px;

    .symbol {
      margin: 0 auto 10px;
      width: 45px;
      height: 40px;
      position: relative;
      text-align: center;

      img {
        height: 100%;
        width: auto;
        max-height: 100%;
        max-width: 100%;
        object-fit: contain;
      }

      &::after {
        @include mix-flex-centered;

        border: 2px solid $bg-2;
        text-align: center;
        color: white;
        position: absolute;
        bottom: -2px;
        right: 0;
        width: 18px;
        height: 18px;
        border-radius: 18px;
        background: $bg-accent-5;
        font-size: 12px;
      }

      &.valid::after {
        @extend %view-icon-checkmark;
      }

      &.invalid::after {
        content: none;
      }
    }

    .name {
      color: $color-text-1;
      @include display-3-bold;

      text-transform: uppercase;
    }
  }

  .info-row {
    @include flex-justify-center;

    + .info-row {
      margin-top: 12px;
    }

    .label {
      color: $color-text-2;
      @include body-4-regular;

      margin-right: 4px;
      white-space: nowrap;
    }

    .value {
      color: $color-text-1;
      @include body-4-bold;
    }
  }
}

.required {
  color: $color-accent-6;
}

.warning-token {
  color: $color-accent-2;
  display: flex;
  align-items: center;
  flex-direction: column;

  span {
    text-align: center;
    margin-top: 12px;
  }
}