@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/extends';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.root {
  border: 2px solid $color-text-4;
  padding: 0 16px;
  border-radius: 12px;
  box-sizing: border-box;

  &-medium {
    height: 50px;
  }

  &-small {
    height: 40px;
  }
}

.input,
.textare {
  color: $color-text-1;
  @include body-2-regular;
}

.textare {
  padding: 12px 0;
}

.disabled {
  background: $bg-6;
  border-color: $bg-6;
  color: $color-text-2;
}

.focused {
  border-color: $color-brand-3;
}

.error {
  color: $color-accent-6;
  border-color: currentcolor;
}

.notchedOutline {
  display: none;
}

.hideArrowInputNumber {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    appearance: textfield;
  }
}

.inputAdornedStart {
  padding-left: 14px;
}
