@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.container-list {
  width: 100%;
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 32px;

  .view-row + .view-row {
    margin-top: 4px;
  }
}

.token-rate {
  margin-bottom: 32px;
  color: $color-text-2;
  text-align: center;
  @include body-3-medium;
}

.input-control {
  width: 100%;

  .label {
    margin-bottom: 10px;
    display: block;
    color: $color-text-1;
    @include body-3-bold;
  }
}

.view-row {
  padding: 15px 0;
  background: $bg-4;
  @include flex-items-center;

  .aside {
    padding: 0 16px;
    @include flex-items-center;

    .adornment {
      margin-right: 8px;
      @include mix-icon-square;
    }

    .label {
      color: $color-text-2;
      @include body-4-medium;
    }

    .value {
      color: $color-text-1;
      @include body-3-bold;
    }

    &.grow {
      flex-grow: 1;
      align-items: flex-end;
      overflow: hidden;
      @include mix-flex-column;

      .overflow-ellip {
        width: 100%;
        line-height: 0;
        text-align: right;

        .value {
          display: inline-block;
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
