@import 'src/styles/abstracts/variables';

.modal-warning-lbp {
  .main {
    display: flex;
    align-items: center;
    flex-direction: column;

    .description {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: $text-color-100;
      margin: 20px 0 0;
      text-align: center;
      font-family: Inter, sans-serif;
    }
  }
}
