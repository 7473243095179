@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';
@import 'src/styles/abstracts/extends';

.form-swap {
  @include mix-card-rounded;
  @extend %view-block-rounded;

  height: 100%;

  .form-header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    @include custom-flex;

    .title {
      font-size: 22px;
      line-height: 30px;
      font-weight: 700;
      color: $color-white;
    }
  }

  .form-body {
    @include mix-border-divider($position: top);

    padding-top: 24px;

    .input-group {
      &.input-disabled {
        opacity: 0.5;
      }

      .header-input {
        @include custom-flex;

        margin-bottom: 8px;
      }

      .label {
        @include body-3-bold;

        color: $text-color-50;
      }

      .balance {
        @include body-3-regular;

        color: $color-text-2;
      }

      .message-error {
        @include body-3-regular;

        color: $color-accent-6;
        margin-top: 4px;
      }

      .input {
        .adornment-end {
          height: 32px;
          padding: 5px;
          border-radius: 32px;
          background: $bg-6;
          @include mix-flex-centered;

          .logo-brand {
            border-radius: 22px;
            @include mix-adornment-start(22px, 22px);

            margin-right: 5px;
          }

          .symbol {
            display: inline-block;
            color: $color-text-1;
            text-transform: uppercase;
            margin-right: 5px;
            @include body-3-medium;
          }
        }
      }

      .error {
        border: 2px solid $color-accent-6;
      }

      .balance-value {
        @include body-3-regular;

        margin-top: 4px;
      }
    }

    .swap-icon {
      @include mix-flex-centered;

      margin: 24px 0;

      svg {
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 0.3s;
        cursor: pointer;
      }

      &.rotated {
        svg {
          transform: rotate(360deg);
        }
      }
    }

    .info-price {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;

      .title {
        @include body-4-medium;

        color: $color-text-1;
      }

      .value {
        @include body-4-bold;

        color: $color-brand-4;
      }
    }

    .button {
      margin-top: 32px;

      > button {
        width: 100%;
        margin-top: 16px;
      }

      .btn-approve-disable:disabled {
        background: $bg-brand-0;
        opacity: 0.5;
      }
    }
  }

  .form-footer {
    padding-top: 8px;
  }
}
