@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';
@import './BaseLbp';

.create-lbp-page {
  @include mix-max-width;
}

.dashboard {
  @include laptop {
    @include flex-justify-center;
  }

  .side {
    @include laptop {
      flex-shrink: 1;
    }
  }

  .detail {
    padding-bottom: 48px;
    @include laptop {
      flex-grow: 1;
      @include mix-padding-section($position: top);
    }

    .main-content {
      @include laptop {
        padding: 0 40px;
        @include flex-justify-center;
        @include mix-border-divider($color: $bg-4);
      }

      .container {
        width: 100%;
        @include laptop {
          border-radius: 16px;
          padding: 40px;
          background: $bg-3;
          max-width: 798px;
        }
      }
    }
  }
}
