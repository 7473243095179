@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.adornment-network-header {
  @include mix-adornment-start($width: 30px, $height: 30px)
}

.adornment-network-body {
  margin-left: 10px;
  @include mix-adornment-start($width: 26px, $height: 26px);
}

.adornment-primary {
  margin-right: 0;
}

.value-network-header {
  color: $color-text-1;
  @include body-3-medium;
}

.value-network-body {
  color: $color-text-1;
  @include body-2-regular;
}

