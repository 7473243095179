@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/extends';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.my-lbp {
  background: $bg-400;
  border-radius: 16px;
  margin-bottom: 100px;

  .header {
    @include flex-items-center;

    justify-content: space-between;
    padding: 32px 32px 0;

    .title {
      @include h4-bold;

      color: $text-color-50;
    }

    button {
      padding: 12px 16px;
      color: $color-text-1;
      @include body-2-medium;

      svg {
        margin-right: 10px;

        path {
          fill: $color-text-1;
        }
      }
    }
  }

  .table-row {
    margin: 0;

    &:nth-child(even) {
      background-color: $bg-4;
    }

    &:hover {
      background-color: $bg-5;
    }

    .link-detail {
      width: 30px;
      display: flex;
      justify-content: flex-end;
    }

    .table-cell {
      width: 15%;
      padding: 0 5px;
      justify-content: flex-start;
      @include flex-items-center;

      &.name {
        width: 18%;
      }

      &.network {
        width: 18%;
      }

      &.token {
        width: 12%;
      }

      &.text-right {
        justify-content: flex-end;
      }

      &.action {
        display: flex;
        justify-content: flex-end;

        button:disabled {
          background: $bg-6;
        };
      }
    }

    .name {
      @include flex-items-center;
    }

    @include flex-items-center;

    padding: 16px 32px;
    @include body-3-medium;

    color: $color-text-1;

    .img-cell {
      width: 32px;
      height: 32px;
      border-radius: 100px;
      margin-right: 8px;
    }

    .img-token {
      width: 22px;
      height: 22px;
      margin-left: 8px;
    }

    .status {
      margin-right: 10px;
    }

    .token-name {
      @include body-2-bold;

      color: $color-neutrals-7;
      margin-right: 10px;
    }
  }

  .lbp-list {
    margin-top: 70px;
    padding-bottom: 30px;

    .header-lbp-list {
      margin-bottom: 24px;
      @include body-4-semibold;

      color: $color-text-2;

      .table-row {
        @include body-4-semibold;

        color: $color-text-2;

        &:hover {
          background-color: inherit;
        }
      }
    }
  }
}

.card-lbp {
  background: $bg-400;
  box-shadow: 0 4px 4px rgb(0 0 0 / 16%);
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 32px;
  }

  .card-lbp-header {
    padding-bottom: 20px;

    .flex-header {
      position: relative;
      display: flex;
      align-items: center;
      padding-right: 50px;

      .link-pool {
        display: flex;
        align-items: center;
      }

      .icon {
        display: block;
        margin-right: 8px;
        width: 34px;

        svg,
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .img-cell {
          width: 32px;
          height: 32px;
          border-radius: 100px;
          margin-right: 8px;
          object-fit: cover;
        }
      }

      .title {
        color: $color-white;
        @include body-2-bold;

        margin: 0;
        cursor: pointer;
        white-space: nowrap;
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icon-collapse {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        display: block;
        overflow: visible;
        padding: 1px 8px;
        background: $accent-color-100;

        &::after {
          content: '';
          display: block;
          position: absolute;
          padding: 1px;
          background: inherit;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
        }

        &.collapsed::after {
          padding: 8px 1px;
        }
      }
    }
  }

  .card-lbp-subheader, .card-lbp-item {
    @include flex-justify-between;

    align-items: center;

    .label, .text {
      color: $text-color-100;
      @include body-3-regular;
    }
  }

  .card-lbp-body {
    .card-lbp-item {
      margin-top: 16px;

      .text {
        @include body-3-bold;

        color: $color-text-1;

        img {
          width: 22px;
          height: 22px;
          border-radius: 100px;
          margin-right: 8px;
        }
      }
    }

    .withdraw {
      @include flex-justify-center;

      margin-top: 16px;

      button:disabled {
        background: $bg-3;
      }
    }
  }
}

.my-lbp-mobile {
  .btn-add-lbp {
    @include flex-justify-center;

    margin-top: 16px;
    width: 100%;

    button {
      width: 100%;

      svg {
        margin-right: 10px;

        path {
          fill: $color-brand-4;
        }
      }
    }
  }
}