@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';
@import 'src/styles/abstracts/extends';

.single-page {
  @extend %view-single-page;

  background: $bg-1;

  .max-width-content {
    @include mix-max-width;

    min-height: 100vh;

    .lbp-detail {
      padding: 10px 0 80px;
      @include tablet {
        padding: 32px 0 130px;
      }

      .detail-body {
        overflow: hidden;
        @include tablet {
          border-radius: 12px;
        }

        .detail-content {
          margin-top: 10px;
          @include tablet {
            margin-top: 4px;
          }

          .content-item {
            + .content-item {
              margin-top: 10px;
              @include tablet {
                margin-top: 4px;
              }
            }

            &.group-tab-desktop {
              @include tablet {
                background: $bg-3;
                border-radius: 16px;
                overflow: hidden;
                margin-top: 16px;
              }
            }

            &.after-rounded {
              border-bottom-left-radius: 16px;
              border-bottom-right-radius: 16px;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

.lbp-swap {
  @include tablet {
    display: flex;
    align-items: stretch;
    margin-left: -4px;
  }

  .swap-aside {
    width: 100%;
    @include tablet {
      padding-left: 4px;
    }

    &.chart {
      @include tablet {
        max-width: 60%;
        flex-grow: 1;
      }
      @include desktop {
        max-width: calc(100% / 3 * 2);
      }
    }

    &.form {
      @include tablet {
        @include tablet {
          max-width: 40%;
          flex-grow: 1;
        }
      }
      @include desktop {
        max-width: calc(100% / 3);
      }
    }
  }
}
