@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.wrapper {
  background-color: $bg-1;
  padding-top: 94px;
  @include tablet {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  .main-content {
    @include mix-max-width;
  }
}

.lbp-container-mobile {
  .lbp-desktop {
    max-width: unset;
  }
}

.lbp-container {
  @include custom-flex(row, flex-start, center);

  min-height: calc(100vh - 525px);

  .position-left {
    text-align: left;
  }

  .position-right {
    text-align: right;
  }

  .lbp-desktop {
    max-width: 1280px;
    width: 100%;

    .lbp-title-wrap {
      @include custom-flex;

      padding-bottom: 32px;

      .lbp-title-content {
        font-size: 40px;
        color: $text-color-50;
        font-weight: 700;
      }

      .lbp-title-content-mobile {
        font-size: 30px;
      }

      .btn-filter {
        @include custom-flex(row, center, center);

        color: $color-white;
        padding: 8px 16px;
        border-radius: 40px;
        border: 1px solid transparent;
        width: 126px;
        height: 50px;
      }

      .btn-add-lbp {
        @include custom-flex(row, center, center);

        color: $text-color-50;
        border: 1px solid transparent;
        padding: 10px 20px;
        border-radius: 40px;
        margin-left: 16px;
        width: 164px;
        height: 50px;

        .gray-plus-icon {
          svg path {
            fill: $color-text-1;
          }
        }
      }

      .btn-mobile {
        padding: 10px 0;
        width: 50px;

        .icon-size {
          margin-right: 0;
        }
      }
    }

    .lbp-title-wrap-mobile {
      padding-top: 0;
    }

    .icon-size {
      width: 24px;
      height: 24px;
      margin-right: 10px;

      img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
      }

      .badge-filter {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background: $accent-color-600;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        font-size: 16px;
        line-height: 26px;
        font-weight: 700;
        margin-right: 12px;
      }
    }

    .content-button {
      font-size: 16px;
      font-weight: 500;
    }

    .lbp-search-container {
      @include custom-flex(column, center, center);

      border-top: 1px solid $text-color-300;
      padding-top: 32px;
      padding-bottom: 48px;
      width: 100%;

      .lpb-search {
        @include custom-flex(row, flex-end, space-between);

        width: 100%;

        .input-search {
          width: 286px;
        }

        .input-search-mobile {
          width: 100%;
        }

        .select-search {
          flex-grow: 1;
          @include flex-justify-end;

          .type-select {
            min-width: 209px;
            @include laptop {
              margin-left: 32px;
            }
          }
        }

        .select-search-mobile {
          width: 100%;
          @include custom-flex(column, center, center);

          .select-filter-custom,
          .type-select {
            width: 100% !important;
          }

          .type-select {
            margin-top: 20px;
          }

          .type-select-custom {
            margin-right: 0;
          }
        }
      }

      .lpb-search-mobile {
        @include custom-flex(column, center, center);
      }
    }

    .lbp-search-container-mobile {
      padding-bottom: 32px;
    }

    .loading-wrap {
      min-height: 800px !important;
      min-width: 650px;
    }

    .infinitiScroll-wrap {
      @include custom-flex(row, center, center);

      // margin-top: 48px;
      margin-bottom: 30px;
      width: 100%;

      > div {
        width: 100%;
      }

      .token-wrap {
        // @include custom-flex(column, center, center);
        display: flex;
        flex-direction: column;
        width: 100%;

        .accordion-container {
          color: inherit;
          background-color: transparent;
          box-shadow: none;
        }

        .accordion-sumary {
          padding: 0;

          > div {
            margin: 0;
          }
        }
      }

      .token-container {
        width: 100%;
        margin-bottom: 16px;
        padding: 16px;
        background-color: $bg-400;
        border-radius: 12px;
      }

      .infinite-scroll {
        @include custom-flex(column, flex-start, center);

        width: 100%;

        .infinite-scroll-content {
          @include custom-flex(row, center, space-between);

          width: 100%;
          padding-bottom: 18px;

          &:last-child {
            padding-bottom: 0;
          }

          .content-summary {
            @include custom-flex(row, center, center);

            margin-right: 35px;

            .content-summary-img {
              width: 32px;
              height: 32px;
              object-fit: cover;
              border-radius: 100px;
            }

            .content-summary-name {
              text-transform: uppercase;
              font-weight: bold;
              padding-left: 10px;
              font-size: 16px;
              margin-right: 10px;
              color: $color-white;
            }
          }

          .flex-header {
            position: relative;

            .icon-collapse {
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              display: block;
              overflow: visible;
              padding: 1px 8px;
              background: $accent-color-100;

              &::after {
                content: '';
                display: block;
                position: absolute;
                padding: 1px;
                background: inherit;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
              }

              &.collapsed::after {
                padding: 8px 1px;
              }
            }
          }

          .icon-expand {
            width: 30px;
            height: 30px;
          }

          .content-status {
            color: $text-color-100;
            font-size: 14px;
          }

          .status-detail {
            @include custom-flex(row, center, left);

            text-transform: capitalize;
            border: 1px solid $text-color-100;
            padding: 4px 8px;
            border-radius: 16px;

            .status-text {
              padding-left: 10px;
              font-size: 12px;
            }
          }

          .status-token {
            width: 78px;
            height: 28px;
            justify-content: center;
          }
        }
      }

      .lbp-accordion-detail {
        padding: 18px 0 0;

        .content-detail-wrap {
          @include custom-flex(column, center, center);

          width: 100%;

          .content-detail {
            @include custom-flex(row, center, space-between);

            padding-bottom: 16px;
            width: 100%;

            &:last-child {
              padding-bottom: 0;
            }

            span {
              font-size: 14px;

              &:first-child {
                color: $text-color-100;
              }

              &:last-child {
                color: $text-color-50;
              }
            }
          }
        }
      }
    }

    .load-more {
      display: flex;
      justify-content: center;

      .btn-see-more {
        border-radius: 4px;
      }
    }

    .header-table,
    .table-row {
      @include flex-items-center;

      padding-left: 16px;
      padding-right: 16px;

      > div {
        &:first-child {
          width: 18%;
        }

        &:nth-child(2) {
          min-width: 15%;
          flex-grow: 1;
        }

        &:nth-child(3) {
          width: 10%;
          justify-content: right;
        }

        &:nth-child(4) {
          width: 15%;
          justify-content: right;
        }

        &:nth-child(5) {
          width: 15%;
          justify-content: right;
        }

        &:last-child {
          width: 15%;
          justify-content: right;
        }
      }
    }

    .header-table {
      padding-top: 52px;
      color: $text-color-100 !important;
      font-size: 12px;

      > div {
        padding: 16px;
        cursor: pointer;

        img {
          width: 8px;
          margin-left: 15px;
        }
      }
    }

    .token-table-container {
      // min-height: 900px;
      min-width: 650px;
      background-color: $bg-3;
      border-radius: 16px;
      padding-bottom: 36px;

      a:nth-child(even) {
        .table-row {
          background-color: $bg-300;
        }
      }

      a:hover {
        .table-row {
          background-color: $bg-5;
        }
      }

      .table-row {
        min-height: 64px;

        &:hover {
          cursor: pointer;
        }

        > div {
          padding: 16px;
          color: $text-color-50;
          font-size: 14px;

          &:first-child {
            span {
              text-transform: uppercase;
              font-size: 16px;
            }
          }

          &:nth-child(2) {
            span {
              color: $color-text-neutrals-6;
              font-size: 16px;
            }
          }

          &:nth-child(4) {
            span {
              padding-left: 10px;
            }
          }
        }

        .table-cell {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 100%;
          display: flex;
          align-items: center;

          .token-name {
            color: $color-text-neutrals-6;
            font-weight: 700;
          }

          .img-cell {
            width: 32px;
            height: 32px;
            margin-right: 10px;
            object-fit: cover;
            border-radius: 100px;
          }

          .tooltip-warning {
            margin-left: 10px;

            path {
              fill: $color-accent-2;
            }
          }
        }

        .cell-price {
          @include custom-flex(row, center, right);

          height: 100%;
        }

        .cell-status {
          border: 1px solid $text-color-100;
          border-radius: 16px;
          padding: 4px 12px 4px 7px;
          height: auto;
        }
      }
    }
  }
}

.filter-contain {
  width: 100%;
  align-items: stretch;
  @include mix-flex-column;

  .filter-header {
    margin-bottom: 10px;
    justify-content: space-between;
    @include flex-items-center;

    .label-filter {
      color: $color-text-2;
      @include body-2-semibold;
    }

    .end-label-filter {
      color: $color-brand-3;
      @include body-3-semibold;
    }
  }
}

.badge-status {
  color: $color-text-1;
  @include body-3-regular;
  @include mix-flex-centered;

  padding: 0 10px;
  height: 28px;
  border-radius: 28px;
  border: 1px solid $color-text-4;

  .status {
    padding-left: 0 !important;
    text-transform: capitalize;
  }

  .pending,
  .active,
  .inactive {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 6px;
  }

  .pending {
    background: $color-accent-2;
  }

  .active {
    background: $color-accent-5;
  }

  .inactive {
    background: $color-text-3;
  }
}
