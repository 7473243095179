@import 'src/styles/base/typography';
@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';

.tab-bar {
  width: 100%;
  overflow: auto;
  padding-bottom: 24px;
  border-bottom: 1px solid $color-text-4;
  flex-wrap: wrap;
  @include flex-items-center;

  .tab-asset {
    flex-grow: 1;
    padding: 8px 0;
    @include flex-items-center;
    @include mobile-down {
      margin-bottom: 12px;
    }

    .btn-tab {
      border: none;
      outline: none;
      background: transparent;
      padding: 0 16px;
      height: 35px;
      border-radius: 35px;
      margin-right: 16px;
      color: $color-text-2;
      white-space: nowrap;
      @include body-3-bold;
      @include mix-flex-centered;

      &.active {
        color: $color-brand-2;
        background: $bg-8;
      }
    }
  }
}

.content-tab {
  width: 100%;
  overflow: hidden;
}

.table-scroll {
  width: 100%;
}

.table-asset-token {
  width: 100%;
  overflow: auto;

  .col-asset,
  .col-symbol,
  .col-network {
    text-align: left;
  }

  .col-value {
    text-align: right;
  }

  .col-nofound {
    padding: 32px 0;
  }

  thead th {
    + th {
      padding-left: 16px;
    }

    height: 64px;
    color: $color-text-2;
    @include mix-border-divider($position: bottom, $color: $bg-6);
    @include body-4-semibold;
  }

  tbody td {
    + td {
      padding-left: 16px;
    }

    height: 79px;
    color: $color-text-1;
    @include mix-border-divider($position: bottom, $color: $bg-6);
    @include body-3-semibold;

    img {
      @include mix-adornment-start(32px, 32px);
    }

    .cell-value {
      align-items: flex-end;
      @include mix-flex-column;

      .value-token {
        color: inherit;
      }

      .value-currency {
        color: $color-text-2;
        @include body-4-semibold;
      }
    }
  }
}

.tab-nft {
  padding-top: 42px;
}

.grid-my-nft {
  align-items: stretch;
  @include mix-grid;

  .card-nft {
    border-radius: 22px;
    background: $bg-3;
    padding: 16px;
    @include mix-flex-column;

    .card-header {
      flex-grow: 1;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid rgb(240 243 246 / 10%);

      .thumb-card {
        width: 100%;
        max-width: 100%;
        margin-bottom: 16px;
        object-fit: cover;
      }

      .title-card {
        color: $color-brand-4;
        @include h5-bold;
      }
    }

    .card-body {
      width: 100%;
    }
  }
}

.nft-nofound {
  width: 30%;
  max-width: 100%;
  margin: 0 auto;
}

.field-text {
  width: 100%;
  @include flex-items-center;

  + .field-text {
    margin-top: 8px;
  }

  .aside {
    padding: 0 6px;

    .label {
      color: $color-text-2;
      @include body-4-medium;
    }

    &.grow {
      flex-grow: 1;
      overflow: hidden;

      .value-ellipsis {
        width: 100%;
        line-height: 0;
        align-items: center;
        @include flex-justify-end;

        .value {
          color: $color-text-1;
          display: inline-block;
          max-width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          @include body-4-semibold;
        }
      }
    }
  }
}
