@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.network-warning, .alert-warning {
  background: rgb(240 116 22 / 25%);
  border-radius: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: $accent-color-200;
  padding: 10px 16px;
  margin-bottom: 32px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    font-size: 12px;
    line-height: 20px;
    padding: 16px;
    margin-bottom: 10px;

    > svg {
      display: none;
    }
  }

  .text {
    display: flex;
    align-items: center;
    max-width: 80%;
    @media only screen and (max-width: 768px) {
      margin-bottom: 16px;
      align-items: flex-start;
      max-width: 100%;
    }

    .icon-warning {
      display: none;
      @media only screen and (max-width: 768px) {
        display: block;

        svg {
          width: 16px;
          margin-right: 8px;
        }
      }
    }

    .icon-bubble {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}

.network-warning-block {
  @include mix-flex-column;
  @include mix-flex-centered;

  .icon-outer {
    width: 40px;
    height: 40px;
    object-fit: contain;
    margin-bottom: 8px;
  }

  .text {
    text-align: center;
    margin-bottom: 16px;
    color: $color-accent-2;
    @include body-3-medium;

    .icon-warning,
    .icon-bubble {
      display: none;
    }
  }
}

.alert-warning {
  flex-direction: row;
  align-items: center;

  .text {
    margin-bottom: 0;
  }

  svg {
    display: block !important;
  }

  .btn-close {
    cursor: pointer;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  @include mobile {
    svg {
      width: 20px !important;
      height: 20px !important;
      margin-right: 8px;
    }
  }
}
