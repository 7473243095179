@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.container {
  width: 100%;
  flex-wrap: wrap;
  @include flex-items-center;

  .legends {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .list {
      display: flex;
      margin: 0;
      padding: 0;
      flex-flow: row wrap;
      width: 100%;
      width: fit-content;

      .item {
        align-items: center;
        display: flex;
        flex-direction: row;
        margin-left: 10px;
        padding: 16px 0;

        &:first-child {
          .color-box {
            background: rgba(181 62 250 / 100%);
          }
        }

        &:last-child {
          .color-box {
            background: rgba(255 152 229 / 100%);
          }
        }

        .color-box {
          height: 20px;
          margin-left: 12px;
          min-width: 30px;
          border-radius: 8px;
        }

        .text {
          margin: 0;
          margin-left: 12px;
          padding: 0;
          font-size: 14px;
          line-height: 23px;
          font-style: normal;
          color: $color-white;
        }
      }
    }
  }

  .chart {
    position: relative;
    width: 100%;
    margin-bottom: 32px;
    @include laptop {
      max-width: 70%;
      margin-bottom: 0;
      padding-right: 40px;
    }
  }

  .information {
    width: 100%;
    @include laptop {
      max-width: 30%;
      flex-direction: column;
      @include mix-border-divider($color: $bg-6);
      @include mix-flex-centered;
    }

    .text {
      + .text {
        margin-top: 12px;
      }

      @include laptop {
        text-align: center;
      }

      .title {
        color: $color-text-2;
        @include body-4-regular;
      }

      .value {
        color: $color-text-1;
        @include body-4-bold;
      }
    }
  }
}

.container-detail {
  width: 100%;
  height: 100%;
  @include mix-flex-column;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .legends {
    align-items: center;
    @include laptop {
      @include flex-justify-end;
    }

    ul {
      margin-bottom: 0;
    }

    .list {
      list-style: none;
      padding: 7px 0;
      @include flex-items-center;

      .item {
        @include flex-items-center;

        + .item {
          margin-left: 8px;
          @include laptop {
            margin-left: 20px;
          }
        }

        .color-box {
          width: 14px;
          height: 14px;
          border-radius: 4px;
          background: $bg-accent-1;
          display: block;

          &-predicted {
            background: $bg-accent-3;
          }
          @include laptop {
            width: 30px;
            height: 20px;
            border-radius: 8px;
          }
        }

        .text {
          padding: 0 8px;
          color: $color-white;
          @include body-3-medium;
          @include flex-items-center;

          @include laptop {
            padding: 0 12px;
          }
        }
      }
    }
  }

  .chart {
    position: relative;
    flex-grow: 1;
    @include mix-flex-column;
    @include flex-justify-center;
  }
}

.tabs-chart {
  display: flex;
  align-items: center;
  @include mobile {
    margin-bottom: 16px;
  }

  .tabs-item {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: $text-color-100;
    margin-right: 16px;
    padding: 0 12px;
    cursor: pointer;
  }

  .active {
    font-weight: 700;
    background: #2c3561;
    border-radius: 100px;
    padding: 6px 12px;
    color: $brand-color-200;
  }
}
