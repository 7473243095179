@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';
@import 'src/styles/abstracts/extends';

.lbp-detail-chart {
  height: 100%;
  @include mix-card-rounded;
  @extend %view-block-rounded;

  .history-swap-chart {
    height: 450px;
    @include mobile {
      height: 320px;
    }
  }
}