@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';

.container {
  background-color: $bg-600;
  padding: 0 80px;
  @include mobile {
    padding: 0 16px;
  }
}
