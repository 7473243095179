@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.overview {
  max-width: 1280px;
  margin: 0 auto;


  .navigation {
    @include custom-flex(row, center, flex-start);
    @include body-2-bold;

    color: $color-white;
    width: 100%;
    background-color: $bg-3;
    border-radius: 12px 12px 4px 4px;
    padding: 9px 12px;
    @include mobile {
      border-radius: 0;
      padding: 3px 5px;
      margin-bottom: 10px;
    }

    a {
      color: $color-white;
    }
  }

  &__top {
    padding: 32px;
    background-color: $bg-400;
    border-radius: 4px 4px 16px 16px;
    margin-top: 4px;
    @include mobile {
      padding: 0;
      background-color: transparent;
    }

    .logo {
      width: 36px;
      height: 36px;
      border-radius: 100px;
      object-fit: cover;
      @include desktop {
        width: 48px;
        height: 48px;
      }
    }
  }

  &__container {
    border-radius: 4px;
    @include custom-flex(row, flex-start, center);
    @include mobile {
      @include custom-flex(column, center, center);
    }
  }


  &__content {
    flex: 2;
    @include mobile {
      width: 100%;
    }

    &--header {
      @include custom-flex(row, center, flex-start);

      margin-bottom: 24px;
      @include mobile {
        margin-bottom: 10px;
      }

      h3 {
        @include h3-bold;

        margin-left: 16px;
        color: $color-white;
        @include mobile {
          @include h4-bold;
        }
      }
    }

    &--tool {
      @include custom-flex;
    }

    &--socials a:not(:last-child) {
      margin-right: 24px;
    }
  }
}

.whitelist-info {
  background-color: rgb(47 50 65 / 50%);
  padding: 16px;
  border-radius: 16px 16px 4px 4px;
  margin-top: 24px;
  @include mobile {
    border-radius: 4px;
    margin-top: 10px;
  }

  p {
    margin: 0;
  }

  &__header {
    @include custom-flex;
  }

  &__price {
    @include custom-flex(row, center, flex-start);

    h4 {
      @include h4-bold;

      margin-left: 14px;
      color: $color-white;
    }
  }

  .info {
    @include custom-flex(row, center, center);

    svg {
      margin-right: 8px;
    }
  }

  &__button {
    @include mobile {
      background-color: $bg-400;
      z-index: 1000;
      position: fixed;
      left: 0;
      padding: 10px 16px;
      width: 100%;
      bottom: 0;
      box-shadow: 0 64px 64px -48px rgb(15 15 15 / 10%);

      button {
        width: 100%;
      }
    }
  }

  &__countdown {
    @include custom-flex(row, center, flex-start);
    @include mobile {
      @include custom-flex(column, flex-start, center);

      border-bottom: 1px solid $color-text-4;
      padding-bottom: 16px;
    }

    .phase-notice {
      margin-right: 8px;
      @include mobile {
        margin-right: 0;
      }
    }

    .countdown-text {
      @include body-1-bold;

      margin: 0 8px;
      @include mobile {
        &:first-child {
          margin-left: 0;
        }
        @include body-2-bold;
      }
    }
  }

  &__info {
    @include custom-flex(row, center, center);

    width: 100%;
    background-color: rgb(47 50 65 / 50%);
    padding: 0;
    margin: 4px 0 0;
    @include mobile {
      margin: 0;
      @include custom-flex(column, center, center);
    }

    .list-item {
      width: 100%;
      background-color: transparent;
      border-radius: 4px;
    }
  }
}

.no-auth {
  justify-content: flex-end !important;
}

.user {
  @include custom-flex;

  padding: 16px;
  background-color: rgb(47 50 65 / 50%);
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 4px;

  @include mobile {
    @include custom-flex(column, flex-start, space-between);

    margin-bottom: 0;
    margin-top: 0;
    padding-top: 0;
  }

  &__info {
    width: 100%;
    @include custom-flex(row, center, flex-start);
    @include mobile {
      margin-bottom: 16px;
      padding-top: 20px;
      border-top: 1px solid $text-color-300;
    }

    h6 {
      color: $color-white;
    }

    &--rank {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }

  &__action {
    @include custom-flex(row, center, flex-end);
    @include mobile {
      @include custom-flex(row, center, flex-start);

      padding-bottom: 20px;
      border-bottom: 1px solid $text-color-300;
      width: 100%;
    }

    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      color: $color-white;
    }
  }

  &__action button:first-child {
    margin-right: 8px;
  }

  .collapse {
    width: 100%;
  }

  &__collapse-header {
    @include custom-flex;

    width: 100%;
    position: relative;
    @include mobile {
      background-color: rgb(47 50 65 / 50%);
      margin-top: 10px;
      padding: 16px;
    }

    &--left {
      @include custom-flex(row, center, flex-start);

      span {
        margin-right: 8px;
        display: block;
        width: 22px;
        height: 22px;
        background-color: $color-accent-5;
        border: 5px solid $color-white;
        border-radius: 100%;
      }

      p {
        @include display-2-bold;

        color: $color-white;
        margin: 0;
        text-transform: capitalize;
      }
    }

    &--icon-collapse {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      display: block;
      overflow: visible;
      padding: 1px 8px;
      background: $accent-color-100;

      &::after {
        content: '';
        display: block;
        position: absolute;
        padding: 1px;
        background: inherit;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }

    &--collapsed::after {
      padding: 8px 1px;
    }
  }

  .timeline-mobile {
    margin-top: 4px;
  }

  .timeline-desktop {
    margin-top: 4px;

    > div {
      border-radius: 16px;
    }
  }

  .timeline-element {
    padding: 0;
    background-color: transparent;
    border-radius: 4px 4px 16px 16px;
  }
}

.separator {
  width: 100%;
  border-top: 1px solid $text-color-300;
}

.phase-info {
  &__item {
    background-color: transparent;
    width: 50%;
    padding-left: 34px;
    padding-right: 0;

    @include mobile {
      width: 100%;
      padding: 0;
    }

    &:nth-child(odd) {
      padding-right: 34px;
      padding-left: 0;
      border-right: 1px solid $color-text-4;
      @include mobile {
        padding: 0;
        border-right: none;
      }
    }
  }

  &__timer {
    display: flex;
    background-color: rgb(47 50 65 / 50%);
    padding: 16px 32px;
    border-radius: 4px 4px 16px 16px;
    margin-top: 4px;
    @include mobile {
      flex-direction: column;
      padding: 0;
      background: transparent;
    }

    .text--warning {
      color: rgb(240 116 22 / 100%);
      font-weight: 500;
      font-size: 14px;
      padding-left: 36px;
    }

    .list-item {
      @include mobile {
        padding: 16px;
        margin-bottom: 10px;
        background-color: rgb(47 50 65 / 50%);
        border-radius: 4px;
      }
    }

    li {
      padding-top: 0;
      padding-bottom: 0;
      @include mobile {
        padding: 16px;
        margin-bottom: 10px;
        background-color: rgb(47 50 65 / 50%);
        border-radius: 4px;
      }
    }

    &--item {
      width: 100%;


      & > div {
        @include custom-flex(row, center, space-between);

        margin-top: 16px;
        @include mobile {
          @include custom-flex(column, flex-start, center);
          @include body-4-bold;
        }

        div:first-child {
          @include mobile {
            margin-bottom: 16px;
          }
        }

        div {
          @include mobile {
            width: 100%;
            @include custom-flex(row, center, space-between);

          }

          span {
            color: $text-color-100;
            @include mobile {
              @include body-4-regular;
            }
          }
        }
      }

      .time {
        margin-left: 16px;
        color: #8d909a;
        @include mobile {
          margin-left: 0;
          @include body-4-bold;
        }
      }

      .highlight {
        color: $color-white;
      }
    }
  }


}

.phase-info-border {
  border-radius: 16px 16px 4px 4px;
}

.phase-timer {
  @include custom-flex(column, flex-start, space-between);

  height: auto;
}

.button-tabs {
  margin: 0;
  padding: 8px;
  border: 2px solid $text-color-400;
  border-radius: 30px;

  @include custom-flex(row, center, space-between);
  @include mobile {
    margin-bottom: 10px;
  }

  li {
    cursor: pointer;
    list-style: none;
    @include body-3-medium;

    padding: 6px 12px;
    text-align: center;
  }

  li:not(:last-child) {
    margin-right: 14px;
    @include mobile {
      margin-right: 0;
    }
  }

  .active {
    padding: 6px 12px;
    color: $brand-color-200;
    background-color: $bg-50;
    border-radius: 30px;
  }
}


.hide-desktop {
  @include desktop {
    display: none;
  }
}

.hide-mobile {
  @include mobile {
    display: none;
  }
}
