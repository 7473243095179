@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.item {
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  margin-bottom: 10px;
  color: $color-gray-2;
  @include body-2-bold;

  svg {
    margin-left: 13px;
  }
}

.list-slippage {
  @include flex-justify-between;

  .btn-select-slippage {
    background-color: $bg-3;
    border: 1px solid $color-text-4;
    width: 85px;
    height: 40px;
    color: $color-white;
    @include body-2-bold;

    &.active {
      border: 0;
    }

    @include mobile {
      @include body-4-bold;

      width: 50px;
      height: 30px;
    }
  }

  .input-slippage {
    color: $color-white;
    @include body-2-bold;
    @include flex-items-center;

    &.active {
      input {
        background: $hover-brand-0;
        border: 0;
      }
    }

    input {
      outline: none;
      background: transparent;
      border: 1px solid $color-text-4;
      color: $color-white;
      height: 40px;
      border-radius: 100px;
      padding: 0 15px;
      width: 85px;
      margin-right: 8px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      appearance: textfield;
    }

    @include mobile {
      @include body-4-bold;

      input {
        height: 30px;
        width: 60px;
        @include body-4-bold;
      }
    }
  }
}
