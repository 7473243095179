@import 'src/styles/abstracts/variables';

.no-result {
  display: flex;
  align-items: center;
  justify-content: center;

  .bg-img {
    position: relative;

    .bg {
      width: auto;
      max-width: 100%;
      height: auto;
    }

    .context-result {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2.5% 20%;

      .icon-anim {
        width: auto;
        height: auto;
        max-width: 100%;
      }

      .text-view {
        text-align: center;

        .title {
          display: block;
          color: $text-color-50;
          font-size: 22px;
          font-weight: 700;
          line-height: 30px;
          margin-bottom: 10px;
          @media (min-width: 992px) {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 16px;
          }
        }

        .desc {
          display: block;
          color: $text-color-200;
          font-size: 12px;
          line-height: 20px;
          font-weight: 400;
          @media (min-width: 992px) {
            font-size: 14px;
            line-height: 23px;
          }
        }
      }
    }
  }
}