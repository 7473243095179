@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/abstracts/extends';
@import 'src/styles/base/typography';
@import '../BaseLbp';

.field {
  .label-text {
    color: $color-text-2;
    margin-bottom: 8px;
    white-space: nowrap;
    @include caption;
  }

  .value-text {
    color: $color-neutrals-8;
    @include body-2-medium;

    .icon {
      @include mix-adornment-end($width: 26px);

      border-radius: 100px;
    }

    .icon-fail {
      color: $color-accent-6;
    }

    .icon-done {
      color: $color-accent-5;
    }
  }

  .value-link {
    align-items: center;
    color: $color-brand-2;
    max-width: 100%;
    overflow: hidden;
    @include flex-justify-end;
    @include body-2-medium;

    .link {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $color-brand-2;
      @include body-2-medium;

      max-width: 450px;
      @include mobile {
        max-width: 300px;
      }
    }

    p {
      color: $color-neutrals-8;
    }

    .icon {
      @include mix-adornment-end($width: 26px);

      border-radius: 100px;
    }

    .icon-done {
      color: $color-accent-5;
    }

    .icon-fail {
      color: $color-accent-6;
    }
  }
}

.collapse-header {
  align-items: center;
  @include flex-justify-between;

  .collapse-title {
    color: $color-text-3;
    @include body-1-medium;
  }

  .btn-collap {
    @extend %view-btn-collap;
    @include laptop {
      display: none;
    }
  }
}

.collapse-body {
  padding-top: 8px;
}

.grid-collapse {
  > .grid-item {
    + .grid-item {
      @include mix-border-divider($position: top, $color: $bg-6);

      padding-top: 8px;
    }

    .collapse {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

.card-body {
  > .grid {
    padding-bottom: 16px;
    padding-top: 8px;

    + .grid:not(.grid-collapse) {
      @include mix-border-divider($position: top, $color: $bg-6);
      @include laptop {
        padding-top: 16px;
      }
    }

    @include laptop {
      padding-top: 8px;
      padding-bottom: 16px;
    }
  }

  > .grid-collapse {
    @include mix-border-divider($position: top, $color: $bg-6);

    padding-top: 0;
    padding-bottom: 0;
    @include laptop {
      padding-bottom: 0;
      padding-top: 8px;
    }
  }
}

.wrap-footer {
  padding-top: 8px;
}

.check-term {
  margin-bottom: 26px;
  padding-top: 16px;
  cursor: pointer;
  @include flex-justify-start;
  @include laptop {
    margin-bottom: 48px;
    padding-top: 0;
  }

  .text {
    margin-left: 12px;
    color: $color-text-2;
    @include body-3-medium;
  }

  .checkbox {
    position: relative;
    box-sizing: border-box;
    display: block;
    width: 24px;
    height: 24px;

    input[type='checkbox'] {
      display: none;

      &:checked + .as-input {
        border: none;
        background: $bg-brand-0;

        &::after {
          content: '✓';
          font-family: system-ui;
          font-size: 20px;
          font-weight: 600;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          color: $color-white;
          @include mix-flex-centered;
        }
      }
    }

    .as-input {
      width: inherit;
      height: inherit;
      border-radius: 4px;
      border: 2px solid $color-brand-4;
      position: relative;
      @include mix-flex-centered;
    }
  }
}
