@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.box-nft {
  margin: 32px 0;

  .nfts {
    @include flex-justify-center;

    .next-milestone {
      color: $color-text-3;
      padding: 10px;
      text-align: center;
      @include body-4-medium;

      .value-convert {
        color: $color-text-1;
        @include body-4-medium;
      }
    }

    img {
      width: 110px;
      object-fit: contain;
    }

    .text {
      text-align: center;
      color: $color-text-1;
      margin-top: 10px;
      @include body-4-medium;
    }
  }

  .descriptions {
    @include flex-justify-between;

    .text {
      text-align: center;
      color: $color-text-1;
      margin-top: 10px;
      @include body-4-medium;
    }
  }
}

.list-info {
  border-radius: 12px;
  overflow: hidden;

  .item {
    width: 100%;
    overflow: hidden;
    background: $bg-4;
    padding: 15px 16px;

    + .item {
      margin-top: 4px;
    }
    @include flex-items-center;

    .label {
      @include flex-items-center;

      .adornment {
        margin-right: 8px;
        @include mix-icon-square;
      }

      .name {
        color: $color-text-2;
        @include body-4-medium;
      }
    }

    .value {
      flex-grow: 1;
      text-align: right;
      color: $color-text-1;
      @include body-3-bold;
    }
  }
}
