@import 'src/styles/abstracts/variables';

.header {
  display: flex;
  align-items: center;
  color: $color-white;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  background: #13162c;
  border-bottom: 1px solid rgb(255 255 255 / 10%);

  svg {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
}

.cell {
  width: 200px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $text-color-50;

  .icon-head {
    max-width: 25px;
    width: auto;
    height: auto;
    object-fit: contain;
  }

  .text-head {
    color: $text-color-100;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    margin-right: 7px;
  }

  .text-body {
    color: $text-color-50;
    font-size: 14px;
    line-height: 23px;
    font-weight: 500;
  }
}

.staking-table {
  tr {
    border-bottom: 1px solid rgb(255 255 255 / 10%);

    td {
      text-align: right;
      font-size: 14px;
      color: $text-color-50;
      padding: 13.5px 25px;

      &:first-child {
        text-align: left;
      }

      .icon-head {
        max-width: 25px;
        width: auto;
        height: auto;
        object-fit: contain;
        margin-left: 10px;
      }

      .text-head {
        color: $color-white;
        font-size: 14px;
        font-weight: bold;
        margin-right: 7px;
      }
    }
  }
}

.staking-table-profile {
  margin: 30px 0;
  width: 100%;
  border: none;
  margin-top: 10px;

  .header {
    background: inherit;
    font-size: 12px;
    border-bottom: 1px solid $bg-100;
    padding: 0 32px;

    .cell {
      color: $text-color-100;
      justify-content: flex-end;
    }
  }

  .cell {
    width: 20%;
    height: 60px;
  }

  .row {
    border-bottom: 1px solid $bg-100 !important;

    .cell {
       justify-content: flex-end;

       &:first-child {
         justify-content: flex-start;
         padding-left: 0;
       }
     }

    &:last-child {
      border-bottom: 1px solid $bg-100;
    }
  }

  .body {
    padding: 0 32px;
  }
}

.staking-table-mobile {
  padding: 16px 3px;
}

.card-staking {
  background: $bg-400;
  box-shadow: 0 4px 4px rgb(0 0 0 / 16%);
  border-radius: 12px;
  padding: 16px;

  &:not(:first-child) {
    margin-top: 16px;
  }

  .card-staking-header {
    padding-bottom: 16px;

    .flex-header  {
      position: relative;
      display: flex;
      align-items: center;

      .icon {
        display: block;
        margin-right: 8px;
        width: 100%;
        height: auto;
        max-width: 34px;
        object-fit: contain;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        color: white;
        font-size: 16px;
        font-weight: 700;
        margin: 0;
      }

      .icon-collapse {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        display: block;
        overflow: visible;
        padding: 1px 8px;
        background: $accent-color-100;

        &::after {
          content: '';
          display: block;
          position: absolute;
          padding: 1px;
          background: inherit;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: all .25s cubic-bezier(0.19, 1, 0.22, 1);
        }

        &.collapsed::after {
          padding: 8px 1px;
        }
      }
    }
  }

  .card-staking-subheader, .card-staking-body .card-staking-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      color: $text-color-100;
      font-size: 14px;
      font-weight: 400;
    }

    .text {
      color: $text-color-50;
      font-size: 14px;
      font-weight: 700;
      margin: 0;
    }
  }

  .card-staking-body {
    .card-staking-item {
      margin-top: 16px;
    }
  }
}
