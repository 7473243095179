@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.main {
  margin-top: 30px;
  align-items: center;
  @include mix-flex-column;

  .team-item {
    width: 100%;
    flex-direction: column;
    @include mix-flex-centered;

    .avatar {
      margin-bottom: 32px;

      img {
        width: 100%;
        border-radius: 32px;
        object-fit: cover;
        mix-blend-mode: luminosity;
        aspect-ratio: 1;
      }
    }

    .name {
      margin-bottom: 16px;
      color: $color-white;
      @include h4-bold;
    }

    .position {
      color: $text-color-200;
      margin-bottom: 8px;
      @include body-1-regular;
    }

    .description {
      text-align: center;
      color: $text-color-100;
      @include body-2-medium;
    }
  }
}
