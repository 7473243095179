@import 'src/styles/abstracts/variables';

.project-info {
  margin: 0 80px;
  display: flex;

  @media (max-width: 992px) {
    margin: 0 0 80px;
  }

  .menu-info {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $text-color-100;
    padding-left: 15px;
    border-left: 2px solid $text-color-300;
    margin-right: 75px;
    height: max-content;
    width: 140px;

    @media (max-width: 992px) {
      display: none;
    }

    > a {
      display: block;
      margin-bottom: 8px;
      position: relative;
      color: $text-color-100;
      width: max-content;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .active {
      color: $brand-color-200;

      svg {
        path {
          fill: $brand-color-200;
        }
      }

      &::before {
        content: '';
        width: 8px;
        height: 8px;
        border: 3px solid $brand-color-200;
        border-radius: 100px;
        position: absolute;
        top: 10px;
        left: -20px;
      }
    }
  }

  .menu-fixed {
    position: fixed;
    top: 120px;
  }

  .content-info {
    width: calc(100% - 215px);

    @media (max-width: 992px) {
      width: 100%;
    }

    .show-less {
      height: 400px;
      overflow: hidden;
      transition: height 0.2s;
      position: relative;

      &::before {
        content: '';
        width: 100%;
        height: 200px;
        position: absolute;
        bottom: 0;
        background: linear-gradient(
          to bottom,
          rgb(6 10 33 / 0%) 0%,
          rgb(6 10 33 / 100%) 100%
        );
        z-index: 2;

        @media (max-width: 768px) {
          height: 400px;
        }
      }
    }

    .show-more {
      height: max-content;
      transition: height 0.2s;
    }

    .item-info {
      margin-bottom: 68px;
      scroll-margin-top: 8rem;

      @media (max-width: 768px) {
        margin-bottom: 52px;
      }

      &:last-child {
        margin-bottom: 47px;
      }

      .title {
        font-weight: 700;
        font-size: 30px;
        line-height: 38px;
        color: $text-color-50;
        margin-bottom: 32px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 30px;
          margin-bottom: 22px;
        }

        .navigation {
          background-color: #242731;
          border-radius: 100px;
          height: 34px;
          width: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 4px;

          svg path {
            fill: $color-white;
          }

          &:hover {
            background-color: $bg-300;
          }
        }

        .navigation-next {
          svg {
            transform: rotate(180deg);
          }
        }
      }

      .content {
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        color: $text-color-200;

        p,
        div,
        span,
        h1,
        h2,
        h3,
        li {
          font-size: 14px;
          line-height: 23px;
        }
      }

      .backers {
        margin: 0 -8px;

        .item {
          padding: 0 8px;
          margin-bottom: 16px;

          .logo {
            background: $bg-400;
            border-radius: 16px;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg,
            img {
              max-width: 80%;
              max-height: 80%;
              width: auto;
            }

            @media (max-width: 768px) {
              height: 120px;
            }
          }
        }
      }
    }
  }

  .content-info-fixed {
    margin-left: 215px;

    @media (max-width: 992px) {
      margin-left: 0;
    }
  }

  .btn-show {
    font-family: R-Flex-Bold, sans-serif;
    width: 100%;
    background: $bg-500;
    border: 1px solid $text-color-100;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px 0;
    line-height: 26px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    color: $text-color-50;
    align-items: center;
    cursor: pointer;
    position: relative;

    &:hover {
      border-color: $color-white;
      color: $color-white;
    }

    @media (max-width: 768px) {
      padding: 5px 0;
    }

    img {
      width: 10px;
      height: 6px;
      margin-right: 15px;
    }
  }

  .roadmap {
    .roadmap-item {
      position: relative;
      display: flex;

      .circle-status {
        width: 14px;
        height: 14px;
        margin-right: 20px;
        background: $text-color-300;
        border-radius: 36px;
        margin-top: 25px;

        &::before {
          content: '';
          position: absolute;
          width: 2px;
          height: 100%;
          background-color: $text-color-300;
          top: 39px;
          left: 6px;
        }
      }

      .active {
        background: $color-green-100;
      }

      .active-line {
        &::before {
          background-color: $accent-color-500;
        }
      }

      &:last-child {
        .card-roadmap {
          margin-bottom: 0;
        }

        .circle-status {
          &::before {
            display: none;
          }
        }
      }
    }

    .card-roadmap {
      width: calc(100% - 24px);
      padding: 16px 32px;
      background: $bg-400;
      border: 1px solid $text-color-300;
      border-radius: 20px;
      margin-bottom: 16px;

      @media (max-width: 768px) {
        padding: 16px;
      }

      .header {
        cursor: pointer;
        position: relative;

        .icon-collapse {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          display: block;
          overflow: visible;
          padding: 1px 8px;
          background: $accent-color-100;

          &::after {
            content: '';
            display: block;
            position: absolute;
            padding: 1px;
            background: inherit;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
          }

          &.collapsed::after {
            padding: 8px 1px;
          }
        }
      }

      .name {
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        color: $text-color-50;
        margin-bottom: 4px;
      }

      .date {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: $text-color-100;
      }

      .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        color: $text-color-200;
        margin-top: 16px;
      }
    }
  }

  .slide-advisors {
    .advisors-item {
      .avatar {
        background: $bg-400;
        border-radius: 22px;
        min-width: 210px;
        width: 100%;
        display: flex;
        padding: 32px;

        .avatar-img {
          width: 100%;
          padding-bottom: 100%;
          position: relative;
        }

        img {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          border-radius: 32px;
          object-fit: cover;
          mix-blend-mode: luminosity;
        }
      }

      .name {
        font-family: R-Flex-Bold, sans-serif;
        font-size: 22px;
        line-height: 30px;
        color: $text-color-100;
        margin-bottom: 12px;
        margin-top: 16px;

        @media (max-width: 768px) {
          font-size: 18px;
          line-height: 26px;
        }
      }

      .line {
        background: $brand-color-300;
        border-radius: 10px;
        width: 38px;
        height: 4px;
        margin-bottom: 12px;
        object-fit: cover;
      }

      .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 23px;
        color: $text-color-200;

        @media (max-width: 768px) {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }

  .slide-team {
    margin-top: 32px;

    .slide-team-item {
      margin: 0 40px 32px 0 !important;

      @media (max-width: 768px) {
        margin-bottom: 30px !important;
      }
    }

    .team-item {
      background: $bg-400;
      border-radius: 22px;
      min-width: 210px;
      width: 100%;
      display: flex;
      padding: 32px 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (max-width: 768px) {
        padding: 16px 0;
      }

      .avatar {
        margin-bottom: 16px;

        img {
          width: 108px;
          height: 108px;
          border-radius: 32px;
          object-fit: cover;
          mix-blend-mode: luminosity;
        }
      }

      .name {
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        color: $text-color-50;
      }

      .position {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: $text-color-100;
      }

      .description {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: $text-color-200;
        max-width: 170px;
        text-align: center;
        margin-top: 8px;
      }
    }
  }

  .read-more {
    color: $brand-color-300;
    cursor: pointer;
  }
}
