@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.modal-coming-soon {
  @include mix-flex-centered;

  .content {
    margin-bottom: 24px;
    transform: translateY(-6.5px);
    color: $color-white;
    @include h4-bold;
  }
}
