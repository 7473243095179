@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.main {
  margin: 30px 0;
  align-items: center;
  @include mix-flex-column;

  .link {
    padding-top: 30px;

    a {
      color: $color-brand-2;
      text-decoration-line: underline;
      font-family: Inter, sans-serif;
      @include body-3-semibold;
    }

    span {
      text-transform: capitalize;
    }
  }
}
