@import 'src/styles/abstracts/variables';

.card {
  background: rgb(47 50 65 / 50%);
  padding: 16px 0;

  .col {
    padding: 0 16px;

    + .col {
      padding-top: 16px;
    }

    &.col--2,
    &.col--3 {
      width: 100%;
    }

    .row {
      + .row {
        padding-top: 16px;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;

        .icon {
          display: block;
          width: 12px;
          min-width: 12px;
          height: 12px;
          background: $accent-color-100;
          border-radius: 4px;
          margin-right: 8px;
        }

        .label {
          display: inline-block;
          color: $text-color-100;
          font-size: 12px;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .value {
          color: #fcfcfd;
          font-size: 12px;
          font-weight: 700;
          line-height: 20px;
        }
      }
    }
  }

  &.card-in-modal {
    border-radius: 12px;
    overflow: hidden;
    background: transparent;
    padding: 0;

    .col {
      padding: 0;

      + .col {
        margin-top: 4px;
      }

      .row {
        background: $bg-300;
        padding: 15px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        + .row {
          margin-top: 4px;
        }

        &.hidden-in-modal {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .card {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0;

    .col {
      padding: 0 32px;
      flex-grow: 1;

      + .col {
        padding-top: 0;
        border-left: 1px solid $text-color-300;
      }

      &.col--2 {
        width: 50%;
      }

      &.col--3 {
        width: calc(100% / 3);
      }

      .row {
        .left, .right {
          max-width: 50%;
        }

        .left {
          .label {
            font-size: 14px;
            line-height: 23px;
          }
        }

        .right {
          .value {
            font-size: 14px;
            line-height: 23px;
          }
        }
      }
    }
  }
}
