@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.header-v2 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 84px;
  overflow: hidden;
  z-index: 99;
  background: transparent
    linear-gradient(180deg, #060a21 0%, rgb(6 10 33 / 0%) 100%);
  transition: all 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53);

  @include tablet {
    height: 100px;
  }

  &.visible-background {
    background: #2c3149
      linear-gradient(180deg, #060a21 0%, rgb(6 10 33 / 0%) 100%);
  }

  &.show-menu {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    background: $bg-400;

    .header-logo {
      background: linear-gradient(180deg, #060a21 0%, rgb(6 10 33 / 0%) 100%);
    }

    .header-right {
      max-height: calc(100vh - 100px);
      overflow: auto;
    }
  }

  .navbar-v2 {
    width: 100vw;
    position: relative;
    height: 100%;

    .navbar-wrapper {
      position: relative;
      height: 100%;
      overflow: hidden;
      @include mix-max-width;

      .btn-toggle-menu {
        position: absolute;
        top: 25px;
        left: 10px;
        width: 35px;
        height: 35px;
        border: none;
        outline: none;
        background: transparent;
        @include mix-flex-centered;

        svg {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      .header-logo {
        width: 100%;
        padding-right: 15px;
        margin-left: 35px;
        @include flex-justify-start;

        .logo {
          display: inline-block;
          height: 30px;
          margin-top: 25px;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .header-right {
        margin-top: 6px;

        .navbar-menu {
          list-style: none;
          display: block;
          width: 100%;
          margin: 0;
          padding: 60px 0;

          .navbar-link {
            display: block;
            width: 100%;

            > a {
              display: flex;
              justify-content: center;
              padding: 20px;
              font-size: 18px;
              font-family: R-Flex-Regular, sans-serif;
              color: $color-text-1;

              &:hover {
                color: $color-white;
              }
            }

            &.active {
              position: relative;

              &::before {
                content: '';
                width: 4px;
                height: 4px;
                background: $bg-brand-2;
                border-radius: 100px;
                position: absolute;
                top: -6px;
                left: 50%;
                transform: translateX(-50%);
              }

              color: $color-brand-2;
            }

            &.active > a {
              color: $color-brand-2;
            }
          }
        }

        .navbar-account {
          position: absolute;
          top: 25px;
          right: 10px;
          display: flex;

          .contain-connect {
            margin-bottom: 20px;
            @include flex-justify-center;

            @include mobile {
              button {
                padding: 0 18px;
              }
            }
          }

          .the-account {
            margin-left: 10px;
            @include laptop {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}

.left-menu {
  background: $bg-2;
  color: $color-white;
  height: 100%;
  padding: 32px 25px 0 17px;
  position: relative;
  margin-bottom: 0;

  .header-sidebar {
    padding-bottom: 22px;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;

    .logo {
      margin-left: 28px;
    }

    border-bottom: 1px solid rgb(240 243 246 / 10%);
  }

  .social-icons {
    justify-content: space-around;
    position: absolute;
    bottom: 25px;
    width: 90%;
    margin: auto;
    @include flex-items-center;

    a {
      color: $color-text-3;

      &:hover {
        color: $color-white;
      }
    }
  }

  .sidebar-link {
    height: 60px;
    margin-left: 10px;
    @include flex-items-center;

    &.active {
      color: $color-brand-2;

      a {
        color: $color-brand-2;
      }
    }

    a {
      color: #f4f5f6;
      font-weight: 500;
      font-size: 14px;
      line-height: 23px;
      text-align: left;
    }
  }
}

@include laptop {
  .header-v2 {
    .navbar-v2 {
      .navbar-wrapper {
        @include flex-items-center;

        .btn-toggle-menu {
          display: none;
        }

        .header-logo {
          width: auto;
          flex-shrink: 1;
          margin-left: 0;

          .logo {
            margin-top: 0;
          }
        }

        .header-right {
          flex-grow: 1;
          display: flex;
          margin-top: 0;
          margin-left: 15px;
          padding-left: 20px;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 2px;
            background: $color-white;
            opacity: 0.2;
            border-radius: 1px;
            height: 30px;
            margin-top: 7px;
          }

          .navbar-menu {
            flex-grow: 1;
            padding: 0;
            @include flex-items-center;

            .navbar-link {
              width: auto;

              > a {
                padding: 0 20px;
                font-size: 18px;
                font-weight: 300;
              }
            }
          }

          .navbar-account {
            display: flex;
            position: relative;
            top: unset;
            right: unset;

            .contain-connect {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
