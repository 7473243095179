@import 'src/styles/abstracts/variables';

.footer {
  background: $bg-500;
  width: 100%;
  border-top: 1px solid rgba($color: #fff, $alpha: 10%);
  padding: 0 17px;

  .footer-top {
    display: flex;
    flex-wrap: wrap;

    .intro-footer {
      width: 100%;
      margin-top: 50px;

      .intro-content {
        display: block;
        width: 100%;
        text-align: center;

        .logo {
          display: inline-block;

          > img {
            width: 200px;
            height: auto;
            display: block;
            max-width: 100%;
            object-fit: contain;
          }
        }

        > p {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 400;
          color: $text-color-200;
          margin-top: 30px;
        }
      }
    }

    .list-link-footer {
      width: 50%;
      margin-top: 35px;
      text-align: center;
      position: relative;
      padding: 0 25px;

      &:last-child::after {
        content: '';
        display: block;
        width: 1px;
        height: 126px;
        background: #F0F3F6;
        position: absolute;
        left: 0;
        top: 40px;
        opacity: .1;
      }

      h4 {
        color: $text-color-50;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        margin-bottom: 30px;
      }

      ul {
        list-style: none;
        display: block;
        margin-bottom: 0;
        padding-left: 0;

        > li {
          display: block;
          width: 100%;
          margin-bottom: 20px;
        }
      }

      .text-link-footer {
        display: inline-block;
        color: $text-color-200;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .footer-bottom {
    margin-top: 30px;

    .list-link-social {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 23px;
      border-bottom: 1px solid $bg-100;

      > ul {
        display: flex;
        align-items: center;
        padding-left: 0;

        > li {
          padding: 0 7.5px;
          display: inline-block;

          > a {
            display: inline-block;

            svg path {
              fill: $color-text-3;
            }
          }
        }
      }
    }

    .copyright {
      padding-top: 20px;
      padding-bottom: 33px;

      > p {
        margin-bottom: 0;
        color: $text-color-200;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
      }
    }
  }
}

@media (min-width: 992px) {
  .footer {
    padding: 0;

    .footer-top {
      flex-wrap: nowrap;
      border-bottom: 1px solid rgb(240 243 246 / 10%);

      .intro-footer {
        width: 50%;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        margin-top: 80px;
        margin-left: 80px;

        .intro-content {
          display: flex;
          align-items: flex-start;
          width: auto;

          .logo {
            margin-right: 72px;
            margin-top: 26px;
          }

          > p {
            max-width: 271px;
            text-align: left;
            margin-top: 0;
          }
        }
      }

      .list-link-footer {
        width: 23%;
        flex-grow: 1;
        display: flex;
        margin-top: 0;
        justify-content: center;
        border-left: 1px solid rgb(240 243 246 / 10%);
        border-right: 1px solid rgb(240 243 246 / 10%);
        padding-top: 80px;
        padding-bottom: 28px;
        text-align: left;

        &:last-child::after {
          content: none;
        }

        &.lg-right {
          width: 33%;
          border: none;
        }

        ul {
          li {
            margin-bottom: 11px;
          }
        }
      }
    }

    .footer-bottom {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      max-width: 1080px;
      margin: 0 auto;

      .list-link-social {
        padding-bottom: 0;
        border-bottom: none;

        a {
          color: $color-text-3;

          &:hover {
            color: $color-white;

            svg {
              fill: currentcolor;
            }
          }
        }
      }

      .copyright {
        display: flex;
        padding-bottom: 20px;
      }
    }
  }
}
