@import 'src/styles/abstracts/variables';

.list-content {
  list-style: none;
  margin: 0;
  display: block;
  width: 100%;
  padding-left: 0;

  .item {
    display: block;
    padding: 16px 0;

    &:not(:last-child) {
      border-bottom: 1px solid $bg-100;
    }

    .label {
      display: flex;
      align-items: center;

      .icon {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 8px;
        background: $brand-color-50;
        border-radius: 4px;
      }

      .text {
        font-size: 14px;
        font-weight: 500;
        color: $text-color-100;
        line-height: 23px;
      }
    }

    .value {
      margin-top: 8px;

      .text {
        color: $text-color-50;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
      }
    }
  }
}

.overview {
  padding: 32px;
  background: $bg-400;
  border-radius: 16px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-family: R-Flex-Bold, sans-serif;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      color: $text-color-50;
    }

    .conversation-rate {
      font-family: Inter, sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: $text-color-100;
    }
  }

  .content {
    margin-top: 20px;

    >div {
      padding: 0 9px;

      &:first-child {
        padding-left: 12px;
      }

      &:last-child {
        padding-right: 12px;
      }
    }

    .info {
      padding: 16px;
      background: $bg-200;
      border-radius: 16px;

      .title {
        display: flex;
        align-items: center;
        font-family: Inter, sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: $text-color-100;

        .dot {
          display: block;
          width: 12px;
          height: 12px;
          background: $brand-color-50;
          border-radius: 4px;
          margin-right: 8px;
        }
      }

      .value {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #FCFCFD;
      }
    }
  }
}