@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/abstracts/extends';
@import 'src/styles/base/typography';

.wrap-item {
  margin-bottom: 10px;
  @include laptop {
    + .wrap-item {
      margin-top: 32px;
    }

    &.wrap {
      flex-wrap: wrap;
    }
    @include mix-flex-centered;
  }
}

.card {
  width: 100%;
  background: $bg-3;
  border-radius: 12px;
  padding: 16px;

  + .card {
    margin-top: 10px;
    @include laptop {
      margin-top: 0;
    }
  }
  @include laptop {
    padding: 0;
    border-radius: 0;
    width: 100%;
  }

  .card-header {
    width: 100%;
    flex-grow: 1;

    @include laptop {
      margin-bottom: 16px;
    }

    .title,
    .text {
      color: $color-neutrals-8;
      @include flex-items-center;
      @include h5-bold;
      @include laptop {
        color: $color-white;
        @include h3-bold;
      }

      .adornment-start {
        @include flex-items-center;

        margin-right: 15px;

        @include tablet {
          margin-right: 27px;
        }

        img,
        svg {
          cursor: pointer;
          width: 6px;
          height: 10px;
          transform: rotate(180deg);

          @include tablet {
            width: 10px;
            height: 16px;
          }
        }
      }
    }

    .sub-title {
      margin-top: 16px;
      color: $color-text-3;
      @include body-3-regular;
      @include laptop {
        margin-top: 8px;
      }
    }
  }

  .card-body {
    &.grow {
      flex-grow: 1;
    }

    .title-body {
      color: $color-brand-4;
      @include body-2-bold;
      @include laptop {
        margin-bottom: 16px;
        @include body-1-bold;
      }
    }

    .sub-title-body {
      margin-bottom: 16px;
      color: $color-text-3;
      @include body-2-medium;
      @include laptop {
        @include body-1-medium;
      }
    }

    .section-content,
    .section-content-none {
      padding-top: 16px;

      + .section-content {
        @include mix-border-divider($position: top, $color: $bg-6);
        @include laptop {
          @include mix-border-divider($position: top, $width: 0);
        }
      }

      &:last-child {
        padding-bottom: 0;
      }

      .row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -8px;

        .col,
        .col-lg-6 {
          padding: 0 8px;
          width: 100%;

          + .col,
          + .col-lg-6 {
            margin-top: 16px;
            @include laptop {
              margin-top: 0;
            }
          }
        }

        .col-lg-6 {
          @include laptop {
            max-width: 50%;
          }
        }

        .col-auto {
          padding: 0 8px;
          margin-top: 16px;
        }
      }
    }

    .section-content {
      padding-bottom: 16px;
    }
  }
}

.wrap-footer {
  width: 100%;
  @include laptop {
    padding-top: 48px;
  }

  .footer-fixed {
    background: $bg-4;
    box-shadow: 0 64px 64px -48px rgb(15 15 15 / 10%);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 16px;
    z-index: 2;
    @media (min-width: 992px) {
      padding: 48px 0 0;
      position: static;
      background: transparent;
      @include mix-border-divider($position: top, $color: $bg-6);
    }

    .row {
      margin-left: -8px;
      margin-right: -8px;
      @include flex-items-center;
      @media (min-width: 992px) {
        justify-content: space-between;
      }

      .group-button-left,
      .group-button-right {
        padding-left: 8px;
        padding-right: 8px;
        flex-grow: 1;
        display: flex;

        .btn {
          min-width: 100px;
          width: 100%;
          max-width: 576px;

          @include laptop {
            width: auto;
          }
        }
      }

      .group-button-right {
        justify-content: flex-end;

        .btn {
          &:disabled {
            background: $bg-brand-0;
            color: $color-white;
            opacity: 0.5;
          }
        }
      }

      .group-button-left {
        justify-content: flex-start;
      }
    }
  }

  &.padding-have-term {
    padding: 35px 0;
  }
}

.field {
  + .field {
    margin-top: 16px;
  }

  &-right {
    align-items: center;
    @include flex-justify-end;
  }

  .label {
    color: $color-text-1;
    margin-bottom: 10px;
    @include body-3-bold;

    .require {
      color: $color-accent-6;
    }
  }

  .sub-label {
    display: inline-block;
    color: $color-text-1;
    margin-top: 4px;
    @include body-3-regular;
  }

  .note {
    display: inline-block;
    color: $color-text-3;
    margin-top: 4px;
    @include body-3-regular;
  }
  @include laptop {
    align-items: center;

    &-lg-right {
      @include flex-justify-end;
    }

    &-lg-left {
      @include flex-justify-start;
    }
  }
}

.group-footer-full {
  width: 100%;
  @include laptop {
    width: auto;
  }
}

.grid {
  @include mix-grid($space: 8px);
}

.message-warning {
  margin-top: 48px;
  @include body-3-regular;

  color: $color-accent-2;
}

.status-approve {
  margin-bottom: 16px;
  @include laptop {
    margin-bottom: 32px;
  }

  color: $color-text-3;
  @include body-1-bold;

  &.process {
    color: $color-text-3;
  }

  &.done {
    color: $color-text-1;

    svg {
      margin-left: 8px;
    }
  }
}
