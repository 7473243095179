@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/_mixins';

.mypool-mobile {
  padding: 0 3px;

  .pool-list {
    margin: 16px 0;
  }

  .data-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 230px);
    color: #777e91;
  }

  .load-more {
    display: flex;
    justify-content: center;
    padding: 16px 0;

    .btn-see-more {
      border-radius: 4px;
    }
  }

  .filter-bar {
    position: relative;
    display: flex;
    align-items: center;

    .select-tab {
      min-width: 154px;
    }

    .field-search {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      // .type-search {
      //   width: calc(100% - 60px);
      // }

      .btn-search {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        background: $bg-100;
        height: 50px;
        width: 50px;
        border-radius: 50px;
        margin-left: 10px;
      }
    }
  }
}

.my-pool {
  background: $bg-400;
  border-radius: 16px;
  margin-bottom: 16px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 32px 0;

    .title {
      font-family: R-Flex-Bold, sans-serif;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      color: $text-color-50;
    }
  }

  .tab-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 32px;
    margin: 32px;
    border-bottom: 1px solid $text-color-300;

    .input-search {
      input {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  .pool-list {
    padding-bottom: 40px;

    .header-pool-list {
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: $text-color-100;
      width: calc(100% - 40px);
      padding: 0 32px;
      margin-bottom: 10px;

      .header-step {
        display: flex;
        position: relative;
        justify-content: space-between;

        > div {
          width: 100px;

          &:first-child {
            margin-left: -10px;
          }

          &:last-child {
            margin-right: -35px;
          }
        }

        .header-token-sale {
          margin-left: 40px;
        }

        @media only screen and (max-width: 1400px) {
          justify-content: inherit;

          .header-token-sale {
            margin-left: 90px;
          }

          > div:last-child {
            position: absolute;
            right: 5px;
          }
        }
      }

      .header-invest {
        text-align: right;
        padding-right: 46px;
      }
    }

    .pool-item {
      padding: 0 32px;

      &:nth-child(odd) {
        background: $bg-300;
      }

      .my-pool-item {
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        color: $text-color-50;
        box-shadow: none;

        > div {
          padding: 0;
        }

        .content-pool {
          width: 100%;
          display: flex;
          align-items: center;

          .pool-name {
            img {
              width: 32px;
              height: 32px;
              margin-right: 8px;
            }

            a {
              color: $text-color-50;
              display: flex;
              align-items: center;

              .title {
                cursor: pointer;
                white-space: nowrap;
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }

          .action {
            display: flex;
            justify-content: flex-end;
            width: 15%;
          }

          .time-step {
            display: flex;
            margin-left: -50px;

            .content-step {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              line-height: 20px;
              min-width: 120px;

              &:first-child {
                margin-left: -15px;
              }

              &:nth-child(2) {
                margin: 0 95px 0 100px;
              }

              @media only screen and (max-width: 1400px) {
                &:nth-child(2) {
                  margin: 0 65px 0 55px;
                }
              }
            }
          }
        }

        .pool-step {
          display: flex;
          padding: 0 20px;

          .step {
            position: relative;
            width: 40px;

            &:nth-child(2),
            &:last-child {
              width: calc(100% - 64px);
            }

            &:first-child {
              &::after {
                display: none;
              }
            }

            &::before {
              content: '';
              display: inline-block;
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              width: 32px;
              height: 32px;
              border-radius: 16px;
              border: 2px solid $text-color-300;
              background: transparent;
              z-index: 3;
            }

            &::after {
              content: '';
              height: 2px;
              width: calc(100% - 32px);
              background: $text-color-300;
              position: absolute;
              left: 0;
              z-index: 1;
            }

            &.done {
              &::before {
                display: flex;
                align-items: center;
                justify-content: center;
                content: '';
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
                  Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
                  'Helvetica Neue', sans-serif;
                font-size: 20px;
                font-weight: 700;
                color: $color-white;
                border: none;
                background: $accent-color-500;
                background-size: contain;
                background-image: url('../../assets/icons/CheckIcon.svg');
              }

              &::after {
                background-color: $accent-color-500;
              }
            }

            &.happening {
              &::before {
                border: 5px solid $color-white;
                background: $accent-color-500;
              }

              &::after {
                background-color: $accent-color-500;
              }
            }
          }
        }

        .value-invest {
          text-align: right;
          text-transform: uppercase;
          padding-right: 30px;
        }
      }

      &.ino {
        .content-pool .time-step {
          margin-left: -15px;
          padding: 0;
          @include flex-justify-between;

          .content-step:nth-child(2) {
            margin: 0;
          }
        }
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    margin-right: 32px;
    padding-bottom: 32px;

    nav {
      ul {
        li {
          button {
            background: $bg-300;
            border: 1px solid $bg-100;
            border-radius: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 23px;
          }
        }
      }
    }

    button {
      color: #fcfcfd !important;
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    color: $color-white;
  }

  .no-result {
    max-width: 40%;
    margin: 0 auto;
    margin-top: 40px;
  }

  &.ino {
    .pool-list {
      .header-pool-list {
        .header-step > div:first-child {
          margin-left: 10px;
        }

        .header-invest {
          padding-right: 20px;
        }
      }
    }
  }
}

.tabs-project {
  display: flex;
  align-items: center;

  .tabs-item {
    font-weight: 500;
    font-size: 14px;
    line-height: 23px;
    color: $text-color-100;
    margin-right: 16px;
    padding: 0 12px;
    cursor: pointer;
  }

  .active {
    font-weight: 700;
    background: #2c3561;
    border-radius: 100px;
    padding: 6px 12px;
    color: $brand-color-200;
  }
}

.card-mypool {
  background: $bg-400;
  box-shadow: 0 4px 4px rgb(0 0 0 / 16%);
  border-radius: 12px;
  padding: 16px;

  &:not(:first-child) {
    margin-top: 16px;
  }

  .card-mypool-header {
    padding-bottom: 20px;

    .flex-header {
      position: relative;
      display: flex;
      align-items: center;
      padding-right: 50px;

      .link-pool {
        display: flex;
        align-items: center;
        width: 100%;
      }

      .icon {
        display: block;
        margin-right: 8px;
        width: 34px;

        svg,
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .title {
        color: $color-white;
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        cursor: pointer;
        white-space: nowrap;
        width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icon-collapse {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        display: block;
        overflow: visible;
        padding: 1px 8px;
        background: $accent-color-100;

        &::after {
          content: '';
          display: block;
          position: absolute;
          padding: 1px;
          background: inherit;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
        }

        &.collapsed::after {
          padding: 8px 1px;
        }
      }
    }
  }

  .card-mypool-subheader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      color: $text-color-100;
      font-size: 14px;
      font-weight: 400;
    }

    .text {
      color: $text-color-50;
      font-size: 14px;
      font-weight: 700;
      margin: 0;
      text-transform: uppercase;
    }
  }

  .card-mypool-body {
    position: relative;
    padding-left: 36px;

    .card-mypool-item {
      padding-top: 20px;
      overflow: visible;

      &:not(:first-child) {
        margin-top: 20px;
        border-top: 1px solid $bg-100;

        .flex-title-item {
          .after-status {
            &::before {
              content: '';
              position: absolute;
              width: 2px;
              background: $text-color-300;
              left: 7px;
              top: -112px;
              height: 111px;
              z-index: 1;
            }

            &.happening,
            &.done {
              &::before {
                background: $accent-color-500;
              }
            }

            &.done {
              &::before {
                top: -116px;
              }
            }

            &.happening {
              &::before {
                left: 4px;
                top: -118px;
                height: 113px;
              }
            }
          }
        }
      }

      .flex-title-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        position: relative;

        .after-status {
          display: inline-block;
          position: absolute;
          top: calc(50% - 10px);
          left: -36px;
          padding: 5px;
          border-radius: 50%;
          border: 2px solid $text-color-300;
          z-index: 2;
          width: 20px;
          height: 20px;

          &.happening {
            border: 5px solid $color-white;
            background: $accent-color-500;
            z-index: 2;
          }

          &.done {
            background: $color-green-100;
            border-color: $color-green-100;
            z-index: 2;

            &::after {
              content: '';
              display: inline-block;
              position: absolute;
              top: calc(50% - 5px);
              left: calc(50% - 2.5px);
              padding: 4px 2px;
              border-bottom: 1px solid $color-white;
              border-right: 1px solid $color-white;
              transform: rotate(45deg);
            }
          }
        }
      }

      .label {
        color: $text-color-100;
        font-size: 14px;
        font-weight: 400;
      }

      .detail {
        .row {
          display: flex;
          align-items: center;

          &:not(:first-child) {
            margin-top: 8px;
          }

          .label-icon {
            width: 22px;
            height: 22px;
            display: block;
            margin-right: 9px;

            svg {
              width: 100%;
              height: 100%;
              object-fit: contain;
              vertical-align: baseline;
            }
          }

          .text {
            color: $text-color-50;
            font-size: 14px;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.btn-claim {
  width: 123px !important;
}
