@import 'src/styles/abstracts/variables';

.tabs-info {
  padding: 16px 32px 36px;
  background: $bg-400;
  border-radius: 16px;
  margin: 0 80px 48px;

  @media (max-width: 992px) {
    margin: 0 0 48px;
  }

  @media (max-width: 768px) {
    padding: 5px 16px 16px;
    margin-bottom: 32px;
  }

  .tab-nav {
    border-bottom: 1px solid $text-color-300;

    .tab-list {
      display: flex;
      margin-bottom: 0;
      padding-left: 0;

      .tab-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px;
        margin-right: 15px;
        color: $text-color-100;
        font-size: 14px;
        font-weight: 500;
        border-bottom: 3px solid transparent;
        transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
        cursor: pointer;

        &.tab-active {
          color: $brand-color-200;
          border-color: $brand-color-200;
        }
      }
    }
  }

  .tabs-content {
    margin-top: 16px;

    .metrics {
      margin-left: -12px;
      margin-right: -12px;

      > div:first-child {
        padding-right: 2px;

        @media (max-width: 768px) {
          padding-right: 12px;

          .metric-item {
            &:first-child {
              border-radius: 12px 12px 0 0;
            }
          }
        }
      }

      > div:last-child {
        padding-left: 2px;

        @media (max-width: 768px) {
          padding-left: 12px;

          .metric-item {
            &:last-child {
              border-radius: 0 0 12px 12px;
            }
          }
        }
      }

      .metric-item {
        background: $bg-300;
        border-radius: 4px;
        padding: 21px 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4px;

        @media (max-width: 768px) {
          padding: 16px;
        }

        .label {
          display: flex;
          align-items: center;

          .dot {
            width: 12px;
            min-width: 12px;
            height: 12px;
            background: $brand-color-50;
            border-radius: 4px;
            margin-right: 8px;
          }

          .text {
            font-size: 14px;
            line-height: 23px;
            color: $text-color-100;

            @media (max-width: 768px) {
              font-size: 12px;
              line-height: 20px;
            }
          }
        }

        .value {
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          color: $text-color-50;
          text-align: right;

          @media (max-width: 768px) {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }

    .metrics-tokenomics {
      > div:nth-child(2) {
        padding-left: 2px;

        @media (max-width: 768px) {
          padding-left: 12px;
        }
      }

      > div:last-child {
        padding-left: 12px;
      }
    }

    .tokenomics {
      margin-top: 40px;

      > div {
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }

        @media (max-width: 992px) {
          &:first-child {
            margin-bottom: 0;
          }
        }
      }

      .title {
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        color: $text-color-50;
        margin-bottom: 16px;
        position: relative;
        margin-left: 15px;

        &::before {
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 100px;
          background-color: $text-color-50;
          position: absolute;
          top: 10px;
          left: -15px;
        }
      }

      .table-sales-round {
        .header {
          display: flex;
          margin: 0 0 16px;

          .cell {
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            text-align: right;
            color: $text-color-100;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            &:last-child {
              padding-right: 32px;
            }
          }
        }

        .body {
          .row {
            display: flex;
            margin: 0;

            .cell {
              font-weight: 500;
              font-size: 14px;
              line-height: 23px;
              color: $text-color-50;
              text-align: right;
              padding: 16px 12px;
              display: flex;
              align-items: center;
              justify-content: flex-end;

              &:first-child {
                text-align: left;
                padding-left: 32px;
                justify-content: flex-start;
              }

              &:last-child {
                padding-right: 32px;
              }
            }

            &:nth-child(odd) {
              background: $bg-300;
            }
          }
        }
      }
    }
  }

  .card-staking {
    background: $bg-200;
    box-shadow: 0 4px 4px rgb(0 0 0 / 16%);
    border-radius: 12px;
    padding: 16px;

    &:not(:first-child) {
      margin-top: 16px;
    }

    .card-staking-header {
      position: relative;
      display: flex;
      align-items: center;

      .title-header {
        color: white;
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        width: 90%;
      }

      .icon-collapse {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        display: block;
        overflow: visible;
        padding: 1px 8px;
        background: $accent-color-100;

        &::after {
          content: '';
          display: block;
          position: absolute;
          padding: 1px;
          background: inherit;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
        }

        &.collapsed::after {
          padding: 8px 1px;
        }
      }
    }

    .card-staking-body .card-staking-item {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      .label {
        color: $text-color-100;
        font-size: 14px;
        font-weight: 400;
      }

      .text {
        color: $text-color-50;
        font-size: 14px;
        font-weight: 700;
        margin: 0;
        width: 70%;
        text-align: right;
      }
    }
  }
}
