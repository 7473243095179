@import 'src/styles/abstracts/variables';
@import 'src/styles/base/typography';

.wallet-address {
  line-height: 28px;
  display: flex;
  align-items: center;

  &__network-name {
    margin: 0 8px 0 0;
  }

  &__text {
    @include caption;

    color: $text-color-50;
    margin: 0 8px 0 0;
  }

  &__icon {
    cursor: pointer;
  }
}

.bold {
  font-weight: 700;
}
