@import 'src/styles/abstracts/variables';

.list-countdown {
  display: flex;
  align-items: center;
  padding-left: 0;

  .item {
    background: linear-gradient(137.32deg, rgb(0 120 255 / 0%) 41.53%, #0078FF 100%);
    padding: 1px;
    border-radius: 12px;
    list-style-type: none;
  }

  .part {
    display: flex;
    padding: 0 4px;
    color: $color-white;
    background: rgb(47 50 65 / 50%);
    backdrop-filter: blur(64px);
    border-radius: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 80px;
    min-height: 80px;
    font-weight: 700;
    font-size: 22px;

    @media only screen and (max-width: 600px) {
      min-width: 58px;
      min-height: 58px;
      font-size: 18px;
    }
  }

  .colon {
    display: inline-block;
    list-style-type: none;
    padding: 0 10px;
    color: $color-white;

    @media only screen and (max-width: 600px) {
      padding: 0 5px;
    }
  }

  .info {
    color: $text-color-100;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    @media only screen and (max-width: 600px) {
      font-size: 12px;
    }
  }
}

.countdown-pool {
  .part {
    min-width: 58px;
    min-height: 58px;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;

    @media only screen and (max-width: 600px) {
      min-width: 58px;
      min-height: 58px;
      font-size: 18px;
    }
  }

  .info {
    font-size: 12px;
    font-weight: 400;
  }

  .colon {
    padding: 0 5px;
  }
}