@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.pool-detail {
  @include mix-max-width;

  margin-bottom: 130px;

  @media (max-width: 768px) {
    margin-bottom: 80px;
  }

  .message-alert {
    cursor: pointer;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 768px) {
      white-space: inherit;
      -webkit-line-clamp: 2;
      max-height: 40px;
      display: box;
      -webkit-box-orient: vertical;
      word-break: break-word;
    }
  }

  .time-line {
    z-index: 999;
    margin: -60px 80px 16px;
    @media (max-width: 992px) {
      margin: 0 0 16px;
    }
  }

  .banner-pool {
    position: relative;
    margin-bottom: 16px;

    @media (max-width: 768px) {
      margin-bottom: 3px;
    }

    .banner-img {
      img {
        width: 100%;
        height: 420px;
        object-fit: cover;
        border-radius: 16px;
        @media (max-width: 768px) {
          border-radius: 16px 16px 4px 4px;
        }
      }
    }

    .banner-content {
      padding: 80px;
      display: flex;
      align-items: flex-end;
      width: 100%;
      justify-content: space-between;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(
                      180deg,
                      rgb(0 0 0 / 0%) 0%,
                      rgb(0 0 0 / 70%) 100%
      );
      border-radius: 16px;

      @media (max-width: 768px) {
        flex-direction: column;
        padding: 16px;
        justify-content: flex-end;
        align-items: flex-start;
        border-radius: 16px 16px 4px 4px;
      }

      .box-info {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }

      .name {
        font-family: R-Flex-Bold, sans-serif;
        font-size: 40px;
        line-height: 48px;
        color: $color-white;
        max-width: 100%;
        margin-bottom: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        max-height: 96px;
        display: box;
        -webkit-box-orient: vertical;
        word-break: break-word;
        cursor: pointer;

        @media (max-width: 992px) {
          font-size: 30px;
          line-height: 38px;
          max-height: 76px;
        }
      }

      .title-countdown {
        font-weight: 700;
        font-size: 12px;
        line-height: 20px;
        color: $color-white;
        margin-bottom: 11px;
      }

      .countdown-comp {
        margin-bottom: 18px;
      }

      .info {
        @include body-4-medium;

        color: $color-text-1;
        display: flex;
        align-items: center;
        margin-top: 18px;

        svg {
          margin-right: 8px;
        }
      }

      .btn-apply {
        margin-top: 18px;
      }

      .info-social {
        display: flex;

        a {
          display: block;
          margin-left: 20px;

          svg path {
            fill: $text-color-50;
          }

          &:hover {
            filter: brightness(0) invert(1);
          }
        }

        @media (max-width: 768px) {
          display: none;
        }
      }

      .social-mobile {
        display: none;

        @media (max-width: 768px) {
          display: block;
          position: absolute;
          top: 16px;
          right: 16px;
        }
      }
    }
  }
}

.contain {
  border-radius: 12px;
  overflow: hidden;
  backdrop-filter: blur(64px);
  @media (max-width: 768px) {
    border-radius: 4px 4px 12px 12px;
  }

  .item-card {
    border-radius: 4px;
    overflow: hidden;

    + .item-card {
      margin-top: 4px;
    }

    .card-header {
      padding: 0 16px;
      background: rgb(47 50 65 / 50%);

      .header-collapse {
        padding: 16px 0 20px;
        border-bottom: 1px solid $bg-100;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;

          .check-radio {
            width: 22px;
            height: 22px;
            background: $color-green-100;
            border: 5px solid $color-white;
            border-radius: 11px;
            margin-right: 8px;
          }

          .title {
            color: #fcfcfd;
            font-size: 16px;
            line-height: 26px;
            font-weight: 700;
          }
        }

        .right {
          position: relative;

          .toggle-collapse {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            display: block;
            overflow: visible;
            padding: 1px 8px;
            background: $accent-color-100;

            &::after {
              content: '';
              display: block;
              position: absolute;
              padding: 1px;
              background: inherit;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
            }

            &.collapsed::after {
              padding: 8px 1px;
            }
          }
        }
      }
    }
  }
}
