@import 'src/styles/abstracts/variables';
@import 'src/styles/base/typography';
@import 'src/styles/abstracts/mixins';

.note {
  margin: 32px 0;
  text-align: center;
  color: $color-accent-2;
  @include body-3-medium;
}

.box-input {
  position: static;

  .label-input-max {
    color: $color-text-1;
    margin-bottom: 10px;
    @include body-3-bold;
  }
}

.list-info {
  border-radius: 12px;
  overflow: hidden;

  .item {
    width: 100%;
    overflow: hidden;
    background: $bg-4;
    padding: 15px 16px;

    + .item {
      margin-top: 4px;
    }
    @include flex-items-center;

    .label {
      @include flex-items-center;

      .adornment {
        margin-right: 8px;
        @include mix-icon-square;
      }

      .name {
        color: $color-text-2;
        @include body-4-medium;
      }
    }

    .value {
      flex-grow: 1;
      text-align: right;
      color: $color-text-1;
      @include body-3-bold;
    }
  }
}
