@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.container-wallet {
  width: 100%;

  .section {
    margin-bottom: 32px;
    width: 100%;
    padding: 1px;
    overflow: hidden;

    .title {
      margin-bottom: 4px;
      color: $color-text-1;
      @include body-3-bold;

      @include tablet {
        @include body-2-bold;
      }
    }

    .grid {
      align-items: stretch;
      @include mix-grid(12px);
    }

    .choose-item {
      background: $bg-3;
      border: 1px solid $color-text-4;
      border-radius: 12px;
      height: 100%;
      width: 100%;
      cursor: pointer;
      padding: 10px;
      @include mix-flex-centered;

      &.selected,
      &:hover {
        border: 1px solid $color-brand-3;
        box-shadow: 0 0 0 1px $color-brand-3;
      }

      @include tablet {
        flex-direction: column;
      }

      .img-thumb {
        width: 28px;
        height: 28px;
        margin-right: 6px;
        object-fit: contain;
        @include tablet {
          width: 42px;
          height: 42px;
          margin-right: 0;
          margin-bottom: 12px;
        }
      }

      .name {
        margin-bottom: 0;
        text-align: center;
        color: $color-text-1;
        @include body-4-semibold;
        @include tablet {
          @include body-3-semibold;
        }
      }
    }
  }
}
