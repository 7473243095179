@import 'src/styles/abstracts/variables';

.pool-list {
  padding: 32px;
  background: $bg-400;
  border-radius: 16px;
  margin-top: 16px;

  .header {
    font-family: R-Flex-Bold, sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: $text-color-50;
    margin-bottom: 50px;
  }

  .content {
    .table {
      width: 100%;

      .cell {
        text-align: right;

        &:first-child {
          text-align: left;
        }
      }

      .thead {
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: $text-color-100;
        margin: 0 20px;
      }

      .pool-item {
        padding: 22px 20px;
        margin: 16px 0 0;
        cursor: pointer;

        .cell {
          font-weight: 500;
          font-size: 14px;
          line-height: 23px;
          color: #F4F5F6;

          &:first-child {
            >div {
              display: flex;
              align-items: center;
            }
          }

          .price {
            font-weight: 500;
            font-size: 14px;
            line-height: 23px;
            color: $text-color-200;
          }
        }

        &:nth-child(odd) {
          background: $bg-300;
          border-radius: 16px;
        }
      }

      .active {
        border: 2px solid $brand-color-300;
        border-radius: 16px;
      }
    }
  }
}

.pool-list-mobile {
  position: relative;

  .pool-list-contain {
    padding: 16px 0;
  }
}

.pool-item {
  margin-bottom: 16px;

  .pool-contain {
    background: $bg-400;
    border-radius: 12px;
    padding: 0 16px;
    box-shadow: 0 4px 4px rgb(0 0 0 / 16%);

    .pool-header {
      border-bottom: 1px solid $bg-100;
      padding: 10px 0;

      .pool-title {
        position: relative;
        padding-right: 24px;

        .pool-intro {
          display: flex;
          align-items: center;

          .pool-icon {
            display: inline-block;
            margin-right: 4px;

            img, svg {
              width: 100%;
              max-width: 32px;
              vertical-align: middle;
              object-fit: contain;
            }
          }

          .pool-name {
            color: $color-white;
            font-size: 16px;
            font-weight: 700;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            text-transform: uppercase;
            margin: 0 4px;
          }
        }

        .arrow-detail {
          position: absolute;
          top: 50%;
          right: 9px;
          transform: translateY(-50%);
          border: none;
          outline: none;
          background: transparent;
          color: $text-color-100;
        }
      }
    }

    .pool-body {
      padding: 16px 0;

      .pool-row {
        &:not(:first-child) {
          padding-top: 16px;
        }

        display: flex;
        margin-left: -8px;
        margin-right: -8px;

        .pool-col {
          padding: 0 8px;
          width: 100%;

          .pool-label {
            color: $text-color-100;
            font-size: 14px;
            font-weight: 400;
            margin: 0;
          }

          .pool-value {
            color: $text-color-50;
            font-size: 14px;
            font-weight: 700;
            text-align: right;
          }

          .price {
            font-weight: 500;
            font-size: 14px;
            line-height: 23px;
            text-align: right;
            color: $text-color-200;
          }

          .pool-value-currency {
            color: $text-color-200;
            font-size: 14px;
            font-weight: 500;
            text-align: right;
          }
        }
      }
    }
  }
}