@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.section-slick-landing {
  position: relative;
  min-height: 576px;
  aspect-ratio: 12 / 5;
  max-width: 100%;
  @media (min-width: 1080px) {
    width: 100%;
    height: auto;
    max-width: none;
  }
}

.slick-as {
  .slick-list {
    .slick-track {
      .slick-slide {
        .slick-as-item {
          height: 576px;
          aspect-ratio: 12 / 5;
          overflow: hidden;
          position: relative;

          &::before {
            display: none;
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 80%;
            background: linear-gradient(
                            to right,
                            #0b0e20 0%,
                            rgb(6 10 33 / 0%) 100%
            );
            z-index: 1;
          }

          @media (min-width: 769px) {
            &::before {
              width: 50%;
              display: block;
            }
          }
          @media (min-width: 1080px) {
            width: 100%;
            height: auto;
          }

          .slick-video-item,
          .slick-img-item {
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
            object-position: top center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}

.slick-content-manage {
  @include mix-flex-centered;

  position: absolute;
  left: 0;
  bottom: 10%;
  right: 0;
  z-index: 2;
  padding-left: 15px;
  padding-right: 15px;

  .slick-container {
    width: 100%;
    max-width: 100%;
    position: relative;
    z-index: 2;

    @media (min-width: 769px) {
      max-width: 1280px;
    }

    .slick-body-pool {
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: left;

      .slick-pool-name {
        color: $color-white;
        @include h4_bold;

        margin-bottom: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        max-height: 76px;
        display: box;
        -webkit-box-orient: vertical;
        word-break: break-word;
        cursor: pointer;

        @media (min-width: 769px) {
          @include h2_bold;

          margin-bottom: 15px;
          max-width: 600px;
          max-height: 120px;
        }
      }

      .slick-pool-desc {
        color: $color-white;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: box;
        -webkit-box-orient: vertical;

        @include body-4-medium;

        p, h1, h2, h3, h4, h5, h6, li {
          @include body-4-medium;

          margin: 0;
        }

        max-width: calc(100vw - 40px);
        @media (min-width: 769px) {
          @include body-2-medium;

          p, h1, h2, h3, h4, h5, h6, li, div {
            @include body-2-medium;

            margin: 0;
          }
        }
      }

      .slick-pool-divider {
        background-color: rgb(240 243 246 / 20%);
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .slick-pool-countdown-label {
        margin-bottom: 11px;
        @include body-4-medium;

        color: $color-brand-4;
        @include flex-items-center;

        @media (min-width: 769px) {
          @include body-3-regular;
        }

        .slick-clock-icon {
          margin: 0 10px 0 0;
        }
      }

      .slick-group-btn {
        @include flex-items-center;

        margin: 16px -8px;

        @media (min-width: 769px) {
          margin: 40px -8px;
        }

        .s-g-b-col {
          padding: 0 8px;

          .slick-volume-video-icon {
            align-self: flex-end;
            @include mix-flex-centered;

            width: 42px;
            min-width: 42px;
            height: 42px;
            border-radius: 42px;
            border: 2px solid rgb(240 243 246 / 10%);
            cursor: pointer;
            @media (min-width: 769px) {
              margin-left: 16px;
            }
          }
        }
      }
    }
  }
}

.slick-for {
  max-width: calc(108px * var(--count-slick-item));

  .slick-list {
    padding: 40px 0;

    .slick-track {
      .slick-slide {
        .slick-for-item {
          position: relative;
          aspect-ratio: 12 / 7;
          height: auto;
          background: transparent;
          border-radius: 14px;
          border: 6px solid transparent;
          box-sizing: border-box;
          cursor: pointer;
          transition: all 0.15s linear;

          .slick-video-item,
          .slick-img-item {
            min-width: 100%;
            min-height: 100%;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            border-radius: 8px;
          }
        }

        &.slick-active.slick-center,
        &.slick-slide.slick-center {
          .slick-for-item {
            border-color: $brand-color-300;
          }
        }

        @media (min-width: 769px) {
          &.slick-active.slick-current {
            .slick-for-item {
              border-color: $brand-color-300;
            }
          }
        }
      }
    }
  }

  .slick-arrow {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background: $brand-color-300;
    overflow: hidden;
    z-index: 2;

    &::before {
      content: url('../../assets/icons/ArrowIcon.svg');
      @include mix-flex-centered;

      position: absolute;
      top: 0;
      left: 8px;
      bottom: 0;
      color: $color-white;
      font-size: 20px;
      font-weight: 600;
      transform: rotate(90deg);
    }

    &.slick-prev {
      left: -8px;

      &::before {
        margin-left: 2px;
      }
    }

    &.slick-next {
      right: -8px;

      &::before {
        right: 15px;
        margin-right: 2px;
        transform: rotate(270deg);
      }
    }
  }
}

.slick-for-manager {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  @include mix-flex-centered;

  z-index: 3;

  @media (min-width: 769px) {
    bottom: 10%;
    width: 100vw;
  }

  .slick-for-body {
    width: 100%;
    position: relative;
    max-width: 100%;

    @media (min-width: 769px) {
      max-width: 1280px;
      width: 100vw;
    }

    .slick-for {
      position: absolute;
      top: 100%;
      right: 0;
      width: calc(100vw - 60px);

      // left: 30px;
      // transform: translateY(-50%);
      left: 50%;
      transform: translate(-50%, -50%);

      @media (min-width: 769px) {
        left: auto;
        top: auto;
        bottom: 0;
        right: 20px;
        width: 456px;
        transform: translate(0, 0);
      }
    }
  }
}
