@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/abstracts/extends';
@import 'src/styles/base/typography';
@import '../BaseLbp';

.adornment-end {
  .text {
    color: $color-text-1;
  }
}

.error {
  color: $color-accent-6;
  @include body-4-regular;
}

.row-switch {
  @include laptop {
    @include flex-items-center;
  }

  .switch-control {
    + .switch-control {
      @include mix-border-divider($position: top, $color: $bg-6);
      @include laptop {
        border-top: none;
        margin-left: 32px;
      }
    }

    @include flex-items-center;

    justify-content: space-between;
    @media (min-width: 992px) {
      justify-content: flex-start;
    }

    .label {
      color: $color-text-1;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 0;
      display: block;
    }

    .root {
      width: calc(24px + 52px);
      height: calc(24px + 28px);
    }

    .track {
      width: 52px;
      height: 28px;
      border-radius: 52px;
      background: $bg-8;
    }

    .switchBase {
      padding: 16px;
    }

    .checked {
      transform: translateX(24px);
      color: $color-white;

      &:hover {
        background-color: transparent;
      }

      + .track {
        background: $bg-brand-0;
        opacity: 1;
      }
    }
  }
}

.owner-rights {
  color: $color-text-1;
  @include body-3-bold;

  margin-bottom: 3px;
}

.owner-rights-item {
  margin-top: 10px;

  svg {
    margin-left: 10px;
  }
}