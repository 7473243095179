@import 'src/styles/abstracts/variables';

.card-staking {
  background: $bg-400;
  box-shadow: 0 4px 4px rgb(0 0 0 / 16%);
  border-radius: 12px;
  padding: 16px;

  &:not(:first-child) {
    margin-top: 16px;
  }

  .text-note {
    font-size: 14px;
    font-weight: 400;
    color: $text-color-100;
    line-height: 23px;
    margin-bottom: 0;

    .read-more {
      color: #0065D0
    }
  }

  .card-staking-header {
    padding-bottom: 16px;

    .flex-header  {
      position: relative;
      display: flex;
      align-items: center;

      .icon-position {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: $bg-50;
        color: $brand-color-200;
        font-size: 16px;
        font-weight: 700;
        line-height: 26px;
      }

      .title {
        color: $color-white;
        font-size: 16px;
        font-weight: 700;
        margin: 0;
      }

      .icon-collapse {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        display: block;
        overflow: visible;
        padding: 1px 8px;
        background: $accent-color-100;

        &::after {
          content: '';
          display: block;
          position: absolute;
          padding: 1px;
          background: inherit;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: all .25s cubic-bezier(0.19, 1, 0.22, 1);
        }

        &.collapsed::after {
          padding: 8px 1px;
        }
      }
    }
  }

  .card-staking-subheader, .card-staking-body .card-staking-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      color: $text-color-100;
      font-size: 14px;
      font-weight: 400;
    }

    .text {
      color: $text-color-50;
      font-size: 14px;
      font-weight: 700;
      margin: 0;
    }
  }

  .card-staking-body {
    .card-staking-item {
      margin-top: 16px;
    }
  }
}