@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.purchase-info {
  @include custom-flex;

  flex-wrap: wrap;
  padding: 16px 32px;
  margin-top: 16px;
  background-color: rgb(47 50 65 / 50%);
  border-radius: 16px;
  @include mobile {
    @include custom-flex(column, flex-start, center);

    padding: 16px;
    margin-top: 10px;
    border-radius: 4px 4px 12px 12px;
  }

  &__item {
    background-color: transparent;
    width: 50%;
    padding-left: 34px;
    padding-right: 0;
    @include mobile {
      width: 100%;
      padding: 0;
    }

    &:nth-child(odd) {
      padding-right: 34px;
      padding-left: 0;
      border-right: 1px solid $color-text-4;
      @include mobile {
        padding: 0;
        border-right: none;
      }
    }
  }
}

.purchase-info-border {
  border-radius: 16px 16px 4px 4px;
}