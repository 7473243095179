@import 'src/styles/abstracts/variables';

img {
  width: 100%;
  height: auto;
}

.walletconnect-modal__headerLogo {
  width: auto;
}

.team-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background: rgb(240 243 246 / 10%);
    border-radius: 100px;
    cursor: pointer;
    margin: 0 16px;

    &-active {
      width: 16px;
      height: 16px;
      background: $color-white;
      border: 4px solid $brand-color-300;
    }
  }
}

