@import 'src/styles/base/typography';
@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';

.list-item {
  padding: 0;
  margin-bottom: 32px;

  li:first-child {
    border-radius: 12px 12px 0 0;
  }

  li:last-child {
    border-radius: 0 0 12px 12px;
  }

  li:not(:last-child) {
    margin-bottom: 4px;
  }
}

.input-container:first-of-type {
  margin-bottom: 32px;
}

.label {
  @include body-3-bold;

  color: $text-color-50;
  margin-bottom: 10px;

  &.balance {
    @include flex-justify-between;
  }
}

.error {
  color: $color-accent-6;
  margin-top: 5px;
}

.inputAdornedEnd {
  p {
    @include body-2-regular;

    color: $text-color-50;
  }

  .brand {
    height: 32px;
    background: $bg-6;
    @include mix-flex-centered;

    .logo-brand {
      @include mix-adornment-start(22px, 22px);

      margin-right: 5px;
      @include mix-flex-centered;

      img,
      svg {
        width: 100%;
        max-width: 100%;
        border-radius: 22px;
        object-fit: contain;
      }
    }
  
    .symbol {
      display: inline-block !important;
      color: $color-text-1;
      text-transform: uppercase;
      margin: 0 5px;
      @include body-3-medium;
    }
  }
}

.modal-list-item {
  @include custom-flex;

  height: 50px;
  background-color: $bg-300;
  list-style: none;
  padding: 15px 18px;

  p {
    padding: 0;
    margin: 0;
  }

  &__left {
    @include custom-flex;
  }

  &__key {
    color: $text-color-100;
    font-size: 12px;
    font-weight: 500;
  }

  &__value {
    color: $text-color-50;
    font-size: 14px;
    font-weight: 700;
  }

  .dot {
    display: inline-block;
    background: $brand-color-50;
    width: 12px;
    height: 12px;
    border-radius: 4px;
    margin-right: 8px;

    &__secondary {
      background: $accent-color-100;
    }
  }
}
