@import 'src/styles/abstracts/variables';

.pool-card-row {
  display: flex;
  padding: 8px;
  height: 220px;
  background: $bg-300;
  box-shadow: 0 64px 64px -48px rgb(15 15 15 / 10%);
  border-radius: 26px;
  margin-bottom: 16px;

  &:hover {
    background-color: $bg-100;
  }

  .thumb {
    position: relative;
    height: 100%;

    .thumb-img {
      height: 100%;

      img {
        height: 100%;
        width: 290px;
        object-fit: cover;
        border-radius: 26px;
      }
    }

    .box-logo-network {
      padding: 1px;
      width: 55px;
      height: 55px;
      background: linear-gradient(
          137.32deg,
          rgb(130 71 229 / 0%) 41.53%,
          #8247e5 100%
      );
      border-radius: 20px;
      position: absolute;
      left: 10px;
      top: 10px;

      .logo-network {
        width: 100%;
        height: 100%;
        background: rgb(47 50 65 / 50%);
        backdrop-filter: blur(64px);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;

        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .info {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    .badge {
      display: flex;
      text-transform: uppercase;
      width: 100%;
      justify-content: space-between;

      >div {
        display: flex;

        >div:first-child {
          margin-right: 5px;
        }
      }

      .info-social {
        display: flex;
        margin-right: 30px;

        a {
          display: block;
          margin-left: 21px;
          cursor: pointer;

          &:hover {
            filter: brightness(0) invert(1);
          }
        }
      }
    }

    .name {
      margin: 8px 0 22px;
      display: flex;
      align-items: center;

      .title {
        font-family: R-Flex-Bold, sans-serif;
        font-size: 30px;
        line-height: 38px;
        color: $color-white;
        margin-right: 20px;
        cursor: pointer;
        white-space: nowrap;
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .token {
        display: flex;
        font-family: R-Flex-Bold, sans-serif;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        color: $text-color-50;
        padding-left: 20px;
        border-left: 1px solid rgb(240 243 246 / 10%);

        img {
          width: 20px;
          height: 20px;
          border-radius: 100px;
          margin-right: 8px;
        }
      }
    }

    .info-detail {
      >div {
        display: flex;
      }

      >div:first-child {
        margin-bottom: 11px;
      }

      .info-item {
        margin-right: 40px;
        position: relative;
        margin-left: 20px;
        display: flex;
        align-items: center;

        &::before {
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 100px;
          position: absolute;
          background: #0049C6;
          left: -15px;
          top: 8px;
        }

        .label {
          font-weight: 500;
          font-size: 12px;
          color: $text-color-100;
          margin-right: 8px;
        }

        .value {
          font-weight: 600;
          font-size: 14px;
          line-height: 23px;
          text-align: right;
          color: $text-color-50;
        }
      }

      .countdown {
        &::before {
          background: #7FBA7A;
        }
      }
    }
  }
}

.price {
  font-size: 12px;
  margin-left: 5px;
  font-weight: 400;
}

.pool-card-column {
  // max-width: 407px;
  min-width: 390px;
  transition: all 0.4s ease;

  &:hover {
    transform: translateY(-10px);

    .content {
      background-color: $bg-100;
    }
  }

  @media only screen and (max-width: 850px) {
    min-width: 375px;
  }

  @media only screen and (max-width: 768px) {
    min-width: 345px;
  }

  .content {
    padding: 16px;
    background-color: $bg-300;
    border-radius: 30px;
  }

  .detail {
    padding: 0 16px 16px;
    @media only screen and (max-width: 768px) {
      padding: 0 0 16px;
    }
  }

  .thumb {
    padding-bottom: 54.8%;
    position: relative;
  }

  .thumb-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }

  .badges {
    display: flex;
    position: absolute;
    left: 16px;
    bottom: 16px;
    text-transform: uppercase;

    div:first-child {
      margin-right: 5px;
    }
  }

  .name-info {
    display: flex;
    justify-content: space-between;
    padding: 22px 0 16px;
    border-bottom: 1px solid rgb(240 243 246 / 10%);

    >div {
      width: 100%;
    }

    .name {
      color: $color-white;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 7px;
      white-space: nowrap;
      width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      @media only screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 26px;
      }
    }

    .project-logo {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        border-radius: 100px;
      }

      span {
        color: $text-color-50;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.12em;
      }
    }
  }

  .box-logo-network {
    padding: 1px;
    width: 70px;
    height: 70px;
    background: linear-gradient(
        137.32deg,
        rgb(130 71 229 / 0%) 41.53%,
        #8247e5 100%
    );
    border-radius: 26px;
    position: absolute;
    bottom: -35px;
    right: 8px;

    .logo-network {
      width: 100%;
      height: 100%;
      background: rgb(47 50 65 / 50%);
      backdrop-filter: blur(64px);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 26px;

      img {
        width: 37px;
        height: 37px;
      }
    }

    @media only screen and (max-width: 768px) {
      width: 60px;
      height: 60px;
      bottom: -30px;
      border-radius: 22px;

      .logo-network {
        border-radius: 22px;
      }
    }
  }

  .info {
    margin-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(240 243 246 / 10%);
    height: 143px;

    .info-item {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      line-height: 23px;
      margin-bottom: 10px;

      .label {
        color: $text-color-200;
        font-weight: 500;
        @media only screen and (max-width: 768px) {
          font-size: 12px;
          line-height: 20px;
        }
      }

      .value {
        color: $text-color-50;

        div {
          color: $text-color-50;
          font-weight: 600;
        }
      }
    }
  }

  .social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 18px;

    a {
      margin: 0 15px;
      display: block;
      cursor: pointer;

      &:hover {
        filter: brightness(0) invert(1);
      }
    }
  }
}