@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';

.banner-project {
  position: relative;
  margin-bottom: 16px;

  .banner-img {
    height: 420px;

    video, img {
      width: 100%;
      height: 420px;
      object-fit: cover;
      border-radius: 15px;
    }
  }

  .banner-content {
    padding: 62px 80px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgb(0 0 0 / 0%) 0%,
      rgb(0 0 0 / 70%) 100%
    );
    border-radius: 15px;
    @include custom-flex(row, flex-end, space-between);

    @media (max-width: 992px) {
      padding: 20px;
    }

    @media (max-width: 768px) {
      padding: 16px;
      @include custom-flex(column, flex-start, flex-end);
    }

    .icon-volume {
      position: absolute;
      top: 60px;
      right: 80px;
      border: 2px solid rgb(240 243 246 / 10%);
      border-radius: 40px;
      width: 42px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .info-social {
      display: flex;

      a {
        display: block;
        margin-left: 20px;

        svg {
          path {
            fill: $text-color-50;
          }

          &:hover {
            path {
              fill: $color-white;
            }
          }
        }
      }

      @media (max-width: 768px) {
        display: none;
      }
    }

    .name-wrap {
      display: flex;
      align-items: flex-start;
      margin-bottom: 16px;

      svg {
        cursor: pointer;
        margin-top: 10px;
        margin-right: 15px;
        @media (max-width: 992px) {
          display: none;
        }
      }

      .name {
        font-family: R-Flex-Bold, sans-serif;
        font-size: 50px;
        line-height: 60px;
        color: $color-white;
        max-width: 100%;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        max-height: 120px;

        // display: box;
        -webkit-box-orient: vertical;
        word-break: break-word;
        cursor: pointer;

        @media (max-width: 992px) {
          font-size: 30px;
          line-height: 38px;
          max-height: 76px;
        }
      }
    }

    .network {
      font-family: R-Flex-Bold, sans-serif;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.12em;
      color: $color-white;
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }

    .token {
      border-top: 1px solid rgb(240 243 246 / 20%);
      padding-top: 16px;
      display: flex;
      align-items: center;
      width: max-content;
      padding-right: 16px;

      .icon-token {
        background: $brand-color-50;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        margin-right: 8px;

        img {
          width: 20px;
          height: 20px;
          border-radius: 100%;
        }
      }

      span {
        font-family: R-Flex-Bold, sans-serif;
        font-size: 22px;
        line-height: 30px;
        @media (max-width: 768px) {
          font-size: 18px;
          line-height: 26px;
        }
      }

      .icon-token-name {
        font-family: R-Flex-Regular, sans-serif;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 500px) {
          width: 70px;
        }
      }

      font-family: Inter, sans-serif;
      font-size: 18px;
      line-height: 22px;
      color: $color-white;

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 26px;
      }

      .address {
        display: flex;
        align-items: center;
        padding-left: 16px;
        margin-left: 16px;
        border-left: 2px solid $text-color-100;

        svg {
          margin-left: 12px;
          width: 25px;
          height: 28px;

          path {
            fill: $text-color-50;
          }

          &:hover {
            path {
              fill: $color-white;
            }
          }
        }
      }
    }

    .social-mobile, .icon-redirect {
      display: none;

      @media (max-width: 768px) {
        display: block;
        position: absolute;
        top: 16px;
        right: 16px;
      }
    }

    .icon-redirect {
      @media (max-width: 768px) {
        left: 16px;
        right: inherit;
      }
    }
  }
}
