@import 'src/styles/abstracts/variables';

.card {
  background: rgb(47 50 65 / 50%);
  position: relative;
  padding: 20px 16px;

  .next-time {
    padding-left: 36px;
    position: relative;

    .step {
      padding: 20px 0;
      position: relative;
      border-bottom: 1px solid $bg-100;

      &:not(:last-child)::after {
        content: '';
        display: block;
        position: absolute;
        top: 40px;
        left: -27px;
        height: calc(100% - 20px);
        width: 2px;
        background: $text-color-300;
      }

      .check-step-circle {
        position: absolute;
        top: 20px;
        left: -36px;
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 2px solid $text-color-300;
        background: transparent;
      }

      &.done {
        &:not(:last-child)::after {
          background: $accent-color-500;
        }

        .check-step-circle {
          background: $color-green-100;
          border-color: $color-green-100;

          &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            content: '\02713';
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
              Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
              sans-serif;
            font-size: 14px;
            font-weight: 700;
            color: $color-white;
            border: none;
          }
        }
      }

      &.happening {
        .check-step-circle {
          background: $color-green-100;
          border: 5px solid $color-white;
        }
      }

      + .step {
        // border-top: 1px solid $bg-100;
      }

      .label {
        color: $text-color-100;
        font-size: 14px;
        line-height: 23px;
        font-weight: 400;
        margin-bottom: 16px;
      }

      .content {
        .start {
          margin-bottom: 8px;
        }

        .start,
        .end {
          color: $text-color-50;
          font-size: 14px;
          font-weight: 700;
          line-height: 23px;
          white-space: nowrap;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .card {
    padding-left: 32px;
    padding-right: 32px;

    .next-time {
      display: flex;
      padding-left: 0;

      .step {
        flex-grow: 1;
        border-top: none !important;
        padding-top: 0;
        padding-bottom: 0;

        &::after {
          content: none !important;
        }

        .check-step-circle {
          display: none;
        }

        .label {
          padding-bottom: 20px;
          border-bottom: 2px solid $text-color-300;
          display: flex;
          justify-content: center;
          color: $text-color-50;
          font-size: 14px;
          line-height: 23px;
          font-weight: 700;

          > span {
            display: inline-block;
            position: relative;
            padding-left: 40px;

            &::before {
              content: '';
              display: inline-block;
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              width: 32px;
              height: 32px;
              border-radius: 16px;
              border: 2px solid $text-color-300;
              background: transparent;
            }
          }
        }

        &.done {
          .label {
            border-bottom: 2px solid $accent-color-500;

            > span {
              &::before {
                display: flex;
                align-items: center;
                justify-content: center;
                content: '';
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
                  Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
                  'Helvetica Neue', sans-serif;
                font-size: 20px;
                font-weight: 700;
                color: white;
                border: none;
                background: $accent-color-500;
                background-size: contain;
                background-image: url('../../assets/icons/CheckIcon.svg');
              }
            }
          }
        }

        &.happening {
          .label {
            border-bottom: 2px solid $accent-color-500;

            > span {
              &::before {
                border: 5px solid $color-white;
                background: $accent-color-500;
              }
            }
          }
        }

        .content {
          .start {
            margin-bottom: 4px;
          }

          .start,
          .end {
            text-align: center;
            color: $text-color-100;
            font-size: 12px;
            line-height: 20px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
