@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.button {
  cursor: pointer;
  border: none;
  outline: none;
  white-space: nowrap;
  box-sizing: border-box;
  transition: all 0.15s linear;
  @include mix-flex-centered;

  &.big {
    height: 50px;
    padding: 0 35px;
    border-radius: 50px;
    @include body-3-semibold;
  }

  &.large {
    height: 50px;
    padding: 0 24px;
    border-radius: 50px;
    @include body-3-semibold;
  }

  &.medium {
    height: 40px;
    padding: 0 24px;
    border-radius: 40px;
    @include body-3-semibold;
  }

  &.small {
    height: 32px;
    padding: 0 18px;
    border-radius: 32px;
    @include body-4-semibold;
  }

  &.mini {
    height: 28px;
    padding: 0 15px;
    border-radius: 28px;
    @include body-4-semibold;
  }

  &.primary {
    background: $bg-brand-0;
    color: $color-white;

    &:hover {
      background: $hover-brand-0;
    }
  }

  &.secondary {
    background: $bg-accent-8;
    color: $color-white;

    &:hover {
      background: $hover-accent-8;
    }

    &:disabled {
      background: $bg-accent-8-disable !important;

      &:hover {
        background: inherit;
        color: inherit;
        cursor: not-allowed;
      }
    }
  }

  &.tertiary {
    background: rgb(255 255 255 / 10%);
    border: 2px solid $bg-100;
    color: $color-brand-4;

    &:hover {
      background: rgb(255 255 255 / 20%);
    }
  }

  &.quaternary {
    background: $bg-100;
    font-weight: 500 !important;
    font-size: 16px;
    line-height: 26px;
    color: $text-color-50;

    &:hover {
      background: $bg-50;
    }
  }

  &.white {
    background: $bg-brand-4;
    color: $color-brand-3;

    &:hover {
      color: $color-accent-1;
    }
  }

  &.outline {
    background: transparent;
    border: 2px solid $bg-6;
    color: $color-brand-4;

    &:hover {
      border: 2px solid rgb(255 255 255 / 90%);
    }
  }

  &.warning {
    background: $bg-accent-6;
    color: $color-brand-4;

    &:disabled {
      background: $bg-accent-6;
      color: $color-brand-4;

      &:hover {
        cursor: not-allowed;
        background: $bg-accent-6;
        color: $color-brand-4;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    background: $bg-4;
    color: rgb(255 255 255 / 50%);

    &:hover {
      background: inherit;
      color: inherit;
    }
  }
}
