@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';

.badge {
  border-radius: 33px;
  padding: 3px 8px;
  line-height: 20px;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.12em;
  width: fit-content;
  @include custom-flex(row, center, center);

  svg {
    margin-right: 7px;
  }

  &.pink {
    color: $accent-color-100;
    background: $bg-pink-200;
  }

  &.green {
    color: $accent-color-500;
    background: $bg-green-200;
  }

  &.orange {
    color: $accent-color-200;
    background: $bg-orange-200;
  }

  &.skyblue {
    color: $brand-color-200;
    background: $bg-skyblue-200;
  }

  &.red {
    color: $accent-color-600;
    background: $bg-red-200
  }

  &.blue {
    color: $brand-color-300;
    background: $bg-blue-200
  }

  &.outline {
    border: 1px solid rgb(255 255 255 / 35%);
    backdrop-filter: blur(64px);
    background: rgb(47 50 65 / 50%);
    font-weight: 700;
    font-family: R-Flex-Bold, sans-serif;
  }
}
