@import 'src/styles/abstracts/variables';

.container {
  width: 100%;

  &__chart {
    height: 616px;
    width: 100%;
    background-color: $bg-300;
    padding: 10px 0 24px 24px;
    border-radius: 12px;
  }

  &__legend {
    margin-top: 10px;

    &__list {
      display: flex;
      margin: 0;
      padding: 0;
      flex-flow: row wrap;
      width: 100%;
      margin-top: 35px;

      &__item {
        align-items: center;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        margin-left: 10px;
        width: calc(30% - 10px);
        padding: 16px 0;
      }

      &__icon-checkbox {
        height: 24px;
        width: 24px;
      }

      &__color-box {
        height: 20px;
        margin-left: 12px;
        min-width: 46px;
        border-radius: 8px;
      }

      &__text {
        margin: 0;
        margin-left: 12px;
        padding: 0;
        font-size: 14px;
        line-height: 23px;
        font-style: normal;
        color: $text-color-100;
      }
    }
  }
}
@media (max-width: 992px) {
  .container {
    &__legend {
      &__list {
        margin-top: 30px;
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    &__chart {
      padding: 10px 0 24px 15px;
    }

    &__legend {
      display: block;

      &__list {
        margin-top: 30px;

        &__item {
          width: 100%;
        }
      }
    }
  }
}