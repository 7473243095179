@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.description {
  padding: 0 32px;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 32px;
  padding-bottom: 130px;

  @include mobile {
    padding: 0;
    padding-bottom: 50px
  }

  .tabs {
    border-bottom: 1px solid $color-text-4;
  }

  .indicator {
    background-color: $color-brand-2;
    height: 3px;
  }

  .project-info {
    margin: 0;
    padding-top: 64px;

    @include mobile {
      padding-top: 32px;
    }
  }
}

.table {
  padding-top: 32px;
  @include mobile {
    overflow-x: scroll;
  }

  table {
    width: 100%;
    border-radius: 0 0 16px 16px;
  }

  p {
    margin: 0;
  }

  th {
    padding: 15px 20px;
    background-color: rgb(255 255 255 / 10%);
    @include body-4-semibold;

    color: $color-text-2;

    @for $i from 3 through 10 {
      &:nth-child(#{$i}) {
        text-align: right;
      }
    }
  }

  td {
    padding: 15px 20px;
    @include body-3-medium;

    color: $color-text-1;

    @for $i from 3 through 10 {
      &:nth-child(#{$i}) {
        text-align: right;
      }
    }
  }

  tr {
    padding: 16px 32px;
    border-radius: 16px 16px 0 0;
    border: 1px solid $color-text-4;
  }
}

// custom tab
.custom-tab {
  @include body-3-medium;

  color: $color-text-2;
  text-transform: none;
  min-width: 100px;
}

.selected {
  color: $color-brand-2;
}
