@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.terms-of-use-wrap {
  @include mix-max-width;

  margin-bottom: 50px;

  .text-center {
    text-align: center;
  }

  .text-content,
  .text-dot {
    @include body-3-regular;
  }

  .text-notice {
    @include body-3-regular;

    font-weight: 700;
    font-style: italic;
  }

  .title {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .padding-section {
    padding-bottom: 20px;
  }

  .text-dot {
    position: relative;
    padding-left: 24px;

    &::before {
      position: absolute;
      left: 0;
      top: 5px;
      content: '';
      width: 10px;
      height: 10px;
      background-color: $text-color-100;
      border-radius: 50%;
    }
  }

  .text_underline {
    text-decoration: underline;
  }
}
