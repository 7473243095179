@import 'src/styles/abstracts/variables';

.pool-info {
  padding: 0 16px;
  height: 100%;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .pool-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .btn-back {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      background: transparent;
    }

    .pool-intro {
      flex-grow: 1;
      display: flex;
      align-items: center;
      padding: 0 16px;

      img,
      svg {
        &:not(:first-child) {
          margin-left: 4px;
        }

        width: 30px;
        height: auto;
        object-fit: contain;
        vertical-align: middle;
      }

      .content {
        padding: 0 10px;

        .symbol {
          color: $color-white;
          font-size: 16px;
          font-weight: 500;
          margin: 0;
          line-height: 28px;
        }

        .name {
          color: #808191;
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }

    .btn-close {
      border: none;
      outline: none;
      width: 30px;
      height: 30px;
      position: relative;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;

      svg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .pool-thumb-mobile {
    padding: 12px 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    .img-thumb {
      display: block;
      object-fit: cover;
      width: calc(52% + 32px);
      max-width: 100%;
      aspect-ratio: 239 / 245;
      border-radius: 12px;
    }
  }

  .pool-thumb {
    padding: 16px 0;

    .img-thumb {
      display: block;
      object-fit: cover;
      width: 100%;
      max-width: 100%;
      aspect-ratio: 1 / 1;
      border-radius: 12px;
    }
  }

  .pool-body {
    padding-bottom: 20px;

    .pool-detail-slot {
      padding: 16px 0;

      &:not(:last-child) {
        border-bottom: 1px solid $bg-100;
      }

      .head {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        + .main {
          margin-top: 4px;
        }

        .label {
          display: flex;
          align-items: center;
          flex-grow: 1;
          margin-bottom: 4px;

          .icon {
            display: inline-block;
            margin-right: 8px;
            width: 12px;
            height: 12px;
            border-radius: 4px;
            background: $brand-color-50;
          }

          .text {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: $text-color-100;
            font-size: 14px;
            font-weight: 500;
          }
        }

        .detail-overview {
          display: inline-block;
          margin-bottom: 4px;

          .btn-view {
            cursor: pointer;
          }

          .btn-withdraw {
            &:disabled {
              opacity: 0.5;
              cursor: not-allowed;
              background: $bg-100;
            }

            border: none;
            outline: none;
            height: 28px;
            border-radius: 14px;
            padding: 0 15px;
            color: $color-white;
            font-size: 12px;
            font-weight: 600;
            background: $brand-color-50;
          }
        }
      }

      .main {
        .value {
          color: $text-color-50;
          font-size: 16px;
          font-weight: 700;
        }

        .box-harverst {
          margin-top: 10px;
          border: 2px solid $text-color-300;
          border-radius: 12px;
          height: 50px;
          padding: 0 16px;
          display: flex;
          align-items: center;

          .value-harverst {
            font-size: 16px;
            font-weight: 400;
            color: white;
            display: block;
            flex-grow: 1;
          }
        }
      }
    }
  }
}

.pool-btn-stake {
  .stake-footer {
    width: 100%;
  }
}

.pool-btn-stake-mobile {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $bg-400;
  box-shadow: 0 64px 64px -48px rgb(15 15 15 / 10%);

  .stake-footer {
    width: 100%;
    padding: 16px;
  }
}

.text-enable {
  margin: 40px 0 60px;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
  color: $text-color-200;
}

.group-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -8px;
  margin-right: -8px;

  .btn {
    margin: 0 8px;
    width: 100% !important;
    padding: 0;

    svg {
      height: auto;
      object-fit: contain;
      width: 17px;
      margin-right: 5px;
    }
  }
}

.modal-overview {
  background: $bg-200;
  width: 100%;
  height: 100%;
  color: white;
  padding: 16px;

  .modal-title {
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      color: #fcfcfd;
      font-size: 22px;
      font-weight: 700;
      display: flex;
      align-items: center;

      .prev-icon {
        transform: rotate(180deg);
        margin-left: 12px;
        margin-right: 20px;
        color: $color-white;
        height: 12px;
        width: auto;
        object-fit: contain;
      }
    }

    .right {
      .btn-close {
        border: none;
        outline: none;
        background: transparent;
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }
  }
}
