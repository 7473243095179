@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';
@import 'src/styles/abstracts/extends';

.nav-tab {
  @include tablet {
    padding: 16px 32px 0;
  }

  .list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
    @include mix-border-divider($position: bottom);
    @include flex-items-center;

    .item {
      cursor: pointer;
      flex-grow: 1;
      @include flex-justify-center;
      @include body-3-medium;
      @include mix-border-divider(
        $position: bottom,
        $width: 3px,
        $color: transparent
      );

      color: $color-text-2;
      padding-bottom: 12px;
      @include tablet {
        flex-grow: 0;
        padding-left: 10px;
        padding-right: 10px;
        margin-right: 16px;
      }

      &.active {
        color: $color-brand-2;
        border-color: currentcolor;
      }
    }
  }
}

.tabs-wapper {
  padding: 10px 0;

  .chart {
    margin-bottom: 10px;
  }
}


// start --- Tab Details

%view-field {
  background: $bg-4;
  padding: 15px 16px;
  border-radius: 4px;
  @include tablet {
    padding: 16px 32px;
  }
}

%view-icon-dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  margin: 0 11px;
  background: currentcolor;
}

%view-contain-icon {
  width: 100%;
  max-width: 100%;
  object-fit: contain;
}

.detail-tab {
  margin-bottom: 32px;
  @include tablet {
    background: $bg-3;
    border-radius: 16px;
  }

  .detail-header {
    background: $bg-3;
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 10px;
    @include tablet {
      display: flex;
      flex-wrap: wrap;
      padding: 32px 32px 16px;
      margin-bottom: 0;
    }

    .brand-name {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      @include tablet {
        margin-bottom: 0;
      }

      .brand-symbol {
        @include mix-adornment-start($width: 32px, $height: 32px);

        border-radius: 32px;

        img,
        svg {
          @extend %view-contain-icon;

          height: 32px;
          width: 32px;
          object-fit: cover;
          border-radius: 32px;
        }
      }

      .brand-title {
        color: $color-text-1;
        @include body-1-bold;

        .symbol {
          margin-left: 8px;
          background: $bg-8;
          color: $color-neutrals-8;
          @include hairline-2;

          text-transform: uppercase;
          padding: 8px;
          border-radius: 11px;
        }
      }
    }

    .contact-address {
      padding: 10px 0;
      @include flex-items-center;
      @include tablet {
        padding: 0 8px;
        margin-left: 8px;
        border-top: none;
        align-self: center;
        @include mix-border-divider;
      }
      @include mix-border-divider($position: top, $color: $color-text-4);

      .logo-token {
        @include mix-adornment-start($width: 26px, $height: 26px);

        img,
        svg {
          max-width: 100%;
        }
      }

      .address {
        color: $color-text-1;
        @include body-2-regular;
      }

      .btn-copy {
        @include mix-adornment-end($width: 26px, $height: 26px);
        @include mix-flex-centered;

        padding: 0;
        border: none;
        outline: none;
        background: transparent;
      }
    }

    .detail-description {
      margin-top: 14px;
      @include tablet {
        width: 100%;
        margin-top: 32px;
      }

      .detail-label {
        color: $color-text-2;
        margin-bottom: 8px;
        @include flex-items-center;
        @include body-2-bold;

        .icon-dot {
          @extend %view-icon-dot;
        }
      }

      .detail-desc {
        color: $color-text-2;
        @include body-3-regular;

        white-space: pre-wrap;
      }
    }
  }

  %view-detail-description-body {
    margin-bottom: 10px;

    .the-detail {
      padding-bottom: 6px;
      @include tablet {
        padding-bottom: 0;
      }

      .the-header {
        padding-bottom: 16px;

        .title {
          color: $color-text-2;
          @include body-2-bold;
          @include flex-items-center;

          .icon-dot {
            @extend %view-icon-dot;
          }
        }

        .sub-header {
          margin-top: 16px;
          margin-bottom: 16px;
          @include flex-items-center;

          .label {
            color: $color-text-2;
            @include body-3-medium;

            margin-right: 8px;
          }

          .box-round-value {
            color: $color-text-1;
            @include body-3-regular;
            @include mix-flex-centered;

            padding: 0 10px;
            height: 28px;
            border-radius: 28px;
            border: 1px solid $color-text-4;

            .active,
            .inactive {
              width: 10px;
              height: 10px;
              border-radius: 10px;
              display: inline-block;
              margin-right: 6px;
            }

            .active {
              background: $color-accent-5;
            }

            .inactive {
              background: $color-text-3;
            }
          }

          .box-info {
            @include mix-flex-centered;

            padding: 0 10px;
            height: 40px;
            border-radius: 40px;
            background: $bg-6;
            margin-right: 20px;
            white-space: nowrap;

            .text {
              color: $color-text-1;
              @include body-3-regular;
            }

            .adornment-end {
              min-width: 24px;
              display: inline-block;
              @include mix-adornment-end($width: 24px, $height: 24px);

              img,
              svg {
                @extend %view-contain-icon;

                height: 24px;
                object-fit: cover;
                border-radius: 100px;
              }
            }
          }

          .description {
            display: flex;
            @include tablet {
              padding: 0 16px;
            }

            .text {
              color: $color-text-2;
              @include body-3-regular;

              margin-bottom: 0;

              .link {
                color: $color-brand-2;
                @include body-3-regular;
                @include flex-items-center;

                margin-top: 16px;

                @include tablet {
                  margin-left: 10px;
                  margin-top: 0;
                  display: inline;
                }

                svg {
                  margin-left: 4px;
                  margin-bottom: 4px;
                }
              }
            }
          }
        }
      }
    }
  }

  .detail-description-body {
    @extend %view-detail-description-body;
  }

  .detail-body {
    background: $bg-3;
    border-radius: 12px;
    padding: 16px;
    @extend %view-detail-description-body;
    @include tablet {
      padding: 16px 32px;
      margin-bottom: 0;
    }

    &:last-child {
      @include mobile {
        .the-header, .the-detail {
          padding-bottom: 0;
        }

        .sub-header {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

.grid-container {
  @extend %view-grid-container;
}

%view-social-block {
  @extend %view-rounded-hidden;

  @include tablet {
    border-radius: 12px 4px 4px 12px;
  }

  .collection {
    @extend %view-field;

    .grow {
      .social-field,
      .tn-field {
        @include flex-justify-end;
      }
    }

    .social-field,
    .tn-field {
      @include flex-items-center;

      .adornment-start {
        @include mix-adornment-start($width: 24px, $height: 24px);

        svg,
        img {
          color: $color-text-1;
          @extend %view-contain-icon;
        }
      }

      .label {
        color: $color-text-1;
        @include body-3-medium;
      }

      .adornment-end {
        svg,
        img {
          color: $color-text-2;
        }
      }

      .no-value {
        color: $color-text-3;
        @include body-3-medium;
      }
    }
  }
}

.social-block {
  @extend %view-social-block;
}

.token-network-block {
  @extend %view-social-block;
  @include tablet {
    border-radius: 4px 12px 12px 4px;
  }
}

%view-detail-block {
  @extend %view-rounded-hidden;

  border-radius: 10px;

  // @include tablet {
  //   @include mix-card-rounded;
  // }
  .collection {
    @extend %view-field;

    .grow {
      .detail-field,
      .owner-field {
        justify-content: flex-end;
      }
    }

    .detail-field,
    .owner-field {
      @include flex-items-center;

      .adornment-start {
        @include mix-adornment-start;

        .adornment-blue {
          @include mix-icon-square;
        }
      }

      .label {
        color: $color-text-1;
        @include body-3-medium;
      }

      .value {
        color: $color-brand-4;
        @include body-3-medium;
      }
    }
  }
}

.detail-block {
  @extend %view-detail-block;
}

%view-statistic-block {
  @extend %view-rounded-hidden;

  .collection {
    @extend %view-field;

    .grow {
      .statistic-field,
      .setting-field {
        justify-content: flex-end;
      }
    }

    .statistic-field,
    .setting-field {
      @include flex-items-center;

      .adornment-start {
        @include mix-adornment-start;

        .adornment-blue {
          @include mix-icon-square;
        }
      }

      .label {
        color: $color-text-1;
        @include body-3-medium;
      }

      .value {
        color: $color-brand-4;
        @include body-3-medium;
      }

      .adornment-end {
        @include mix-adornment-end($width: 22px, $height: 22px);

        svg, img {
          @extend %view-contain-icon;

          height: 22px;
          object-fit: cover;
          border-radius: 100px;
        }
      }
    }
  }
}

.statistic-block {
  @extend %view-statistic-block;
}

.setting-block {
  @extend %view-statistic-block;
}

.owner-right-block {
  @extend %view-detail-block;
}

// end ---Tab Details



// start --- Tab History

.history-tab {
  @include tablet {
    background: $bg-3;
    padding: 16px 32px;
    border-radius: 16px;
    overflow: hidden;
  }

  .history-body {
    width: 100%;
  }

  .history-footer {
    width: 100%;
    @include flex-justify-end;

    .pagination {
      padding: 16px 32px;
    }
  }
}

.history-list {
  padding-bottom: 16px;
  @include tablet {
    padding-top: 16px;
  }

  .history-item {
    padding-bottom: 10px;

    .collapse {
      padding: 20px 16px;
      box-shadow: 0 4px 4px rgb(0 0 0 / 16%);
      background: $bg-3;
      border-radius: 12px;

      .collapse-header {
        .head {
          padding-bottom: 20px;
          @include flex-items-center;

          a, .title {
            display: block;
            color: $color-text-1;
            @include body-2-bold;
          }

          .btn-collap {
            @extend %view-btn-collap;
          }

          a {
            display: flex;
            flex-grow: 1;

            img {
              width: 20px;
              height: 20px;
              margin-left: 15px;
            }
          }
        }

        .field {
          .label {
            @include body-3-regular;

            color: $color-text-2;
          }

          .value {
            @include body-3-bold;

            color: $color-text-1;

            &.buy {
              color: $color-accent-5;
            }

            &.sell {
              color: $color-accent-6;
            }
          }
        }
      }

      .collapse-body {
        padding-top: 16px;

        .row {
          + .row {
            padding-top: 16px;
          }

          .field {
            .label {
              @include body-3-regular;

              color: $color-text-2;
            }

            .value {
              @include body-3-bold;

              color: $color-text-1;
            }
          }
        }
      }

      .row {
        @extend %view-row-wrapper;

        .grow {
          justify-content: flex-end;
        }
      }
    }
  }
}

.history-body {
  width: 100%;

  .table-head {
    .table-row{
      display: flex;
      align-items: center;
      height: 55px;
      color: $color-text-2;
      @include body-4-semibold;
    }
  }

  .table-body {
    .table-row {
      display: flex;
      align-items: center;

      &:nth-of-type(odd) {
        height: 55px;
        background: $bg-4;
      }

      &:nth-of-type(even) {
        height: 87px;
      }

      &:hover {
        background-color: $bg-5;
      }

      a {
        color: $color-text-1;
      }

      color: $color-text-1;
      @include body-3-medium;

      .buy {
        color: $color-accent-5;
      }

      .sell {
        color: $color-accent-6;
      }
    }
  }

  .col {
    padding: 0 8px;

    $time-width: 30%;
    $price-width: 15%;

    &:first-child {
      padding-left: 32px;
    }

    &:last-child {
      padding-right: 32px;
    }

    &.col-time{
      width: $time-width;
    }

    &.col-price {
      width: $price-width;
    }

    &.col-type,
    &.col-input,
    &.col-output,
    &.col-wallet {
      width: calc((100% - $time-width - $price-width) / 4);
    }

    &.col-input,
    &.col-output,
    &.col-price,
    &.col-wallet {
      text-align: right;
    }

    &.link-tx {
      width: 50px;
      display: flex;
      justify-content: flex-start;

      img {
        width: 20px;
        height: 20px;
        border-radius: 100px;
      }
    }
  }
}

// end ---Tab History
