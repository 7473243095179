@import 'src/styles/base/typography';
@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';

.info-account {
  height: 40px;
  border-radius: 40px;
  background: hsl(0deg 0% 100% / 5%);
  @include flex-items-center;

  .asset-account {
    display: none;
    @include tablet {
      padding: 0 15px;
      text-transform: uppercase;
      white-space: nowrap;
      color: $color-text-1;
      @include body-3-medium;
      @include mix-flex-centered;
    }
  }

  .btn-popover {
    width: 40px;
    height: 40px;
    max-height: 40px;
    border-radius: 40px;
    border: none;
    outline: none;
    background: $bg-6;

    &:hover {
      background: rgb(0 120 255 / 30%);
    }

    @include tablet {
      width: auto;
      padding-left: 28px;
      padding-right: 32px;
    }

    .wallet-address {
      @include mix-flex-centered;

      .address {
        margin: 0 10px;
        color: $color-text-1;
        @include body-3-medium;
      }

      .img-contain {
        width: auto;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        vertical-align: middle;

        &.img-wallet {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.drawer-menu {
  height: 100%;
  background: $bg-2;
  justify-content: space-between;
  @include mix-flex-column;

  .drawer-top {
    padding: 30px 16px;

    .header {
      width: 100%;
      margin-bottom: 22px;
      @include flex-items-center;

      .title {
        flex-grow: 1;
        text-align: center;
        color: $color-white;
        @include body-2-bold;
      }
    }

    .body {
      padding: 0;
    }
  }
}

.root-drawer-item {
  padding: 0;
}

.profile-item {
  cursor: auto;
  padding-bottom: 16px;
  border-bottom: 1px solid rgb(240 243 246 / 10%);
  @include tablet {
    padding-bottom: 0;
    border-bottom: none;
  }

  .address {
    padding: 5px 8px;
    padding-right: 0;
    color: $color-white;
    @include h5-bold;
  }

  .user-status {
    padding: 5px 0;
    @include flex-items-center;

    .tier {
      padding: 0 8px;
      @include flex-items-center;

      &-icon {
        width: 100%;
        height: auto;
        max-width: 25px;
        object-fit: contain;
        margin-right: 8px;
      }

      &-text {
        text-transform: capitalize;
        color: $color-brand-3;
        @include body-3-medium;
      }
    }

    .kyc-status {
      flex-grow: 1;
      @include flex-justify-end;

      .verified {
        height: 30px;
        border-radius: 30px 0 0 30px;
      }
    }
  }
}

.account-item {
  width: 100%;
  margin-top: 16px;
  padding: 9px 8px;
  margin-bottom: 10px;
  border-radius: 8px;
  @include flex-items-center;

  &:hover {
    background: rgb(0 120 255 / 30%);
  }

  &-icon {
    @include mix-adornment-start(22px, 22px);
  }

  &-text {
    color: $color-neutrals-7;
    @include body-3-medium;
  }
}

.drawer-bottom {
  padding: 24px;
  background: $bg-4;

  .wallet-address {
    padding-bottom: 16px;
    border-bottom: 1px solid rgb(240 243 246 / 10%);
    @include flex-items-center;

    &-icon {
      @include mix-adornment-start(20px, 20px);
    }

    &-text {
      color: $color-brand-3;
      @include body-3-medium;
    }
  }

  .my-balance {
    margin: 16px 0;
    width: 100%;
    @include flex-items-center;

    .label {
      color: $color-text-3;
      @include body-3-medium;
    }

    .value {
      flex-grow: 1;
      color: $color-text-1;
      @include body-3-bold;
      @include flex-justify-end;
    }
  }
}

.item-disconnect {
  &:hover {
    .slot-disconnect {
      color: $color-white;
      background: $color-accent-6;

      &-icon {
        filter: brightness(0) invert(1);
      }
    }
  }

  .slot-disconnect {
    padding: 0 16px;
    width: 100%;
    height: 40px;
    border-radius: 40px;
    border: 1px solid $color-accent-6;
    color: $color-accent-6;
    @include body-3-semibold;
    @include flex-items-center;

    &-icon {
      @include mix-adornment-start(20px, 20px);
    }
  }
}

.paper-menu {
  background: transparent;
  margin-top: 4px;
}

.list-menu {
  background: $bg-5;
  border-radius: 12px;
  border: 2px solid $color-text-4;
  padding: 8px;
  overflow: hidden;
  box-shadow: 0 16px 64px rgb(0 0 0 / 40%);
}
