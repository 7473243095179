@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';

.common-info {
  .title {
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    color: $color-white;
    margin: 160px 0 20px;
    text-align: center;
    font-family: R-Flex-Bold, sans-serif;

    @media screen and (max-width: 992px) {
      margin: 135px 0 20px;
    }
  }

  .description {
    color: $text-color-200;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    width: 700px;
    margin: auto;
    text-align: center;
  }

  .btn-group {
    margin: 20px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    flex-wrap: wrap;

    .button {
      min-width: fit-content;
      margin: 12px 8px;
      font-weight: 600;

      svg {
        margin-right: 8px;
      }
    }

    .buy-buni-btn {
      padding: 0 18px;
      height: 36px;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.12em;
      font-family: R-Flex-Bold, sans-serif;
      color: $text-color-50;

      &:hover {
        border: 2px solid $bg-100;
        background: rgb(255 255 255 / 10%);
      }
    }

    .address-btn {
      padding: 0 18px;
      height: 36px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: $text-color-50;

      svg {
        margin-right: 0;
        margin-left: 8px;
      }

      &:hover {
        border: 2px solid $bg-100;
        background: rgb(255 255 255 / 10%);
      }
    }
  }
}

.list-project {
  @include mix-max-width;
}

.btn-explore {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.raise-capital {
  position: relative;
  color: $text-color-300;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-align: center;
  margin-top: 60px;
  font-family: R-Flex-Bold, sans-serif;

  &::before {
    content: '';
    display: block;
    width: calc(50vw - 300px);
    height: 1px;
    background: $text-color-300;
    left: 80px;
    top: 50%;
    position: absolute;
  }

  &::after {
    content: '';
    display: block;
    width: calc(50vw - 300px);
    height: 1px;
    background: $text-color-300;
    right: 80px;
    top: 50%;
    position: absolute;
  }
}

.capitals {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 100px;

  svg {
    margin: 12px 40px;
  }
}

.staking {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    color: $color-white;
    text-align: center;
    font-family: R-Flex-Bold, sans-serif;
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: $text-color-200;
    width: 500px;
    margin: 20px auto;
  }

  .staking-table {
    margin: 30px 0;
    border: 1px solid rgb(255 255 255 / 10%);
    border-radius: 15px;

    table {
      tr {
        &:first-child {
          background: rgb(255 255 255 / 10%);

          td {
            &:first-child {
              border-top-left-radius: 15px;
            }

            &:last-child {
              border-top-right-radius: 15px;
            }
          }
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .staking-table-desktop {
    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .staking-table-mobile {
    display: none;
    @media screen and (max-width: 992px) {
      display: block;
    }
  }

  .start-staking-btn {
    margin: 20px auto;
  }
}

.lbp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  margin-top: 150px;
  margin-bottom: 150px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 86px;
  }

  .lbp-info {
    width: 500px;
    margin-top: 20px;

    .title {
      font-weight: 700;
      font-size: 50px;
      line-height: 60px;
      color: $color-white;
      margin: 20px 0;
      text-align: right;
      font-family: R-Flex-Bold, sans-serif;
    }

    .description {
      color: $text-color-200;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      margin: auto;
      text-align: right;
    }

    .btn-group {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 50px;

      .lbp-btn {
        margin: 0 10px;

        svg {
          margin-right: 10px;
        }
      }
    }
  }

  .lbp-image {
    margin-top: 20px;
    width: 480px;
    height: auto;
    object-fit: contain;
    margin-left: 100px;
  }
}
@media screen and (max-width: 526px) {
  .common-info {
    .btn-group {
      .button {
        height: 40px;
        padding: 0 24px;
      }
    }

    .title {
      font-size: 30px;
      line-height: 38px;
      padding: 0 30px;
    }

    .description {
      width: calc(100% - 60px);
    }
  }

  .raise-capital {
    width: 70%;
    margin: 60px auto 0;

    &::before {
      width: calc(50vw - 130px);
      left: -50px;
    }

    &::after {
      width: calc(50vw - 130px);
      right: -50px;
    }
  }

  .capitals {
    svg {
      margin: 12px 20px;
    }
  }

  .staking {
    .title {
      font-size: 30px;
      line-height: 38px;
    }

    .description {
      width: calc(100% - 60px);
    }
  }

  .lbp {
    margin-top: 50px;

    .lbp-info,
    .lbp-image {
      width: 100%;
    }

    .lbp-info {
      .title {
        font-size: 30px;
        line-height: 38px;
        text-align: center;
      }

      .description {
        width: 100%;
        padding: 15px;
        text-align: center;
      }

      .btn-group {
        justify-content: center;

        .lbp-btn {
          height: 40px;
          padding: 0 24px;
        }
      }
    }

    .lbp-image {
      margin-left: 0;
    }
  }
}
