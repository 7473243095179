@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/abstracts/extends';
@import 'src/styles/base/typography';

.side {
  margin-bottom: 10px;
  @include laptop {
    max-width: 260px;
    padding-right: 30px;
  }

  .top {
    padding: 10px 0;
    @include laptop {
      @include mix-border-divider($position: bottom, $color: $bg-6);

      padding-bottom: 15px;
    }

    .title {
      color: $color-text-1;
      @include body-1-bold;
      @include mix-border-divider($position: bottom, $color: $bg-6);

      padding-bottom: 10px;
      text-align: center;
      @include laptop {
        color: $color-brand-4;
        @include display-2-bold;

        text-align: left;
        padding-bottom: 0;
        border-bottom: none;
      }
    }

    .desc {
      color: $color-text-3;
      @include body-4-regular;
      @include flex-items-center;

      text-align: left;
      margin-top: 10px;
      @include laptop {
        text-align: left;
        margin-top: 5px;
      }

      .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include laptop {
          white-space: normal;
        }
      }

      .icon {
        min-width: 25px;
        display: inline-block;
        margin-right: 10px;
      }

      .icon-next-detail {
        @include mix-adornment-end($width: 22px, $height: 22px);
        @include mix-flex-centered;

        margin-right: 16px;

        svg path {
          fill: $color-brand-3;
        }
      }
    }
  }

  .pseudo-stickey {
    height: 48px;
  }

  .position-static {
    position: relative;
  }

  .position-sticky {
    position: fixed;
    top: 84px;
    left: 0;
    right: 0;
    padding: 10px 15px;
    background: $bg-1;
    z-index: 4;
  }

  .step-line {
    @include flex-items-center;

    box-shadow: 0 4px 16px -8px rgb(15 15 15 / 10%);
    border-radius: 12px;
    background: $bg-3;
    padding: 0 25px;

    .item {
      flex-shrink: 1;
      @include laptop {
        width: 100%;
      }

      &.done {
        .intro {
          background: $bg-4;

          .index {
            border-color: $color-accent-5;

            &::after {
              @extend %view-icon-checkmark;
              @include mix-flex-centered;

              position: absolute;
              top: -1px;
              left: -1px;
              width: calc(100% + 2px);
              height: calc(100% + 2px);
              border-radius: 50%;
              background: $color-accent-5;
              z-index: 2;
              color: $color-brand-4;
              font-size: 14px;
            }
          }

          .title {
            color: $color-brand-4;
          }
        }

        .detail {
          .list-step {
            display: none;
          }
        }
      }

      &.pendding {
        .intro {
          .index {
            border-color: $color-accent-5;
            color: $color-brand-4;
          }

          .title {
            color: $color-brand-4;
          }
        }

        .detail {
          .list-step {
            .view {
              &.done {
                color: $color-accent-5;

                &::before {
                  @extend %view-icon-checkmark;

                  margin-right: 8px;
                  font-size: 14px;
                  display: inline-block;
                }
              }

              &.pendding {
                color: #caccd1;
              }
            }
          }
        }
      }

      .intro {
        background: transparent;
        box-shadow: 0 4px 16px -8px rgb(15 15 15 / 10%);
        border-radius: 48px;
        padding: 8px;
        @include laptop {
          @include flex-items-center;

          max-width: 220px;
        }

        .index {
          @include mix-flex-centered;

          width: 32px;
          min-width: 32px;
          height: 32px;
          border-radius: 32px;
          border: 2px solid $color-neutrals-3;
          color: $color-text-3;
          font-size: 14px;
          line-height: 16px;
          font-weight: 700;
          position: relative;
        }

        .title {
          display: none;
          @include laptop {
            display: block;
            margin-left: 16px;
            color: $color-text-3;
            @include body-3-bold;
          }
        }
      }

      .detail {
        display: none;
        @include laptop {
          display: flex;
        }

        .line {
          display: block;
          min-height: 24px;
          border-top: none;
          border-left: 1px dashed #777e91;
          margin: 4px 24px;
        }

        .list-step {
          padding: 6px;
          flex-grow: 1;

          .view {
            color: $color-text-3;
            @include body-4-semibold;

            padding: 10px 0;
          }
        }
      }
    }

    .divider {
      flex-grow: 1;
      border-top: 1px dashed #777e91;
      margin: 0 4px;
      @include laptop {
        display: none;
      }
    }

    @include laptop {
      flex-direction: column;
      background: transparent;
      align-items: flex-start;
      padding: 40px 0;
      border-bottom: 1px solid #1a203f;
      border-radius: 0;
    }
  }

  .bottom {
    display: flex;
    padding: 20px 12px;

    .icon {
      width: 25px;
      min-width: 25px;

      svg,
      img {
        width: auto;
        height: auto;
        max-width: 100%;
      }
    }

    .desc {
      padding-left: 12px;

      .text {
        color: $color-text-3;
        @include body-4-regular;

        display: block;
        margin-bottom: 4px;

        &.summary {
          position: relative;
          height: 80px;
          overflow-y: hidden;

          &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            background: linear-gradient(
              180deg,
              rgb(7 11 33 / 0%) 0%,
              rgb(7 11 33 / 100%) 100%
            );
          }
        }
      }

      .read-more {
        color: $color-brand-3;
        @include body-4-semibold;
        @include flex-items-center;

        cursor: pointer;

        svg {
          margin-left: 8px;
        }
      }
    }
  }
}

.modal-overview {
  box-shadow: 0 64px 64px -48px rgb(31 47 70 / 12%);

  .root {
    background: rgb(26 32 63 / 100%);
  }

  .head-title {
    @include flex-items-center;

    margin-bottom: 32px;

    .title-text {
      color: $color-neutrals-8;
      @include h5-bold;

      display: block;
      flex-grow: 1;
    }

    .icon-back {
      @include mix-flex-centered;

      min-width: 32px;
      width: 32px;
      height: 32px;
      margin-right: 8px;

      svg {
        transform: rotate(180deg);
        width: 7px;
        height: 12px;

        path {
          fill: $color-neutrals-8;
        }
      }
    }
  }

  .content {
    color: $color-text-2;

    .caption,
    desc {
      color: $color-text-2;
      @include body-3-medium;
    }

    .caption {
      padding-bottom: 16px;
      border-bottom: 1px solid $bg-6;
    }
  }
}
