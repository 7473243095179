@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';

.project-detail {
  @include mix-max-width;

  margin-bottom: 130px;

  @media (max-width: 768px) {
    margin-bottom: 56px;
  }

  @media screen and (max-width: 992px) and (min-width: 768px) {
    max-width: 100%;
    padding: 0 45px;
  }

  .loading {
    height: calc(100vh - 525px);
  }

  .pool-list {
    margin-top: 70px;

    .title {
      font-family: R-Flex-Bold, sans-serif;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      color: $color-white;
      margin-bottom: 34px;
    }

    .pools {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media (max-width: 992px) {
      .title {
        text-align: center;
      }
    }
  }
}
