@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.image-container {
  padding: 16px;
  width: 100%;
  flex: 1;
  margin-right: 16px;
  background-color: rgb(47 50 65 / 50%);
  border-radius: 16px;
  @include desktop {
    display: flex;
    flex-direction: column;
    height: 560px;
  }
  @include mobile {
    position: relative;
    margin: 0;
  }

  .socials {
    position: absolute;
    top: 32px;
    right: 32px;
  }

  img {
    margin-bottom: 16px;
    border-radius: 22px;
    height: 100%;
    max-height: calc(100% - 131px);
    object-fit: cover;
    flex: 1;
    @include mobile {
      height: 312px;
    }
  }
}

.image-detail {
  padding: 16px;
  background-color: $bg-500;
  border-radius: 16px;
  display: flex;
  min-height: 115px;
  height: auto;
}

.phase-notice {
  @include custom-flex(row, flex-start, flex-start);

  svg {
    min-width: 20px;
  }

  p {
    margin-bottom: 0;
    margin-left: 10px;
    @include body-4-regular;
  }
}

.hide-desktop {
  @include desktop {
    display: none;
  }
}
