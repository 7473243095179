@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.wapper-field {
  + .wapper-field {
    margin-top: 32px;
  }

  .title {
    margin-bottom: 8px;
    color: $color-white;
    @include body-2-bold;
  }

  .flex-swap {
    @include flex-items-center;

    .label {
      white-space: nowrap;
      color: $color-text-2;
      @include body-3-regular;
    }

    .switch-ctrl {
      margin-left: 15px;
    }
  }

  .content-box {
    border-radius: 12px;
    overflow: hidden;

    .view-paper {
      width: 100%;
      padding: 12px 0;
      background: $bg-4;
      display: flex;
      align-items: flex-start;
      @include mobile {
        display: inherit;
      }

      + .view-paper {
        margin-top: 4px;
      }

      .shrink {
        padding: 0 16px;
        @include flex-items-center;

        .adornment {
          margin-right: 8px;
          @include mix-icon-square;
        }

        .label {
          white-space: nowrap;
          color: $color-text-2;
          @include body-4-medium;
        }
      }

      .grow {
        padding: 0 16px;
        @include mix-grow-ellipsis;
        @include mobile {
          margin-top: 5px;
        }

        &-left {
          @include mix-grow-ellipsis($align: left);

          align-items: flex-start;
        }

        .value {
          color: $color-text-1;
          @include body-3-bold;
        }

        .adornment-end {
          @include mix-adornment-end(22px, 22px);

          img,
          svg {
            width: 100%;
            max-width: 100%;
            height: 22px;
            border-radius: 100px;
          }
        }

        .ellipsis + .ellipsis {
          margin-top: 8px;
        }
      }

      .balance-block {
        padding: 0 16px;

        .row-balance {
          margin-left: -16px;
          margin-bottom: 16px;
          @include flex-items-center;
          @include mobile {
            display: block;
          }

          .col-value {
            padding: 0 16px;
            @include flex-items-center;

            .value {
              color: $color-white;
              @include body-2-medium;
            }

            .adornment {
              @include mix-adornment-end(22px, 22px);

              img,
              svg {
                width: 100%;
                max-width: 100%;
                height: 22px;
                border-radius: 100px;
              }
            }
          }
        }
      }
    }

    .label-info,
    .value-info {
      @include flex-items-center;

      .text {
        color: $color-white;
        @include body-2-medium;
      }

      .adornment-end {
        svg,
        img {
          width: 100%;
          object-fit: cover;
          border-radius: 100px;
        }
      }
    }

    .label-info {
      margin-bottom: 8px;
      @include mobile {
        display: block;
      }

      .adornment-end {
        @include mix-adornment-end(20px, 20px);

        svg,
        img {
          width: 100%;
          height: 20px;
          object-fit: cover;
          border-radius: 100px;
        }
      }
    }

    .value-info {
      .adornment-end {
        @include mix-adornment-end(22px, 22px);

        svg,
        img {
          width: 100%;
          height: 22px;
          object-fit: cover;
          border-radius: 100px;
        }
      }
    }
  }
}

.btn-withraw:disabled {
  background: $bg-6;

  &:hover {
    color: $color-text-1;
    background: $bg-6;
  }
}
