@import 'src/styles/abstracts/variables';

.root-secondary {
  color: $color-accent-1;
}

.rail {
  height: 8px;
  border-radius: 8px;

  &.rail-secondary {
    opacity: 0.2;
  }
}

.track {
  height: 8px;
  border-radius: 8px;

  &.track-secondary {
    background: $bg-accent-1;
  }
}

.thumb {
  width: 20px;
  height: 20px;
  box-shadow: 0 4px 10px rgb(0 0 0 / 25%);

  &.thumb-secondary {
    background: $bg-accent-1;
    border: 4px solid $bg-1;
  }

  &:hover {
    box-shadow: 0 0 0 8px rgb(0 0 0 / 25%);
  }
}

.disabled {
  .thumb {
    &.thumb-secondary {
      display: none;
    }
  }
}
