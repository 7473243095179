@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';
@import 'src/styles/base/typography';

.wallet-connector {
  align-items: center;
  @include mix-flex-column;

  .icon-brand {
    margin-bottom: 32px;
    width: 80px;
    height: 80px;
    @include mix-flex-centered;

    img,
    svg {
      width: 100%;
      height: 100%;
      max-width: 100%;
      object-fit: contain;
    }
  }

  .wallet-address {
    margin-bottom: 32px;
    width: 100%;
    color: $color-text-2;
    text-transform: capitalize;
    align-items: baseline;
    @include body-1-bold;
    @include flex-justify-center;

    .copy-text {
      margin-right: 6px;
    }

    +.choose-wallet {
      margin-top: 0;
    }
  }

  .text-desc {
    margin-bottom: 0;
    text-align: center;
    color: $color-text-2;
    @include body-3-regular;
  }

  .choose-wallet {
    width: 100%;
    text-align: center;
    margin-top: 32px;

    &-title {
      color: $color-text-1;
      @include body-2-bold;
    }

    .group-btn-wallet {
      margin-left: -12px;
      justify-content: center;
      @include mix-flex-wrap;
    }
  }
}

.wallet-item {
  width: 100%;
  max-width: calc(100% / 3 - 12px);
  margin-left: 12px;
  margin-top: 16px;  
  background-color: $bg-3;
  border: 1px solid $color-text-4;
  border-radius: 12px;
  padding: 16px 20px;
  flex-direction: column;
  @include mix-flex-centered;
  @include mobile-down {
    max-width: calc(50% - 12px);
  }

  .icon {
    width: 42px;
    height: 42px;
    margin-bottom: 15px;
  }

  .name {
    flex-grow: 1;
    text-align: center;
    color: $color-text-1;
    @include body-3-semibold;
  }
}
