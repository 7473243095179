@import 'src/styles/abstracts/variables';

.main-layout {
  grid-area: main;
  width: 100%;
  overflow-x: hidden;
}

.default-layout {
  background-color: $bg-600;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;

  @media (max-width: 768px) {
    min-height: auto;
  }
}

.page-layout {
  padding-top: 130px;

  @media only screen and (max-width: 768px) {
    padding-top: 94px;
  }
}

.background-landing {
  background: url('../../assets/images/background-home.svg') center;
  background-size: cover;
}

.bg-body {
  position: relative;
  min-height: 100vh;
  overflow: auto;

  @media (max-width: 768px) {
    min-height: auto;
  }
}