@import 'src/styles/base/typography';
@import 'src/styles/abstracts/variables';
@import 'src/styles/abstracts/mixins';

.paper {
  width: 100%;
  background: $bg-5;
  padding: 32px 32px 0;
  box-shadow: 0 64px 64px -48px rgb(15 15 15 / 10%);
  border-radius: 20px;
  @include mobile-down {
    &.paper-full {
      margin: 0;
      padding: 16px 16px 0;
      box-shadow: none;
      height: 100%;
      max-height: 100%;
      border-radius: 0;
    }
  }
}

.paper-width-xs {
  max-width: 448px;
}

.dialog-title-root,
.dialog-content-root {
  padding: 0;
}

.dialog-action-root {
  padding: 22px 0 0;
}

.dialog-title {
  width: 100%;
  padding: 0 0 32px;
  @include mix-flex-centered;

  &-text {
    color: $color-neutrals-8;
    @include h4-bold;

    &.center {
      text-align: center;
    }
  }

  &-right {
    flex-grow: 1;
    @include flex-justify-end;

    .btn-close {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      border: 2px solid #353945;
      outline: none;
      background: transparent;
      color: $color-neutrals-8;
      transition: all 0.1s linear;
      @include mix-flex-centered;

      position: relative;

      &:hover {
        border-color: currentcolor;
      }

      svg {
        position: absolute;
      }
    }
  }
}

.dialog-action {
  padding-bottom: 32px;
  width: 100%;

  &-group {
    margin-left: -16px;
    @include flex-items-center;

    flex-wrap: wrap;

    .action {
      flex-grow: 1;
      padding-left: 16px;
      margin-top: 10px;

      &-btn {
        width: 100%;

        &:disabled {
          &:hover {
            background-color: $bg-4;
            color: $color-text-1;
          }
        }
      }
    }
  }
}
